import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PanToolIcon from '@mui/icons-material/PanTool';
import PrintIcon from '@mui/icons-material/Print';
import { Link } from 'react-router-dom';
import {Button, TextField, Checkbox } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DescriptionIcon from '@mui/icons-material/Description'
import real from './../services/real'
import PieChartIcon from '@mui/icons-material/PieChart';
import moment from 'moment'
import Autocomplete from '@mui/material/Autocomplete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    color: '#FFF',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#31353D',
  },
}));

const Root = styled('div')({
  width: '100%',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const VisuallyHidden = styled('div')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  let stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  stabilizedThis = stabilizedThis.map((el) => el[0]);

  return stabilizedThis
}

function renderClassFinanceiro(param) {
  if (param === 'VENCIDO') {
    return {
      backgroundColor: '#FFB6C1	'
    }
  } else if (param === 'A VENCER') {
    return {
      backgroundColor: '#FFFFE0'
    }
  } else if (param === 'BAIXADO') {
    return {
      backgroundColor: '#98FB98'
    }
  } else if (param === 'CANCELADO' || param === 'ESTORNADO') {
    return {
      backgroundColor: '#FFFAFA'
    }
  }
}

function renderClassVouchers(param){
  if(param){
    return {
      backgroundColor: '#D3D3D3',
      borderBottom: '1px solid #000'
    }
  }
}

function renderClassExtrato(param){
  if(param){
    return {
      backgroundColor: '#D3D3D3',
      borderBottom: '1px solid #000'
    }
  }
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, acoes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = props.headCell

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map(headCell => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.habilitaFiltro &&
                <React.Fragment>
                  <TableSortLabel
                    style={{color: "#FFF"}}
                    active={orderBy === headCell.id}
                    direction={(orderBy === headCell.id || orderBy === headCell.orderBy) ? order : 'asc'}
                    onClick={createSortHandler(headCell.orderBy || headCell.id)}
                    // style={{width: '30%', marginTop: '7px'}}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <VisuallyHidden>
                        {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                      </VisuallyHidden>
                    ) : null}
                  </TableSortLabel>
                  <br />
                  {headCell.filtro === 'descricao' &&
                    <TextField placeholder='Pesquisar' className="input" variant="outlined" size="small" name="descricao" value={props.filtros_tela.descricao} onChange={e => props.updateFiltroDescricao(e)} InputLabelProps={{ shrink: true }} />
                  }
                  {headCell.filtro === 'forma' &&
                    <TextField placeholder='Pesquisar' className="input" variant="outlined" size="small" name="forma_pgto" value={props.filtros_tela.forma_pgto} onChange={e => props.updateFiltroDescricao(e)} InputLabelProps={{ shrink: true }} />
                  }
                  {headCell.filtro === 'valor' &&
                    <TextField placeholder='Pesquisar' className="input" variant="outlined" size="small" name="valor" value={props.filtros_tela.valor} onChange={e => props.updateFiltroDescricao(e)} InputLabelProps={{ shrink: true }} />
                  }
                </React.Fragment>
              }
              {!headCell.habilitaFiltro &&
                <TableSortLabel
                  style={{color: "#FFF"}}
                  active={orderBy === headCell.id}
                  direction={(orderBy === headCell.id || orderBy === headCell.orderBy) ? order : 'asc'}
                  onClick={createSortHandler(headCell.orderBy || headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span>
                      {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                    </span>
                  ) : null}
                </TableSortLabel>
              }
            </StyledTableCell>
          )
        })}
        {acoes.length === 1 &&
          <StyledTableCell style={{ width: 80 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
        {acoes.length === 2 &&
          <StyledTableCell style={{ width: 100 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
        {acoes.length === 3 &&
          <StyledTableCell style={{ width: 140 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const { acoes, noRemove, headCell, rows, conciliar, ordem, ordernar, heightRows, idUnidadeTrabalho, registrarRows } = props

  const [order, setOrder] = React.useState(ordem || 'asc');
  const [orderBy, setOrderBy] = React.useState(ordernar || '');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.qtdLinhas === 0 ? 1 : ( props.qtdLinhas ? props.qtdLinhas : 10), 10);

  const habilitaRodape = props.desabilitaRodape ? false : true

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const rowsTable = stableSort(rows, getComparator(order, orderBy))

  if(registrarRows){
    registrarRows(rowsTable)
  }

  return (
    <Root>
      <StyledPaper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCell={headCell}
              acoes={acoes}
              updateFiltroDescricao={e => props.updateFiltroDescricao(e)}
              filtros_tela={props.filtros_tela}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id || row.id ? (row.valpag_id ? `${row._id || row.id}-${row['valpag_id']}` : row._id || row.id) : row.index}
                      // key={row._id ? row._id : row.index}
                      selected={isItemSelected}
                      style={
                        {
                          ...renderClassFinanceiro(row.situacao || ''), 
                          ...renderClassVouchers(row.totalizador),
                          ...renderClassExtrato(row.totalizador_extrato)
                        }
                      }
                    >
                      {headCell.map(param => {
                        if (param.id === 'ativo_switch'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row[param.id] &&
                                <Checkbox
                                  checked={true}
                                  onChange={() => props.updateFieldAtivo(row)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              }
                              {!row[param.id] &&
                                <Checkbox
                                  checked={false}
                                  onChange={() => props.updateFieldAtivo(row)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              }
                              <span>{row[param.id] ? 'Sim' : 'Não'}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'principal_switch'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row[param.id] &&
                                <Checkbox
                                  checked={true}
                                  onChange={() => props.updateFieldPrincipal(row)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              }
                              {!row[param.id] &&
                                <Checkbox
                                  checked={false}
                                  onChange={() => props.updateFieldPrincipal(row)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              }
                              <span>{row[param.id] ? 'Sim' : 'Não'}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'tributacao'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte, width: '70%'}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <TextField
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                className="input"
                                fullWidth
                                size="small"
                                SelectProps={{
                                  native: true,
                                }}
                                name="servico_receita_id"
                                value={row['servico_receita_id']}
                                onChangeCapture={(e) => props.updateFieldServicoReceita(e, row)}
                                style={{marginTop: '10px', marginBottom: !row['servico_receita_id'] ? '10px' : '0px'}}
                              >
                                <option value=''>Selecionar Serviço Receita</option>
                                {props.servicos_receita.map((value, key) => {
                                  return (
                                    <option key={key} value={value.id}>{value.codigo} - {value.descricao}</option>
                                  )
                                })}
                              </TextField>
                              {row['servico_receita_id'] &&
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="tributacao_id"
                                  value={row['tributacao_id']}
                                  onChangeCapture={(e) => props.updateFieldTributacao(e, row)}
                                  style={{marginLeft: '20px', width: '98.2%', marginTop: '10px', marginBottom: '10px'}}
                                >
                                  <option value=''>Selecionar Tributacao</option>
                                  {props.servicos_receita[row.index_servico].cod_trib.map(value => {
                                    return (
                                      <option value={value.id}>{value.codigo} - {value.descricao}</option>
                                    )
                                  })}
                                </TextField>
                              }
                            </TableCell>
                          )
                        } else if (row[param.id] === true) {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span>Sim</span>
                            </TableCell>
                          )
                        } else if (row[param.id] === false) {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span>Não</span>
                            </TableCell>
                          )
                        } else if (param.id === 'url') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <img className="imagemTable" src={`${window._env_.REACT_APP_API_URL}/static/${row[param.id]}`} alt="foto" />
                            </TableCell>
                          )
                        } else if (param.id === 'url_banco') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <img className="imagemTable" src={row.url_banco || 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt="foto" />
                            </TableCell>
                          )
                        } else if (param.id === 'descricao_extrato') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={row.totalizador_extrato ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span>{row.descricao_extrato}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'servico_agendado') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span>{row[param.id]}</span> 
                              {row.voucher &&
                                <span style={{
                                  color: 'red',
                                }}> (Voucher)</span>
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'contpr_descricao') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row.pessoa_id &&
                                <p style={{ marginBottom: 0, marginTop: 0, fontSize: '12px' }}><b>{row.pessoa_nome}</b></p>
                              }
                              <span>{row[param.id]}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_caixa') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color: row.tipo === 'SAQUE' ? '#FF0000' : '#006400', textAlign: 'right' }}>R$ {row.valor_caixa}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_mov') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: 14}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span style={{ color: row.valor_mov < 0 ? '#FF0000' : '#006400', textAlign: 'right' }}>R$ {real(row.valor_mov)}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'total_credito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color: '#006400', marginBottom: '0px' }}>{row.total_credito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'total_debito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color: '#FF0000', marginBottom: '0px' }}>{row.total_debito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_debito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color: '#FF0000', marginBottom: '0px' }}>{row.valor_debito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_credito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color: '#006400', marginBottom: '0px' }}>{row.valor_credito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'saldo') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color: '#006400', marginBottom: '0px' }}>{row.saldo}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valorparcela'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valorparcela}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'valorpagamento'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valorpagamento}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_recebido') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              R$ {real(row.valor_recebido)}
                            </TableCell>
                          )
                        } else if (param.id === 'valor') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valor}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_utilizado') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valor_utilizado}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_total') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <p style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valor_total}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'multajuros'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span>{row.multajuros}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'conta_destino') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row.formapagto_descricao === 'Dinheiro' &&
                                <TextField style={{ marginTop: '8px', marginBottom: '8px' }} className="input" label="Conta*" variant="outlined" size="small" value={props.contasFinanceira.filter(param => param.cofre === true)[0].descricao} InputLabelProps={{ shrink: true }} disabled={true} />
                              }
                              {row.formapagto_descricao !== 'Dinheiro' &&
                                <TextField
                                  style={{ marginTop: '8px', marginBottom: '8px' }}
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  label="Conta*"
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="contfin_id"
                                  value={row.contfin_id}
                                  onChangeCapture={(e) => props.updateFieldConta(e, row.index)}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <option value="">--Escolha--</option>
                                  {props.contasFinanceira.filter(param => param.tipo_conta === 'BANCO').sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(conta => {
                                    return (
                                      <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                    )
                                  })}
                                </TextField>
                              }

                            </TableCell>
                          )
                        } else if (param.id === 'flags' && props.tipo_unidade !== 'MANTENEDOR') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte, width: '210px'}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row['flags'].map(flag => {
                                return (
                                  <React.Fragment>
                                    {(flag !== 'REPRESENTANTE' && flag !== 'GRUPOECONOMICO' && flag !== 'UNIDADE' ) &&
                                      <div className={`card-flag-${flag.toLowerCase()}`}>{flag}</div>
                                    }
                                  </React.Fragment>
                                )
                              })}
                            </TableCell>
                          )
                        } else if (param.id === 'flags' && props.tipo_unidade === 'MANTENEDOR') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte, width: '210px'}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row['flags'].map(flag => {
                                return (
                                  <div className={`card-flag-${flag.toLowerCase()}`}>{flag}</div>
                                )
                              })}
                            </TableCell>
                          )
                        } else if (param.id === 'baixar') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte, width: '30px'}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row.aberto &&
                                <React.Fragment>
                                  {row.qtd_parcelas === 1 &&
                                    <React.Fragment>
                                      {props.primeiraParcelaSelecionada.formapagto_slug === '' &&
                                        <React.Fragment>
                                          {props.parcelasBaixa.findIndex(param => param === row._id) !== -1 &&
                                            <Tooltip title='Baixar' aria-label="add">
                                              <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                <CheckCircleOutlineIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
    
                                          {props.parcelasBaixa.findIndex(param => param === row._id) === -1 &&
                                            <Tooltip title='Baixar' aria-label="add">
                                              <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                <RadioButtonUncheckedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                        </React.Fragment>
                                      }
                                      {props.primeiraParcelaSelecionada.formapagto_slug === row.formapagto_slug &&
                                        <React.Fragment>
                                          {props.parcelasBaixa.findIndex(param => param === row._id) !== -1 &&
                                            <Tooltip title='Baixar' aria-label="add">
                                              <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                <CheckCircleOutlineIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
    
                                          {props.parcelasBaixa.findIndex(param => param === row._id) === -1 &&
                                            <Tooltip title='Baixar' aria-label="add">
                                              <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                <RadioButtonUncheckedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                        </React.Fragment>
                                      }
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'data_hora') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row[param.id]}

                              <Tooltip title='Alterar Data' aria-label="add">
                                <IconButton color="primary" aria-label="view" onClick={e => props.alterarData(row)}>
                                  <SwapHorizIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )
                        } else if (param.id === 'qtd_escolha'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <span>
                                {row[param.id]}
                              </span>
                            </TableCell>
                          )
                        } else if (param.id === 'codigo_assinatura'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <Tooltip title={'Acessar Link'} aria-label="add">
                                <Link target='_blank' to={`/assinatura_contrato/${row._id}`}>
                                  <span>{row[param.id]}</span>
                                </Link> 
                              </Tooltip>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_contrato'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row.recorrencia &&
                                <Tooltip title={'Recorrência'} aria-label="add">
                                  <div style={{
                                    backgroundColor: 'red',
                                    width: 18,
                                    height: 18,
                                    textAlign: 'center',
                                    paddingTop: 4,
                                    color: '#FFF',
                                    borderRadius: 10,
                                    fontSize: 12,
                                    position: 'relative',
                                    top: 6
                                  }}>R</div>
                                </Tooltip>
                              }
                              {row.parcelado &&
                                <Tooltip title={'Parcelado'} aria-label="add">
                                  <div style={{
                                    backgroundColor: 'blue',
                                    width: 18,
                                    height: 18,
                                    textAlign: 'center',
                                    paddingTop: 4,
                                    color: '#FFF',
                                    borderRadius: 10,
                                    fontSize: 12,
                                    position: 'relative',
                                    top: 6
                                  }}>P</div>
                                </Tooltip>
                              }
                              <span style={{position: 'relative', top: -10}}>{row[param.id]}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'alterar_atendente'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row['atendente_disponivel'] === 'Sim' &&
                                <span> {row[param.id]} </span>
                              }
                              {row['atendente_disponivel'] === 'Não' &&
                                <Autocomplete
                                  onChange={(event, value) => props.updateFieldAtendente(event, value, row)}
                                  freeSolo
                                  options={row.atendentes}
                                  value={row.atendente}
                                  id='atendente'
                                  getOptionLabel={option => `${option.nome}`}
                                  renderInput={(params) => (
                                    <TextField 
                                      placeholder={'Selecionar Novo Atendente'} 
                                      InputProps={{ ...params.InputProps, type: 'search' }} 
                                      {...params} 
                                      size="small" 
                                      style={{width: '250px'}}
                                      margin="normal" 
                                      variant="outlined" 
                                      InputLabelProps={{ shrink: true }} 
                                      value={`${row.atendente.nome}`} 
                                    />
                                  )}
                                />
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'alterar_sala'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {row['sala_disponivel'] === 'Sim' &&
                                <span> {row[param.id]} </span>
                              }
                              {row['sala_disponivel'] === 'Não' &&
                                <Autocomplete
                                  onChange={(event, value) => props.updateFieldSala(event, value, row)}
                                  freeSolo
                                  options={row.salas}
                                  value={row.sala}
                                  id='ambiente'
                                  getOptionLabel={option => `${option.nome}`}
                                  renderInput={(params) => (
                                    <TextField 
                                      placeholder={'Selecionar Novo Ambiente'} 
                                      InputProps={{ ...params.InputProps, type: 'search' }} 
                                      {...params} 
                                      size="small" 
                                      style={{width: '250px'}}
                                      margin="normal" 
                                      variant="outlined" 
                                      InputLabelProps={{ shrink: true }} 
                                      value={`${row.sala.nome}`}
                                    />
                                  )}
                                />
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'tipo_chave'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte, width: 280}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              <TextField
                                style={{ marginTop: '8px', marginBottom: '8px' }}
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                className="input"
                                fullWidth
                                label="Tipo da Chave"
                                size="small"
                                SelectProps={{
                                  native: true,
                                }}
                                name="tipo_chave"
                                value={row.tipo_chave}
                                onChangeCapture={(e) => props.updateFieldPagamento(e, row.index)}
                                InputLabelProps={{ shrink: true }}
                              >
                                <option value="">--Escolha--</option>
                                <option value="cpf">CPF</option>
                                <option value="cnpj">CNPJ</option>
                                <option value="phone">Telefone</option>
                                <option value="email">Email</option>
                                <option value="random">Aleatório</option>
                              </TextField>
                            </TableCell>
                          )
                        } else if (param.id === 'chave_pix'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte, width: 280}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                             <TextField 
                              style={{ marginTop: '8px', marginBottom: '8px' }}
                              className="input"
                              label="Chave Pix"
                              variant="outlined"
                              size="small"
                              value={row.chave_pix}
                              InputLabelProps={{ shrink: true }}
                              onChangeCapture={(e) => props.updateFieldPagamento(e, row.index)}
                             />
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'normal'}
                            >
                              {param.edit &&
                                <Tooltip title={param.tooltip} aria-label="add">
                                  <Link to={`${param.link}${row._id}`}>
                                    <span>{row[param.id]}</span>
                                  </Link>
                                </Tooltip>
                              }
                              {(!param.edit && typeof row[param.id] !== 'object' ) &&
                                <span style={{textDecoration: row.cancelamento ? 'line-through' : ''}}>
                                  {row[param.id]}
                                </span>
                              }
                            </TableCell>
                          )
                        }
                      })}
                      {acoes.length > 0 &&
                        <TableCell align={'center'}>
                          {acoes.map(acao => {
                            // console.log(acao)
                            if (acao === "view") {
                              return (
                                <Tooltip key={acao} title='Visualizar' aria-label="add">
                                  <Link to={`${props.urlView}${row._id}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === 'viewVoucherRel'){
                              if(!row.totalizador){
                                return (
                                  <Tooltip key={acao} title='Visualizar' aria-label="add">
                                    <Link to={`${props.urlView}${row._id}`} target="_blank">
                                      <IconButton color="primary" aria-label="view">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }
                            }else if (acao === 'viewUsoTela'){
                              return (
                                <Tooltip key={acao} title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewUsuTela(row)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === 'viewPedidos'){
                              return (
                                <Tooltip key={acao} title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewPedidos(row)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === 'selecionarContrato'){
                              return (
                                <Tooltip title='Selecionar' aria-label="add">
                                  <IconButton onClick={e => props.selecionarContrato(row)} color="primary" aria-label="view">
                                    <PanToolIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'imprimirBoleto'){
                              if(row.parceiro_bancario_id && (row.situacao === 'A VENCER' ||  row.situacao === 'VENCIDO') && row.tipo === 'RECEBER'){
                                return (
                                  <Tooltip title={row.formapagto_slug === 'boleto' ? 'Imprimir Boleto' : 'Imprimir Pix'} aria-label="add">
                                    <IconButton onClick={e => props.imprimirBoleto(row)} color="primary" aria-label="view">
                                      <PrintIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else{
                                return false
                              }
                            } else if (acao === 'imprimirBoletoCliente'){
                              if(row.tipo_transaction &&row.status !== "Pago" && row.tipo_conta === 'A Pagar'){
                                return (
                                  <Tooltip title={row.forma_pgto_slug === 'boleto' ? 'Imprimir Boleto' : 'Imprimir Pix'} aria-label="add">
                                    <IconButton onClick={e => props.imprimirBoleto(row)} color="secondary" aria-label="view" disabled={row.status !== 'Pendente' && !row.tipo_transaction}>
                                      <PrintIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else{
                                return (
                                  <IconButton disabled color="secondary" aria-label="view">
                                    <PrintIcon />
                                  </IconButton>
                                )
                              }
                            }else if (acao === 'imprimirRel'){
                              if(row.disponivel === 'Sim'){
                                return (
                                  <IconButton onClick={e => props.imprimirRel(row)} color="primary" aria-label="view">
                                    <PrintIcon />
                                  </IconButton>
                                )
                              }else{
                                return (
                                  <IconButton disabled color="primary" aria-label="view">
                                    <PrintIcon />
                                  </IconButton>
                                )
                              }
                            } else if (acao === 'imprimirNFSE'){
                              if(row.url_pdf){
                                return (
                                  <Tooltip title={'Imprimir NFSE'} aria-label="add">
                                    <IconButton style={{color:'#063e11'}} onClick={e => props.imprimirNFSE(row)} aria-label="view">
                                      <DescriptionIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else{
                                return (
                                  <Tooltip title={'Imprimir NFSE'} aria-label="add">
                                    <IconButton disabled={!row.url_pdf} aria-label="view">
                                      <DescriptionIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'cancelarNFSE'){
                              return (
                                <Tooltip title={'Cancelar NFSE'} aria-label="add">
                                  <IconButton disabled={row.status !== 'Aprovado'} color='secondary' onClick={e => props.cancelarNFSE(row)} aria-label="view">
                                    <CloseIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'viewNFSE'){
                              return (
                                <Tooltip title={'Visualizar Parcela'} aria-label="add">
                                  <Link key={acao} to={`conta_pagar_receber/cadastro/${row['parcela_id']}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "viewPlano") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewPlanos(row)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if(acao === 'viewItens'){
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewItens(row)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === "viewDemonstrativo") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row['datapagamento_sem_form']}/${row['pesf_id']}/${row['unin_id']}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "viewContrato") {
                              return (
                                <Tooltip key={acao} title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewContrato(row)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewContratos") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.contratos, 'contratos-clientes', row.produto)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewVouchers") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.ids, 'vouchers', `${row.descricao} - ${moment(row.data).format('DD/MM/YYYY')}`)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewContas") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewContas(row)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewAgendas") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.ids, 'agendas', `${row.descricao} - ${moment(row.data).format('DD/MM/YYYY')}`)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewAgenda") {
                              if(row.tela === 'AGENDA'){
                                return (
                                  <Tooltip title='Visualizar Agenda' aria-label="add">
                                    <IconButton color="primary" aria-label="view" onClick={e => props.viewAgenda(row)} disabled={!row.utilizado}>
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else if(row.tela === 'VOUCHER'){
                                return (
                                  <Tooltip title='Visualizar Voucher' aria-label="add">
                                    <Link to={`/venda_voucher/view/${row.voucher_id}`} target="_blank">
                                      <IconButton color="primary" aria-label="view">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }else if(row.tela === 'OS'){
                                return (
                                  <Tooltip title='Visualizar OS' aria-label="add">
                                    <IconButton color="primary" aria-label="view" onClick={e => props.viewOS(row)}>
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "viewCliente") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <Link target='blank' key={acao} to={`${props.urlView}${row._id}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            }else if (acao === "exibeMenu") {
                              return (
                                <React.Fragment>
                                  {row.exibe_menu &&
                                    <Tooltip title='Exibir no Menu' aria-label="add">
                                      <IconButton onClick={e => props.selecionarExibeMenu(row)} color="secondary" aria-label="editar">
                                        <CheckCircleOutlineIcon />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                  {!row.exibe_menu &&
                                    <Tooltip title='Exibir no Menu' aria-label="add">
                                      <IconButton onClick={e => props.selecionarExibeMenu(row)} color="secondary" aria-label="editar">
                                        <RadioButtonUncheckedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                </React.Fragment>
                              )
                            } else if (acao === "viewDependente") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewDependente(row)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "update") {
                              if (props.tela === 'Perfil') {
                                if (idUnidadeTrabalho === row.dono_unin_id) {
                                  return (
                                    <Tooltip title='Editar' aria-label="add">
                                      {(() => {
                                        if (typeof props.urlUpdate == 'function') {
                                          return (
                                            <IconButton
                                              color="error"
                                              aria-label="view"
                                              onClick={e => props.urlUpdate(row)}
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                          )
                                        } else {
                                          return (
                                            <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                              <IconButton color="error" aria-label="view">
                                                <CreateIcon />
                                              </IconButton>
                                            </Link>
                                          )
                                        }
                                      })()}
                                    </Tooltip>
                                  )
                                }
                              } else {
                                if (row.descricao === 'Administrador do Sistema') {
                                  return false
                                } else {
                                  return (
                                    <Tooltip key={acao} title='Editar' aria-label="add">
                                      {(() => {
                                        if (typeof props.urlUpdate == 'function') {
                                          return (
                                            <IconButton
                                              color="error"
                                              aria-label="view"
                                              onClick={e => props.urlUpdate(row)}
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                          )
                                        } else {
                                          return (
                                            <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                              <IconButton color="error" aria-label="view">
                                                <CreateIcon />
                                              </IconButton>
                                            </Link>
                                          )
                                        }
                                      })()}
                                    </Tooltip>
                                  )
                                }
                              }
                            } else if (acao === "updateVenda") {
                              if(row.baixado){
                                return (
                                  <Tooltip key={acao} title='Venda Fechada' aria-label="add">
                                    <Link key={acao} to={`${props.urlView}${row._id}`}>
                                      <IconButton color="error" aria-label="view">
                                        <CreateIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }else{
                                return (
                                  <Tooltip key={acao} title='Editar' aria-label="add">
                                    <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                      <IconButton color="error" aria-label="view">
                                        <CreateIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "updateCompra") {
                              if(row.baixado){
                                return (
                                  <Tooltip key={acao} title='Compra Fechada' aria-label="add">
                                    <Link key={acao} to={`${props.urlView}${row._id}`}>
                                      <IconButton color="error" aria-label="view">
                                        <CreateIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }else{
                                return (
                                  <Tooltip key={acao} title='Editar' aria-label="add">
                                    <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                      <IconButton color="error" aria-label="view">
                                        <CreateIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }
                            }else if (acao === "updatePesquisa") {
                              return (
                                <React.Fragment>
                                  {row.enviado &&
                                    <IconButton 
                                      color="secondary" 
                                      aria-label="view"
                                      disabled
                                    >
                                      <CreateIcon />
                                    </IconButton>
                                  }
                                  {!row.enviado &&
                                    <Tooltip key={acao} title='Editar' aria-label="add">
                                      <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                        <IconButton 
                                          color="secondary" 
                                          aria-label="view"
                                        >
                                          <CreateIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  }
                                </React.Fragment>
                              )
                            } else if (acao === "updateServico") {
                              if(row['ativo']){
                                return (
                                  <IconButton
                                    key={acao}
                                    color="primary"
                                    aria-label="view"
                                    onClick={e => props.urlUpdate(row)}
                                  >
                                    <CreateIcon />
                                  </IconButton>
                                )
                              }
                            } else if (acao === "conciliar_mov") {
                              if (row['contfin_conciliavel']) {
                                if (row['conciliavel']) {
                                  if (row['conciliado']) {
                                    return (
                                      <Tooltip key={acao} title='Desconciliar' aria-label="add">
                                        <IconButton onClick={e => props.conciliarMov(row)} color="primary" aria-label="editar">
                                          <CheckCircleOutlineIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  } else {
                                    return (
                                      <Tooltip key={acao} title='Conciliar' aria-label="add">
                                        <IconButton onClick={e => props.conciliarMov(row)} color="secondary" aria-label="editar">
                                          <RadioButtonUncheckedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                }
                              }
                            } else if (acao === 'selecionarVoucher') {
                              let index = props.vouchersCreditoSelecionados.findIndex(param => param.voucher_id === row.id)
                              if(index === -1){
                                return (
                                  <Tooltip key={acao} title='Selecionar' aria-label="add">
                                    <IconButton onClick={e => props.selecionarVoucherCredito(row)} color="secondary" aria-label="editar" disabled={props.diferencaVoucher === 0 || parseFloat(row.novo_saldo_form.replace("R$","").replaceAll(".", "").replace(",", ".")) === 0}>
                                      <RadioButtonUncheckedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else if(props.totalVouchers > 0){
                                return (
                                  <Tooltip key={acao} title='Remover' aria-label="add">
                                    <IconButton onClick={e => props.selecionarVoucherCredito(row)} color="primary" aria-label="editar">
                                      <CheckCircleOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'selecionarVoucherProduto'){
                              let index = props.vouchersProdutosSelecionados.findIndex(param => param.produto_voucher_id === row.id)
                              
                              if(index === -1){
                                return (
                                  <Tooltip key={acao} title='Selecionar' aria-label="add">
                                    <IconButton onClick={e => props.selecionarVoucherProduto(row)} color="secondary" aria-label="editar" disabled={props.diferencaVoucher === 0 ? true : false}>
                                      <RadioButtonUncheckedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else if(props.totalVouchers > 0){
                                return (
                                  <Tooltip key={acao} title='Remover' aria-label="add">
                                    <IconButton onClick={e => props.selecionarVoucherProduto(row)} color="primary" aria-label="editar">
                                      <CheckCircleOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "editar") {
                              return (
                                <Tooltip title='Editar' aria-label="add" key={acao}>
                                  <React.Fragment >
                                    <IconButton onClick={e => props.editar(row.index)} color="primary" aria-label="editar">
                                      <CreateIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "viewFinanceiroPendente") {
                              return (
                                <Tooltip title='Visualizar Parcela' aria-label="add" key={acao}>
                                  <Link target='_blank' to={`/conta_pagar_receber/cadastro/${row.id}`}>
                                    <IconButton onClick={e => props.editar(row.index)} color="primary" aria-label="editar">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "editarAgenda") {
                              return (
                                <Tooltip title='Editar' aria-label="add" key={acao}>
                                  <IconButton onClick={e => props.editar(row.index)} color="primary" aria-label="editar">
                                    <CreateIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === "editarMov") {
                              return (
                                <Tooltip title='Alterar Lançamento' aria-label="add" key={acao}>
                                  <IconButton onClick={e => props.editarMov(row)} color="secondary" aria-label="editar">
                                    <CreateIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === "concluirPreContas") {
                              return (
                                <Tooltip title='Finalizar Lançamento' aria-label="add" key={acao}>
                                  <IconButton onClick={e => props.finalizarLancamento(row)} color="secondary" aria-label="editar">
                                    <CreateIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "imprimir") {
                              return (
                                <Tooltip title='Imprimir' aria-label="add">
                                  <IconButton onClick={e => props.imprimir(row)} color="primary" aria-label="editar">
                                    <PrintIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "imprimirDocVers") {
                              return (
                                <Tooltip title='Imprimir Doc' aria-label="add">
                                  <IconButton onClick={e => props.imprimir(row)} color="primary" aria-label="editar">
                                    <PrintIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "desativar") {
                              if (row.ativo) {
                                return (
                                  <Tooltip title='Excluir' aria-label="add">
                                    <React.Fragment key={acao}>
                                      <IconButton onClick={e => props.desativar(row)} color="secondary" aria-label="editar">
                                        <CloseIcon />
                                      </IconButton>
                                    </React.Fragment>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "remove") {
                              return (
                                <Tooltip key={acao} title='Remover' aria-label="add">
                                  {!noRemove &&
                                    <IconButton onClick={e => props.remove(row.index)} color="error" aria-label="remove">
                                      <DeleteIcon />
                                    </IconButton>
                                  }
                                </Tooltip>
                              )
                            }else if (acao === "removeItem") {
                              return (
                                <Tooltip key={acao} title='Remover' aria-label="add">
                                  <IconButton onClick={e => props.remove(row)} color="primary" aria-label="remove">
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "removeAgenda") {
                              if(row.situacao !== "FINALIZADO"){
                                return (
                                  <Tooltip title='Remover' aria-label="add">
                                    <React.Fragment key={acao}>
                                      {!noRemove &&
                                        <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove" disabled={props.alteraServico ? true : ((props.financeiro_fechado && row.agenda_id) ? true : false)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      }
                                    </React.Fragment>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "removeServicoVoucher") {
                              if(row.tela === 'AGENDA'){
                                return (
                                  <Tooltip acao={acao} title='Remover' aria-label="add">
                                    {!noRemove &&
                                      <IconButton onClick={e => props.remove(row)} color="error" aria-label="remove" disabled={row.utilizado}>
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "removeServico") {
                              if(row['ativo']){
                                return (
                                  <Tooltip key={acao} title='Remover' aria-label="add">
                                    <React.Fragment>
                                      {!noRemove &&
                                        <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                          <DeleteIcon />
                                        </IconButton>
                                      }
                                    </React.Fragment>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'removeCentroCusto') {
                              return (
                                <Tooltip key={acao} title='Remover' aria-label="add">
                                  <React.Fragment>
                                    {!row._id &&
                                      <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "estornarProrrogacao") {
                              if (row.hisp_ativo) {
                                return (
                                  <Tooltip title='Estornar' aria-label="add">
                                    <IconButton onClick={e => props.estornarProrrogacao(row)} color="secondary" aria-label="remove">
                                      <CloseIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "removeTabela") {
                              return (
                                <Tooltip title='Remover' aria-label="add">
                                  <React.Fragment key={acao}>
                                    {!row.precprod_id &&
                                      <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "conciliar") {
                              return (
                                <React.Fragment key={acao}>
                                  <Button onClick={e => conciliar(row)} className="btn-conciliar" size="small" variant="contained" color="primary">
                                    Conciliar
                                  </Button>
                                </React.Fragment>
                              )
                            } else if (acao === 'selecionarMov'){
                              if(props.movimentacoes_selecionadas.findIndex(param => param._id === row._id) !== -1){
                                return (
                                  <Tooltip title={'Desmarcar Movimentação'} aria-label="add">
                                    <IconButton style={{margin: 0}} onClick={e => props.selecionarMov(row)} color="primary" aria-label="view">
                                      <CheckCircleIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else{
                                return (
                                  <Tooltip title={'Selecionar Movimentação'} aria-label="add">
                                    <IconButton style={{margin: 0}} onClick={e => props.selecionarMov(row)} color="primary" aria-label="view" disabled={row.conciliado}>
                                      <RadioButtonUncheckedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            }else if (acao === "updateVersaoDoc") {
                              return (
                                <Tooltip title='Editar' aria-label="add">
                                  <IconButton style={{marginLeft: 10}} onClick={e => props.editarVersao(row)} color="error" aria-label="editar">
                                    <CreateIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "copy") {
                              return (
                                <Tooltip title='Copiar' aria-label="add">
                                  <IconButton color='secondary' onClick={e => props.copiarVersao(row)} aria-label="editar">
                                    <FileCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "horario") {
                              if (row.aula_tipo_horario === 'FIXO') {
                                return (
                                  <Tooltip title='Horario das Aulas' aria-label="add">
                                    <Link key={acao} to={`${props.urlHorario}${row._id}`}>
                                      <IconButton color="primary" aria-label="view">
                                        <AccessTimeIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'selecionar') {
                              if (props.contrato.contm_tabval_id === row._id) {
                                return (
                                  <React.Fragment key={acao}>
                                    <Checkbox
                                      checked={true}
                                      onChange={() => props.updateFieldSelectPlano(row)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment key={acao}>
                                    <Checkbox
                                      checked={false}
                                      onChange={() => props.updateFieldSelectPlano(row)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                  </React.Fragment>
                                )
                              }
                            } else if (acao === 'selectVar') {
                              return (
                                <Tooltip title='Selecionar' aria-label="add">
                                  <IconButton onClick={e => props.selecionarVar(row)} color="primary" aria-label="view">
                                    <PanToolIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'updateFormaPagto') {
                              return (
                                <Tooltip title='Editar Forma de Pagto' aria-label="add">
                                  <IconButton onClick={e => props.editarFormaPagto(row)} color="secondary" aria-label="editar">
                                    <CreateIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'corrigeSaldo') {
                              return (
                                <Tooltip title='Corrigir Saldo' aria-label="add">
                                  <Link key={acao} to={`${props.urlCorrigeSaldo}${row._id}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <MonetizationOnIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === 'viewAula') {
                              return (
                                <Tooltip title='Visualizar aula' aria-label="add">
                                  <IconButton onClick={e => props.viewRow(row, 'aulas-agendadas')} color="primary" aria-label="view">
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === 'viewTaxas') {
                              return (
                                <Tooltip title='Visualizar Taxas' aria-label="add">
                                  <IconButton onClick={e => props.viewRow(row.itens, 'taxa-boleto-pix', row.unidade)} color="primary" aria-label="view">
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'viewBaixaAula') {
                              return (
                                <Tooltip title='Corrigir Saldo' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row.data_aula_sem_form}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <SearchIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === 'transferirCodigo'){
                              return (
                                <Tooltip title='Transferir Código' aria-label="add">
                                  <IconButton onClick={e => props.transferirCodigo(row)} color="primary" aria-label="view">
                                    <SwapHorizIcon/>
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'pegarPessoa'){
                              if(row.ativo){
                                return (
                                  <Tooltip title='Selecionar' aria-label="add">
                                    <IconButton onClick={e => props.selecionarPessoa(row)} color="primary" aria-label="view">
                                      <PanToolIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'openConta'){
                              return (
                                <Tooltip title='Visualizar Conta' aria-label="add">
                                  <IconButton onClick={e => props.viewRow(row)} color="primary" aria-label="view">
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'selecionarServico') {
                              if (props.servicos_selecionados.findIndex(param => param.produto_id === row.produto_id) !== -1) {
                                return (
                                  <Tooltip key={acao} title='Remover' aria-label="add">
                                    <IconButton onClick={e => props.updateFieldSelectServico(row)} color="primary" aria-label="editar">
                                      <CheckCircleOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              } else {
                                return (
                                  <Tooltip key={acao} title='Selecionar' aria-label="add">
                                    <IconButton onClick={e => props.updateFieldSelectServico(row)} color="primary" aria-label="editar">
                                      <RadioButtonUncheckedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'viewResultado'){
                              return (
                                <Tooltip key={acao} title='Visualizar Resultado' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row._id}`}>
                                    <IconButton color="primary" aria-label="editar">
                                      <PieChartIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else {
                              return false
                            }
                            return false
                          })}
                        </TableCell>
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (heightRows || 24) * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {habilitaRodape &&
          <TablePagination
            labelRowsPerPage='Linhas por Pag.'
            rowsPerPageOptions={[10, 15, 25, rows.length]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </StyledPaper>
    </Root >
  );
}
