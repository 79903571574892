import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios'
import './Voucher.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl } from '@mui/material'
import real from './../../services/real'
import somarValores from './../../services/somarValores'
import Table from './../../components/Table'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment'
import Autocomplete from '@mui/material/Autocomplete';
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalFinanceiroCancelamentoVoucher from './../../components/modals/FinanceiroCancelamentoVoucher' 
import ModalNovaParcela from './../../components/modals/NovaParcela'
import ModalAlterarVencimentosVoucher from './../../components/modals/AlterarVencimentosVoucher'
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'cnpj'
import { styled } from '@mui/system';
import Color from 'color';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import moeda from './../../services/moeda'
import SelecionarVoucherCredito from './../../components/modals/SelecionarVoucherCredito'
import VisualizarAgendaOS from './../../components/modals/VisualizarAgendaOS'
import CurrencyInput from './../../components/mask/CurrencyInput'
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

const initialState = {
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoes_vencimento: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  qtdParcelasEditado: 0,
  voucher:{
    id: '',
    data_compra: '',
    data_valid_normal: '',
    data_valid_prom: '',
    numero: '',
    saldo: '',
    valor_credito: '',
    padrao: {
      id: '',
      ativo: '',
      descricao: '',
      nome: '',
      habilita: ''
    },
    clientes: [],
    produtos: [],
    responsavel: {
      id: '',
      pessoa:{
        id: '',
        fisica:{
          id: '',
          nome: '',
          sobrenome: ''
        }
      }
    },
    observacao: ''
  },
  cabecalhoClientes: [
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' }
  ],
  acoesTabelaClientes: [],
  cabecalhoProdutos: [
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Serviço' },
    { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
    { id: 'utilizado', numeric: false, disablePadding: false, label: 'Utilizado' },
    { id: 'utilizado_em', numeric: false, disablePadding: false, label: 'Utilizado Em - Situação' },
    { id: 'cliente', numeric: false, disablePadding: false, label: 'Cliente Beneficiado' },
  ],
  acoesTabelaProduto: ['viewAgenda'],
  cabecalhoProdutosCredito: [
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Nº OS' },
    { id: 'valor', numeric: true, disablePadding: false, label: 'Valor OS' },
    { id: 'valor_utilizado', numeric: true, disablePadding: false, label: 'Valor Utilizado' },
    { id: 'cliente', numeric: false, disablePadding: false, label: 'Cliente Beneficiado' },
  ],
  clientes: {
    list:[]
  },
  cliente: {
    id: '',
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  modal: {
    mensagem: '',
  },
  modalConfirmarOpen: false,
  modalFinanceiroCancelamentoVoucherOpen: false,
  dadosFinanceiro:{
    valor: 0,
    item_id: '',
    data_vencimento: '',
    formapagto_id: '',
    contfin_id: '',
    habilita: ''
  },
  logado: {
    caixa: false
  },
  itemSelecionado: {
    id: '',
    descricao: '',
    planocontas_id: '',
    plano_contas: {}
  },
  itens_receita_despesa: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  filtro: {
    numero: ''
  },
  produto:{
    id: '',
    valor: '',
    descricao: '',
    valor_total_minimo: '',
    valor_total_maximo: '',
    index: ''
  },
  produtoSelecionado:{
    id: '',
    descricao: ''
  },
  produtos: {
    list: []
  },
  novosServicos: [],
  openModalNovaParcela: false,
  headCellLancamentos: [
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: true, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: true, disablePadding: true, label: 'Desconto/Multa ou Juros' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  ],
  lancamentos: [],
  itens_voucher: [],
  itens_voucher_credito: [],
  vouchersCreditoSelecionados: [],
  dadosTelaVoucherCredito: {
    totalConta: real(0),
    totalVouchers: real(0),
    diferenca: real(0)
  },
  vouchersCreditoSelecionadosString: '',
  vouchersCliente: [],
  openModalSelecionarVoucherCredito: false,
  openModalVisualizarAgendaOS: false,
  pedidoServico: {
    id: '',
    numero: '',
    itens_pedido: [],
    pessoa:{
      tipo: 'FISICA',
      fisica: {
        nome: ''
      },
      juridica: {
        nomefantasia: ''
      }
    }
  },
  conta: {
    id: '',
    valor_total: real(0),
    valor_total_minimo: 0,
    valor_total_maximo: 0,
    tipo: '',
    item_id: '',
    descricao: '',
    qtd_parcelas: 1,
    parcelas: [
      {
        id: '',
        baixado: "",
        multajuros: real(0),
        datavencimento: moment().format('YYYY-MM-DD'),
        datavencimento_orig: moment().format('YYYY-MM-DD'),
        valorparcela: real(0),
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: 1,
        parcela_editada: false,
        index: 0,
        trava: false,
        vouchersCreditoSelecionados: [],
        vouchersCreditoSelecionadosString: ''
      }
    ]
  },
  informar_financeiro: true,
  configuracao_unidade: {
    unidade_id: '',
    fuso: '',
    nome_fuso: '',
    intervalo_agenda: 0,
    inicio_atendimento: '',
    fim_atendimento: '',
    momento_comissao: '',
    item_despesa_troco_id: '',
    midia_log_id: '',
    exige_solic_abertura_caixa: false,
    item_recdesp_cortesia_id: '',
    item_recdesp_desc_concedido_id: '',
    item_recdesp_desc_obitido_id: '',
    item_recdesp_multa_recebida_id: '',
    item_recdesp_multa_paga_id: '',
    mensagem_boas_vindas: '',
    perm_voucher_sem_fin: false
  },
  openModalAlterarVencimentosVoucher: false,
  dadosAlteracaoVencimento: {
    voucher_id: '',
    numero_voucher: '',
    nova_data_valid_normal: '',
    nova_data_valid_prom: '',
    data_valid_normal: '',
    data_valid_prom: '',
    lanc_fin: false
  },
  itens_receita: {
    list: []
  }
}

const ViewVoucher = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { voucherId } = useParams();
  const inputRef = createRef();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const configuracaoUnidade = async (unidade_id) => {
    try {
      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade_id}`, getToken())
    
      setState(prevState => ({...prevState,
        configuracao_unidade: {
          id: configuracao.id || '',
          unidade_id: configuracao.unidade_id || '',
          fuso: configuracao.fuso || '',
          nome_fuso: configuracao.nome_fuso || '',
          intervalo_agenda: configuracao.intervalo_agenda || '',
          inicio_atendimento: configuracao.inicio_atendimento || '',
          fim_atendimento: configuracao.fim_atendimento || '',
          momento_comissao: configuracao.momento_comissao || '',
          item_despesa_troco_id: configuracao.item_despesa_troco_id || '',
          midia_logo_id: configuracao.midia_logo_id || '',
          exige_solic_abertura_caixa: configuracao.exige_solic_abertura_caixa || false,
          item_recdesp_cortesia_id: configuracao.item_recdesp_cortesia_id || '',
          item_recdesp_desc_concedido_id: configuracao.item_recdesp_desc_concedido_id || '',
          item_recdesp_desc_obitido_id: configuracao.item_recdesp_desc_obitido_id || '',
          item_recdesp_multa_recebida_id: configuracao.item_recdesp_multa_recebida_id || '',
          item_recdesp_multa_paga_id: configuracao.item_recdesp_multa_paga_id || '',
          mensagem_boas_vindas: configuracao.mensagem_boas_vindas || '',
          perm_voucher_sem_fin: configuracao.perm_voucher_sem_fin
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const backPage = (timeout = 1000) => {
    const self = this
    setTimeout(() => {
      self.navigate("/venda_voucher");
    }, timeout)
  }

  const voltar = () => {
    navigate("/venda_voucher");
    return;
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const removerDependente = async (index) => {
    const {voucher} = state
    
    try {
      await axios.delete(`${window._env_.REACT_APP_API_URL}/voucher/dependentes/${voucher.clientes[index].id}`, getToken())
    
      voucher.clientes.splice(index, 1)
      
      setState(prevState => ({...prevState,
        voucher
      }))

      // window.location.reload()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldCliente = async (event, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        cliente: {
          id: '',
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const consultarPessoa = async (nome) => {
    try {
      const {voucher} = state
        
      let filtroPessoa = {
        unidadesnegocio: [voucher.padrao.unidade_id],
        ativo: 'Sim',
        nome: nome,
        cpf: '',
        nomefantasia: '',
        cnpj: '',
        flag: ''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/list`, filtroPessoa, getToken())

      const pessoas = []

      data.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: pessoa.tipo === 'FISICA' ? `${pessoa.fisica.nome} ${pessoa.fisica.sobrenome ? pessoa.fisica.sobrenome : '' }` : pessoa.juridica.nomefantasia,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.fisica.cpf ? cpf.format(pessoa.fisica.cpf) : 'N/A') : (pessoa.juridica.cnpj ? format(pessoa.juridica.cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        clientes: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const addDependente = async () => {
    const {cliente, voucher} = state

    try {
      await axios.post(`${window._env_.REACT_APP_API_URL}/voucher/dependentes/${cliente.pessoa_id}/${voucher.id}`, {}, getToken())
    
      localStorage.removeItem('pessoa_cadastrada')
      localStorage.removeItem('ultima_tela_cliente')
      localStorage.removeItem('origem_cadastro')
      localStorage.removeItem('voucher')

      window.location.reload()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const openCadastroClienteVoucher = () => {
    const {voucher} = state

    localStorage.setItem('ultima_tela_cliente', `/venda_voucher/view/${voucher.id}`);
    localStorage.setItem('origem_cadastro', 'dependente');
    localStorage.setItem('voucher', JSON.stringify(voucher))

    navigate("/cliente/cadastro");
    return;
  }

  const cancelarVoucher = () => {
    setState(prevState => ({...prevState,
      modal: {
        mensagem: "Deseja mesmo cancelar esse voucher?",
      },
      modalConfirmarOpen: true
    }))
  }

  const handleCloseCancelamento = () => {
    setState(prevState => ({...prevState,
      modal: {
        mensagem: "",
      },
      modalConfirmarOpen: false
    }))
  }

  const verificaMovimentacao = async (value) => {
    if(value){
      try {
        const {voucher} = state

        let {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/consultaMovimentacao/${voucher.id}`, getToken())
      
        if((data.valor_total > 0 && data.conciliado)){
          try {
  
            const dados = {
              unidadesnegocio: [voucher.padrao.unidade_id],
            }
      
            const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,getToken())
                  
            setState(prevState => ({...prevState,
              itens_receita_despesa: {
                list: data.filter(param => param.ativo && param.plano_contas.tipo === 'DESPESA').map(item => {
                  return {
                    id: item.id,
                    descricao: item.descricao,
                    planocontas_id: item.planocontas_id,
                    plano_contas: item.plano_contas
                  }
                })
              }
            }))
      
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
          }

          await consultarContaFinanceira(voucher.padrao.unidade_id)

          setState(prevState => ({...prevState,
            modalFinanceiroCancelamentoVoucherOpen: true,
            dadosFinanceiro:{
              valor: data.valor_total,
              item_id: '',
              data_vencimento: moment().format('YYYY-MM-DD'),
              formapagto_id: '',
              contfin_id: '',
              habilita: ''
            },
            modal: {
              mensagem: "",
            },
            modalConfirmarOpen: false
          }))
        }else{
          await confirmarCancelamento(true)
        }
        
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      setState(prevState => ({...prevState,
        modal: {
          mensagem: "",
        },
        modalConfirmarOpen: false
      }))
    }
  }
  
  const confirmarCancelamento = async (value) => {
    if(value){
      try {
      
        let {voucher, dadosFinanceiro, itemSelecionado} = state
  
        let dados = {}

        if(dadosFinanceiro.valor > 0){
          if(dadosFinanceiro.item_id === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar o item de despesa.'
              }
            }))
            return
          }

          if(dadosFinanceiro.data_vencimento === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a data de pagamento.'
              }
            }))
            return
          }

          if(dadosFinanceiro.formapagto_id === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a forma de pagamento.'
              }
            }))
            return
          }

          if(dadosFinanceiro.contfin_id === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a conta financeira.'
              }
            }))
            return
          }

          let conta = {
            descricao: `Devolução do voucher Nº ${voucher.numero}`,
            tipo: 'PAGAR',
            valortotal: (dadosFinanceiro.valor * -1),
            numparcela: 1,
            item_id: dadosFinanceiro.item_id,
            tipo_item: 'ITEMRECDESP',
            unidade_id: voucher.padrao.unidade_id,
            pessoa_id: voucher.responsavel.pessoa.id,
            recorrencia: false,
            qtdRecorrencia: 1,
            recorrenciaACada: 1,
            tipoRecorrencia: 'M',
            data_documento: moment().format('YYYY-MM-DD'),
            parcelas: [{
              baixado: true,
              datavencimento: dadosFinanceiro.data_vencimento,
              datavencimento_orig: dadosFinanceiro.data_vencimento,
              datapagamento: dadosFinanceiro.data_vencimento,
              valorparcela: (dadosFinanceiro.valor * -1),
              num: 1,
              documento: '',
              contfin_id: parseInt(dadosFinanceiro.contfin_id),
              formas_pgto: [
                {
                  valor_pagamento: (dadosFinanceiro.valor * -1),
                  formapagto_id: parseInt(dadosFinanceiro.formapagto_id),
                }
              ]
            }],
            centros_custo: itemSelecionado.plano_contas.centro_custos.map(centro => {
              return {
                valor_rateio: ((dadosFinanceiro.valor * -1)*centro.valor_percentual)/100,
                valor_percentual: centro.valor_percentual,
                centrocusto_id: centro.centrocusto_id
              }
            })
          }

          dados.conta = conta

        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cancelando Voucher...'
          }
        }))

        await axios.post(`${window._env_.REACT_APP_API_URL}/voucher/deletar/${voucher.id}`, dados, getToken())
  
        window.location.reload()
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      setState(prevState => ({...prevState,
        modalFinanceiroCancelamentoVoucherOpen: false,
        dadosFinanceiro:{
          valor: 0,
          item_id: '',
          data_vencimento: moment().format('YYYY-MM-DD'),
          formapagto_id: '',
          contfin_id: '',
          habilita: ''
        },
        modal: {
          mensagem: "",
        },
        modalConfirmarOpen: false
      }))
    }    
  }

  const handleCloseFinanceiroCancelamentoVoucher = () => {
    setState(prevState => ({...prevState,
      modalFinanceiroCancelamentoVoucherOpen: false
    }))
  }

  const updateFieldItem = (e, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        }
      }))

      return false
    } 

    let {dadosFinanceiro} = state

    dadosFinanceiro.item_id = value.id

    setState(prevState => ({...prevState,
      itemSelecionado: value,
      dadosFinanceiro
    }))
  }

  const updateFieldFinanceiro = (event) =>{
    let {dadosFinanceiro, formasPagamento} = state

    let value = event.target.value
    let name = event.target.name

    dadosFinanceiro[name] = value

    if(name === 'formapagto_id'){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

      dadosFinanceiro.habilita = formaPagtoSelecionada.habilita
      dadosFinanceiro.contfin_id = ""
    }
    
    setState(prevState => ({...prevState,dadosFinanceiro}))
  }

  const updateFiltro = (event) =>{
    let {filtro} = state
    filtro[event.target.name] = event.target.value
    setState(prevState => ({...prevState,filtro}))
  }

  const filtrar = async () => {
    let {filtro} = state

    if(filtro.numero === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o número do voucher.'
        }
      }))
      return
    }

    try {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Consultando voucher...'
        }
      }))

      const { data: voucher } = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/consultaNumero/${filtro.numero}`, getToken())
    
      navigate(`/venda_voucher/view/${voucher.id}`);
      window.location.reload()
      return;

      // setState(prevState => ({...prevState,
      //   voucher: voucher
      // }))
    
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarTabelaPrecoID = async (tabela_id, produto_id) => {
    try {
      const { data: tabelaPreco } = await axios.get(`${window._env_.REACT_APP_API_URL}/tabelaPreco/${tabela_id}`, getToken())

      let tabela_preco = {
        ...tabelaPreco,
        preco_produto: tabelaPreco.itens.filter(param => param.produto_id === produto_id)[0]
      }

      setState(prevState => ({...prevState,
        tabela_preco
      }))

      return tabela_preco

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldProduto = async (event) => {
    const {produto, produtos, voucher} = state
    let name = event.target.name
    let value = event.target.value
      
    if(name === 'id'){
      
      produto[name] = parseInt(value)
  
      let data_hoje = new Date()
      let data_prom = voucher.data_valid_prom
      let resp = null

      if(moment(data_prom).isAfter(data_hoje)){
        resp = await consultarTabelaPrecoID(voucher.padrao.tab_prom.id, parseInt(value))
      }else{
        resp = await consultarTabelaPrecoID(voucher.padrao.tab_normal.id, parseInt(value))
      }

      if(!resp.preco_produto){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Não foi encontrada nenhuma tabela de preço para esse serviço.'
          }
        }))
        return 
      }

      produto.valor = real(resp.preco_produto.valor_maximo)
      produto.valor_total_minimo = real(resp.preco_produto.valor_minimo)
      produto.valor_total_maximo = real(resp.preco_produto.valor_maximo)

      produto.descricao = produtos.list.filter(param => param.id === parseInt(value))[0].descricao
      
      setState(prevState => ({...prevState,
        produto,
      }))
    }else if(name === 'valor'){      
      produto[name] = value

      setState(prevState => ({...prevState,
        produto,
      }))
    }
  }

  const verificaValorProduto = (event) => {
    const {produto} = state

    const valor_minimo = parseFloat(produto.valor_total_minimo.replaceAll(".", "").replace(",", "."))
    const valor_maximo = parseFloat(produto.valor_total_maximo.replaceAll(".", "").replace(",", "."))

    const valor_informado = parseFloat(event.target.value.replaceAll(".", "").replace(",", "."))

    if(valor_informado > valor_maximo){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'O valor informado ultrapassa o valor máximo do serviço.'
        }
      }))

      produto.valor = produto.valor_total_maximo
    }

    if(valor_informado < valor_minimo){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'O valor informado é inferiro ao valor mínimo do serviço.'
        }
      }))

      produto.valor = produto.valor_total_maximo
    }

    setState(prevState => ({...prevState,
      produto
    }))
  }

  const addServico = () => {
    const {produto, voucher, produtos, conta} = state

    if(produto.id === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor selecionar o produto'
        }
      }))
      return
    }

    if(produto.valor === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor selecionar o valor do produto'
        }
      }))
      return
    }

    let index = produtos.list.findIndex(param => param.id === produto.id)

    voucher.produtos.push({
      produto_id: produto.id,
      utilizado: false,
      valor: parseFloat(produto.valor.replaceAll(".", "").replace(",", ".")),
      voucher_id: voucher.id,
      produto: {
        id: produto.id,
        descricao: produtos.list[index].descricao
      }
    })

    voucher.saldo += parseFloat(produto.valor.replaceAll(".", "").replace(",", "."))
    voucher.valor_credito += parseFloat(produto.valor.replaceAll(".", "").replace(",", "."))

    conta.valor_total = real(voucher.valor_credito)
    conta.qtd_parcelas = 1
    conta.parcelas = [
      {
        id: '',
        baixado: "",
        multajuros: real(0),
        datavencimento: moment().format('YYYY-MM-DD'),
        datavencimento_orig: moment().format('YYYY-MM-DD'),
        valorparcela: real(voucher.valor_credito),
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: 1,
        parcela_editada: false,
        index: 0,
        trava: false,
        vouchersCreditoSelecionados: [],
        vouchersCreditoSelecionadosString: ''
      }
    ]

    setState(prevState => ({...prevState,
      voucher,
      conta,
      produto:{
        id: '',
        valor: '',
        descricao: '',
        valor_total_minimo: '',
        valor_total_maximo: '',
        index: ''
      }
    }))

  }

  const handleCloseNovaParcela = () => {
    setState(prevState => ({...prevState,
      openModalNovaParcela: false,
      conta: {
          id: '',
          valor_total: real(0),
          qtd_parcelas: 1,
          parcelas: [
            {
              id: '',
              baixado: "",
              datavencimento: moment().format('YYYY-MM-DD'),
              datavencimento_orig: moment().format('YYYY-MM-DD'),
              valorparcela: real(0),
              multajuros: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0
            }
          ]
        },
    }))
  }

  const updateFieldParcela = async (event, index) => {
    const {conta, formasPagamento, voucher} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index][name] = value
    }else{
      conta.parcelas[index][name] = value
    }

    if(name === 'formapagto_id'){
      if(value !== ''){
        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
  
        if(formaPagtoSelecionada.slug === "voucher"){
        
          conta.numparcela = 1
          
          conta.parcelas = [{
            id: '',
            baixado: false,
            datavencimento: moment().format('YYYY-MM-DD'),
            datavencimento_orig: moment().format('YYYY-MM-DD'),
            valorparcela: conta.valor_total,
            multajuros: real(somarValores(conta.parcelas.map(parcela => { return parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", ".")) }))),
            formapagto_id: 9,
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: false,
            index: 0,
            trava: false
          }]

          await buscaVouchersCredito(voucher.responsavel.pessoa.id, 'CREDITO')
          
        }else{ 
          if(index === 0){
            setState(prevState => ({...prevState,
              vouchersCreditoSelecionados: [],
              dadosTelaVoucherCredito: {
                totalConta: real(0),
                totalVouchers: real(0),
                diferenca: real(0)
              },
              vouchersCreditoSelecionadosString: '',
              vouchersCliente: []
            }))
          }
        }

        conta.parcelas[index].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[index].contfin_id = ""
      }else{
        conta.parcelas[index].habilita = ""
        conta.parcelas[index].contfin_id = ""
      }
    }
    
    setState(prevState => ({...prevState, 
      conta,
      dadosTelaVoucherCredito: {
        totalConta: conta.valor_total,
        totalVouchers: real(0),
        diferenca: conta.valor_total
      },
    }))
  }

  const buscaVouchersCredito = async (pessoa_id, tipo) => {
    try {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Buscando Vouchers'
        }
      }))


      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscarVoucherPessoa/${pessoa_id}/${tipo}`, getToken())

      if(data.length > 0){
        setState(prevState => ({...prevState,
          vouchersCliente: data.map(value => {
            return {
              ...value,
              saldo_form: 'R$ ' + real(value.saldo),
              novo_saldo_form: 'R$ ' + real(value.saldo),
            }
          }),
          openModalSelecionarVoucherCredito: true,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cliente não possui voucher'
          }
        }))
      }
    
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const selecionarVoucherCredito = async (row) => {
    let {vouchersCreditoSelecionados, dadosTelaVoucherCredito, vouchersCliente} = state

    let index = vouchersCreditoSelecionados.findIndex(param => param.voucher_id === row.id)
    
    let valor_total = parseFloat(dadosTelaVoucherCredito.totalConta.replaceAll(".", "").replace(",", "."))
    
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))
    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    
    let index_voucher = await vouchersCliente.findIndex(param => param.id === row.id)

    if(index === -1){
      let valor_a_ultilizar = 0

      if(row.saldo > diferenca){
        totalVouchers += diferenca
        valor_a_ultilizar = diferenca
        vouchersCliente[index_voucher].novo_saldo_form = real(row.saldo - diferenca)
      }else{
        totalVouchers += row.saldo
        valor_a_ultilizar = row.saldo
        vouchersCliente[index_voucher].novo_saldo_form = real(0)
      }

      vouchersCreditoSelecionados.push({
        voucher_id: row.id,
        numero: row.numero,
        saldo: row.saldo,
        valor_a_ultilizar
      })

      dadosTelaVoucherCredito.diferenca = real(valor_total - totalVouchers)
      dadosTelaVoucherCredito.totalVouchers = real(totalVouchers)
    }else{
      vouchersCliente[index_voucher].novo_saldo_form = real(parseFloat(vouchersCliente[index_voucher].novo_saldo_form.replaceAll(".", "").replace(",", ".")) + vouchersCreditoSelecionados[index].valor_a_ultilizar)
      
      dadosTelaVoucherCredito.totalVouchers = real(parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", ".")) - vouchersCreditoSelecionados[index].valor_a_ultilizar)
      dadosTelaVoucherCredito.diferenca = real(valor_total - parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", ".")))
      
      vouchersCreditoSelecionados.splice(index,1)
    }

    let vouchersCreditoSelecionadosString = ''

    for (let i = 0; i < vouchersCreditoSelecionados.length; i++) {
      if((i + 1) === vouchersCreditoSelecionados.length){
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero}`
      }else{
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero} - `
      }
      
    }

    setState(prevState => ({...prevState,
      vouchersCreditoSelecionados,
      dadosTelaVoucherCredito,
      vouchersCreditoSelecionadosString,
      vouchersCliente
    }))
  }

  const finalizarSelecaoVoucher = () => {
    let {dadosTelaVoucherCredito, conta} = state

    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))

    if(totalVouchers > 0){
      if(diferenca > 0){
        conta.parcelas[0].valorparcela = dadosTelaVoucherCredito.totalVouchers
  
        conta.qtd_parcelas = 2
  
        conta.parcelas.push({
          id: '',
          baixado: false,
          datavencimento: moment().format('YYYY-MM-DD'),
          datavencimento_orig: moment().format('YYYY-MM-DD'),
          valorparcela: real(diferenca),
          multajuros: real(0),
          formapagto_id: "",
          contfin_id: "",
          documento: "",
          habilita: "",
          num: 2,
          parcela_editada: false,
          index: 1,
          trava: false
        })
      }
    }else{
      conta.parcelas[0].formapagto_id = ''
    }

    setState(prevState => ({...prevState,
      openModalSelecionarVoucherCredito: false,
      conta
    }))
  }

  const updateFieldInformarFinanceiro = () => {
    setState(prevState => ({...prevState,
      informar_financeiro: !state.informar_financeiro
    }))
  }

  const updateFieldConta = (event) => {
    const {conta} = state

    if (event.target.name === 'valor_total') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value
    setState(prevState => ({...prevState,conta}))
  }

  const mudarParcelas = async (event) => {
    const {conta} = state
    
    let array = []
    const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    const dataHoje = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))

    if(!conta.parcelas[0].trava){
      let valorDividido = valorTotal / qtdParcelasInfo
      valorDividido = parseFloat(valorDividido.toFixed(2))
  
      let sobra = valorTotal - (valorDividido * qtdParcelasInfo)
  
      sobra = parseFloat(sobra.toFixed(2))
  
      for (let i = 0; i < qtdParcelasInfo; i++) {
        let mesAcrecentado = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
        mesAcrecentado.setMonth(dataHoje.getMonth() + (i))
  
        let parcela = {
          id: conta.parcelas[i] ? conta.parcelas[i].id : '',
          baixado: "",
          datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
          valorparcela: real(valorDividido),
          multajuros: real(0),
          formapagto_id: "",
          documento: "",
          num: i + 1,
          parcela_editada: false,
          index: i,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        }

        if(parcela.id === '' || !parcela.id){
          delete parcela.id
        }

        array.push({
          ...parcela
        })
      }
  
      if (sobra !== 0) {
        array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
      }

      conta.numparcela = qtdParcelasInfo
      conta.qtd_parcelas = qtdParcelasInfo
    }

    if(conta.parcelas[0].trava){
      array.push({...conta.parcelas[0]})

      let valor_restante = valorTotal -  parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
      qtdParcelasInfo -= 1

      let valorDividido = valor_restante / qtdParcelasInfo
      valorDividido = parseFloat(valorDividido.toFixed(2))
      
      let sobra = valor_restante - (valorDividido * qtdParcelasInfo)

      for (let i = 0; i < qtdParcelasInfo; i++) {
        // let mesAcrecentado = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
        // mesAcrecentado.setMonth(dataHoje.getMonth() + (i))
  
        array.push({
          baixado: "",
          datavencimento: moment().format('YYYY-MM-DD'),
          valorparcela: real(valorDividido),
          multajuros: real(0),
          formapagto_id: "",
          documento: "",
          num: i + 2,
          parcela_editada: false,
          index: i + 1,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        })

        if (sobra !== 0) {
          array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
        }
      }

      conta.numparcela = qtdParcelasInfo + 1
      conta.qtd_parcelas = qtdParcelasInfo + 1

    }
    
    conta.parcelas = array

    // await buscaVouchersCredito(voucher.responsavel.id, 'CREDITO')

    setState(prevState => ({...prevState, 
      conta,
      dadosTelaVoucherCredito: {
        totalConta: real(0),
        totalVouchers: real(0),
        diferenca: real(0),
        parcela_index: 0
      }
    }))
  }

  const verificaDataParcela = (event, index) => {
    const {conta} = state
        
    for (let i = (index + 1); i < conta.parcelas.length; i++) {
      let count = (i - index)

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      conta.parcelas[i].datavencimento = novaData
      conta.parcelas[i].datavencimento_orig = novaData
    }

    setState(prevState => ({...prevState, conta }))
  }

  const openVouchers = () => {
  
    setState(prevState => ({...prevState,
      openModalSelecionarVoucherCredito: true,
    }))

    // if(!financeiro_fechado){
    // }else{
    //   setState(prevState => ({...prevState,
    //     alerta: {
    //       open: true,
    //       severity: 'info',
    //       message: 'Financeiro fechado, não é possivel alterar os vouchers'
    //     }
    //   }))
    // }
  }

  const marcaParcelas = (event, index) => {
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelasContaFin = (event, index) => {
    
    const {conta, contasFinanceira} = state
        
    const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(contaFinanceiraSelecionada){
        if(conta.parcelas[i].habilita === contaFinanceiraSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const salvarVoucher = async () => {
    const {voucher} = state
    
    if(voucher.conta && voucher.padrao.habilita === 'PRODUTO'){
      let valor_conta = voucher.conta.valortotal
      let valor_total_servicos = somarValores(voucher.produtos.map(value => {return value.valor}))
      valor_total_servicos += somarValores(voucher.itens_compra_voucher.map(value => {return value.valorparcela}))
  
      if(valor_total_servicos < valor_conta){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total dos serviços é inferior ao financeiro lançado.'
          }
        }))
        return
      }
  
      if(valor_total_servicos > valor_conta){
        let diferença = valor_total_servicos - valor_conta
  
        try {
          const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${voucher.padrao.unidade_id}`, getToken())
      
          // if(state.logado.caixa){
          //   const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
            
          //   setState(prevState => ({...prevState,
          //     contasFinanceira: {
          //       list: contasFinanceira.filter(param => param.id === ultimo['conta.id'])
          //     }
          //   }))
    
          // }else{
          // }
          setState(prevState => ({...prevState,
            contasFinanceira: {
              list: contasFinanceira
            }
          }))
  
          const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
  
          setState(prevState => ({...prevState,
            formasPagamento: {
              list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
            }
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }
  
        setState(prevState => ({...prevState,
          openModalNovaParcela: true,
          conta: {
            id: '',
            valor_total: real(diferença),
            qtd_parcelas: 1,
            parcelas: [
              {
                id: '',
                baixado: "",
                datavencimento: moment().format('YYYY-MM-DD'),
                datavencimento_orig: moment().format('YYYY-MM-DD'),
                valorparcela: real(diferença),
                multajuros: real(0),
                formapagto_id: "",
                contfin_id: "",
                documento: "",
                habilita: "",
                num: 1,
                parcela_editada: false,
                index: 0
              }
            ]
          },
        }))
  
      }else{
        await confirmarSalvarVoucher(true)
      }
    }else{
      await confirmarSalvarVoucher(true)
    }
  }

  const confirmarSalvarVoucher = async (value) => {
    if(value){
      const {voucher, conta, vouchersCreditoSelecionados} = state

      try {

        let dados = {
          saldo: voucher.saldo,
          valor_credito: voucher.valor_credito,
          produtos: voucher.produtos.map(value => {
            return {
              id: value.id || '',
              produto_id: value.produto_id,
              valor: value.valor
            }
          }),
          observacao: voucher.observacao
        }

        if(conta.valor_total !== '0,00'){
          for (const parcela of conta.parcelas) {

            if(parcela.valorparcela === ''){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'Por favor informar o valor da parcela.'
                }
              }))
              return
            }
            if(parcela.datavencimento === ''){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'Por favor informar a data de vencimento.'
                }
              }))
              return
            }
            if(parcela.formapagto_id === ''){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'Por favor informar a forma de pagamento.'
                }
              }))
              return
            }
            if(parcela.contfin_id === '' && parseInt(parcela.formapagto_id) !== 9){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'Por favor informar a conta financeira de pagamento.'
                }
              }))
              return
            }

            let valor_parcela = parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))
            let valor_desconto = parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", "."))

            if(valor_desconto > 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'Não é possivel lançar um acrescimo na parcela.'
                }
              }))
              return
            }

            valor_desconto = valor_desconto * -1

            if(valor_desconto > valor_parcela){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'O valor do desconto/multa não pode ser superior ao valor da parcela.'
                }
              }))
              return
            }
          }
        
          dados.parcelas = conta.parcelas.map(parcela => {
            return {
              valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
              multajuros: parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", ".")),
              formapagto_id: parseInt(parcela.formapagto_id),
              contfin_id: parseInt(parcela.contfin_id),
              datavencimento: parcela.datavencimento,
              documento: parcela.documento
            }
          })

          if(vouchersCreditoSelecionados.length > 0){
            dados.vouchers_credito = vouchersCreditoSelecionados
          }

          for (let i = 0; i < dados.parcelas.length; i++) {
            if(dados.parcelas[i].formapagto_id === 9){
              delete dados.parcelas[i].contfin_id
            }
          }
        }

        if(state.logado.caixa){
          const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
          
          dados.caixa = ultimo.id
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Alterando Voucher...'
          }
        }))

        await axios.put(`${window._env_.REACT_APP_API_URL}/voucher/${voucher.id}`, dados, getToken())
    
        window.location.reload()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

    }else{
      handleCloseNovaParcela()
    }
  }

  const removeServico = (row) => {  
    let {voucher} = state

    let produtos = voucher.produtos.sort((a, b) => (a.produto.descricao > b.produto.descricao) ? 1 : ((b.produto.descricao > a.produto.descricao) ? -1 : 0))
  
    let produto_removido = produtos[row.index]

    voucher.saldo -= produto_removido.valor
    voucher.valor_credito -= produto_removido.valor

    produtos.splice(row.index, 1)

    voucher.produtos = produtos

    setState(prevState => ({...prevState,
      voucher
    }))
  }
    
  const viewAgenda = (row) => {
    localStorage.setItem('agenda_voucher', JSON.stringify(row))

    navigate("/agenda_ambiente");
    return;
  }

  const viewOS = async (row) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/pedidoServico/${row._id}`, getToken())
        
      setState(prevState => ({...prevState,
        pedidoServico: data,
        openModalVisualizarAgendaOS: true
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseVisualizarAgendaOS = () => {
    setState(prevState => ({...prevState,
      openModalVisualizarAgendaOS: false,
      pedidoServico: {
        id: '',
        numero: '',
        itens_pedido: [],
        pessoa:{
          tipo: 'FISICA',
          fisica: {
            nome: ''
          },
          juridica: {
            nomefantasia: ''
          }
        }
      }
    }))
  }

  const updateFieldVoucher = (event) => {
    let {voucher} = state
    voucher[event.target.name] = event.target.value
    setState(prevState => ({...prevState,voucher}))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) }))
      
      const diferenca = valorTotal - somaParcelasEditada
      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))
      
      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const consultaVoucher = async () => {
    let pessoa_cadastrada = JSON.parse(localStorage.getItem('pessoa_cadastrada'))
    let origem_cadastro = localStorage.getItem('origem_cadastro')

    let { conta } = state

    if(pessoa_cadastrada){
      let voucher = JSON.parse(localStorage.getItem('voucher'))

      if(!voucher.conta){
        conta.valor_total = real(voucher.valor_credito)
        conta.parcelas[0].valorparcela = real(voucher.valor_credito)
      }

      if(origem_cadastro === 'dependente'){
        setState(prevState => ({...prevState,
          cliente: {
            id: pessoa_cadastrada.pessoa_id,
            nome: pessoa_cadastrada.cpf ? pessoa_cadastrada.nome : pessoa_cadastrada.nomefantasia,
            cpf_cnpj: pessoa_cadastrada.cpf ? pessoa_cadastrada.cpf : pessoa_cadastrada.cnpj,
            pessoa_id: pessoa_cadastrada.pessoa_id
          },
          voucher,
          lancamentos: voucher.conta.parcelas.sort((a, b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0)).map((parcela, key) => {
            let formapagto_descricao = ''

            for (let i = 0; i < parcela.valores_pago.length; i++) {
              if((i +1) === parcela.valores_pago.length){
                formapagto_descricao += `${parcela.valores_pago[i].forma_pagto.descricao}`
              }else{
                formapagto_descricao += `${parcela.valores_pago[i].forma_pagto.descricao} - `
              }
            }
            
            return {
              num_parcela: parcela.num + '/' + voucher.conta.numparcela,
              datavencimento: moment(parcela.datavencimento).format('DD/MM/YYYY'),
              datapagamento: parcela.datapagamento ? moment(parcela.datapagamento).format('DD/MM/YYYY') : '',
              valorparcela: real(parcela.valorparcela),
              multajuros: parcela.multajuros ? real(parcela.multajuros) : real(0),
              formapagto_descricao,
              situacao: parcela.situacao,
              index: key
            }
          }),
          conta
        }))
      }

      return voucher
    }else{
      if (voucherId) {
        try {
          const { data: voucher } = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/${voucherId}`, getToken())

          const { data: serviços } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/servicosByUnidade/${voucher.padrao.unidade_id}`, getToken())
        
          if(voucher.cancelamento_voucher){
            setState(prevState => ({...prevState,
              acoesTabelaProduto: [],
              acoesTabelaClientes: []
            }))
          }

          if(voucher.conta){
            setState(prevState => ({...prevState,
              lancamentos: voucher.conta.parcelas.sort((a, b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0)).map((parcela, key) => {
                let formapagto_descricao = ''
  
                for (let i = 0; i < parcela.valores_pago.length; i++) {
                  if((i +1) === parcela.valores_pago.length){
                    formapagto_descricao += `${parcela.valores_pago[i].forma_pagto.descricao}`
                  }else{
                    formapagto_descricao += `${parcela.valores_pago[i].forma_pagto.descricao} - `
                  }
                }
                
                return {
                  num_parcela: parcela.num + '/' + voucher.conta.numparcela,
                  datavencimento: moment(parcela.datavencimento).format('DD/MM/YYYY'),
                  datapagamento: parcela.datapagamento ? moment(parcela.datapagamento).format('DD/MM/YYYY') : '',
                  valorparcela: real(parcela.valorparcela),
                  multajuros: parcela.multajuros ? real(parcela.multajuros) : real(0),
                  formapagto_descricao,
                  situacao: parcela.situacao,
                  index: key
                }
              }),
            }))
          }else{
            conta.valor_total = real(voucher.valor_credito)
            conta.parcelas[0].valorparcela = real(voucher.valor_credito)
          }

          let itens_voucher = []

          if(voucher.padrao.tipo === 'PRODUTO'){
            itens_voucher = voucher.itens_voucher.map((value, key) => {
              return {
                descricao: value.descricao,
                valor: 'R$ ' + real(value.valor_servico),
                valor_utilizado: 'R$ ' + real(value.valor_utilizado / (voucher.itens_voucher.filter(param => param.id === value.id).length)),
                utilizado: true,
                status: value.situacao,
                utilizado_em: `${moment(value.data_hora_inicio).format('DD/MM/YYYY')} - ${value.situacao}`,
                data_hora_inicio: value.data_hora_inicio,
                cliente: value.pessoa_tipo === 'FISICA' ? value.nome : value.nomefantasia,
                tela: 'AGENDA',
                voucher_id: '',
                index: key
              }
            })
          }

          let itens_voucher_credito = voucher.itens_voucher_credito.map((value, key) => {
            return {
              _id: value.id,
              descricao: `OS Nº ${value.numero}`,
              valor: 'R$ ' + real(value.valortotal),
              valor_utilizado: 'R$ ' + real(value.valor_utilizado),
              cliente: value.pessoa_tipo === 'FISICA' ? value.nome : value.nomefantasia,
              tela: 'OS',
              voucher_id: '',
              index: key
            }
          })

          let index = itens_voucher_credito.length

          for (const value of voucher.itens_compra_voucher) {
            itens_voucher.push({
              descricao: value.descricao,
              valor: 'R$ ' + real(value.valorparcela),
              valor_utilizado: 'R$ ' + real(value.valor_utilizado),
              utilizado: true,
              status: '',
              utilizado_em: `${moment(value.datalancamento).format('DD/MM/YYYY')} - ${value.ativo ? 'ATIVO' : 'INATIVO'}`,
              data_hora_inicio: '',
              cliente: value.tipo === 'FISICA' ? value.nome : value.nomefantasia,
              tela: 'VOUCHER',
              voucher_id: value.id,
              index
            })

            index++
          }

          setState(prevState => ({...prevState,
            voucher: voucher,
            itens_voucher,
            itens_voucher_credito,
            produtos:{
              list: serviços.map(value => {
                return {
                  id: value.id,
                  descricao: value.descricao
                }
              })
            },
            conta
          }))

          return voucher
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }
      }
    }
  }

  const consultarContaFinanceira = async (unidade_id) => {
    try {
      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${unidade_id}`, getToken())

      if(state.logado.caixa){
        const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
        
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira.filter(param => param.id === ultimo['conta.id'])
          }
        }))

      }else{
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira
          }
        }))
      }
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarItens = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados, getToken())

      const itens = []

      data.filter(param => param.plano_contas.tipo === 'RECEITA').sort((a, b) => (a.plano_contas.codigo > b.plano_contas.codigo) ? 1 : (b.plano_contas.codigo > a.plano_contas.codigo) ? -1 : 0).forEach(item => {
        itens.push({
          id: item.id,
          descricao: item.descricao,
          planocontas: item.plano_contas.codigo + ' - ' + item.plano_contas.descricao,
          sequencia: item.plano_contas.sequencia,
          tipo: item.plano_contas.tipo === 'DESPESA' ? 'PAGAR' : 'RECEBER',
          planocontas_id: item.plano_contas.id,
          codigo: item.plano_contas.codigo,
          unidade_id: item.plano_contas.unidade_id,
          ativo: item.ativo
        })
      })

      setState(prevState => ({...prevState,
        itens_receita: {
          list: itens
        },
        loading: false
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const alterarVencimentos = async () => {
    let {voucher} = state

    let conta = {
      id: '',
      valor_total: real(0),
      valor_total_minimo: 0,
      valor_total_maximo: 0,
      tipo: '',
      item_id: '',
      descricao: '',
      qtd_parcelas: 1,
      parcelas: [
        {
          id: '',
          baixado: "",
          multajuros: real(0),
          datavencimento: moment().format('YYYY-MM-DD'),
          datavencimento_orig: moment().format('YYYY-MM-DD'),
          valorparcela: real(0),
          formapagto_id: "",
          contfin_id: "",
          documento: "",
          habilita: "",
          num: 1,
          parcela_editada: false,
          index: 0,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        }
      ]
    }

    try {
      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
  
      setState(prevState => ({...prevState,
        formasPagamento: {
          list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
        }
      }))

      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${voucher.padrao.unidade_id}`, getToken())

      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira
        }
      }))

      conta.tipo = 'RECEBER'
      conta.descricao = `Alteração dos Vencimentos do Voucher Nº ${voucher.numero}`

      await consultarItens(voucher.padrao.unidade_id)

      setState((prevState) => ({
        ...prevState,
        openModalAlterarVencimentosVoucher: true,
        conta,
        dadosAlteracaoVencimento: {
          voucher_id: voucher.id,
          numero_voucher: voucher.numero,
          nova_data_valid_normal: voucher.data_valid_normal,
          nova_data_valid_prom: voucher.data_valid_prom,
          data_valid_normal: voucher.data_valid_normal,
          data_valid_prom: voucher.data_valid_prom,
          lanc_fin: false
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldNovaValidade = (event) => {
    let {dadosAlteracaoVencimento} = state

    dadosAlteracaoVencimento[event.target.name] = event.target.value

    setState((prevState) => ({
      ...prevState,
      dadosAlteracaoVencimento
    }))
  }

  const updateFieldLancFinanceiro = () => {
    let {dadosAlteracaoVencimento} = state

    dadosAlteracaoVencimento.lanc_fin = !dadosAlteracaoVencimento.lanc_fin

    setState((prevState) => ({
      ...prevState,
      dadosAlteracaoVencimento
    }))
  }

  const handleCloseModalAlterarVencimentosVoucher = () => {
    setState((prevState) => ({
      ...prevState,
      dadosAlteracaoVencimento: {
        voucher_id: '',
        numero_voucher: '',
        nova_data_valid_normal: '',
        nova_data_valid_prom: '',
        data_valid_normal: '',
        data_valid_prom: '',
        lanc_fin: false
      },
      openModalAlterarVencimentosVoucher: false
    }))
  }

  const confirmarNovasDatas = async (value) => {
    if(value){
      let {dadosAlteracaoVencimento, conta, voucher} = state

      if(dadosAlteracaoVencimento.nova_data_valid_prom === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a nova data promocional'
          }
        }))
        return
      }

      if(dadosAlteracaoVencimento.nova_data_valid_normal === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a nova data normal'
          }
        }))
        return
      }

      if(dadosAlteracaoVencimento.lanc_fin){
        if(conta.item_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o item de receita'
            }
          }))
          return
        }

        if(conta.descricao === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a descrição da conta'
            }
          }))
          return
        }

        if(conta.valor_total === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o valor total da conta'
            }
          }))
          return
        }

        for (const parcela of conta.parcelas) {
          if(parcela.valorparcela === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar o valor da parcela.'
              }
            }))
            return
          }

          if(parcela.datavencimento === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a data de vencimento.'
              }
            }))
            return
          }

          if(parcela.formapagto_id === ''){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a forma de pagamento.'
              }
            }))
            return
          }

          if(parcela.contfin_id === '' && parseInt(parcela.formapagto_id) !== 9){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a conta financeira de pagamento.'
              }
            }))
            return
          }
        }
      }

      try {
        let dados = {
          nova_data_valid_normal: dadosAlteracaoVencimento.nova_data_valid_normal,
          nova_data_valid_prom: dadosAlteracaoVencimento.nova_data_valid_prom,
        }

        if(dadosAlteracaoVencimento.lanc_fin){
          dados.conta ={
            descricao: conta.descricao,
            tipo: conta.tipo,
            item_id: parseInt(conta.item_id),
            tipo_item: 'ITEMRECDESP',
            valortotal: parseFloat(conta.valor_total.replaceAll(".", "").replace(",", ".")),
            recorrencia: false,
            qtdRecorrencia: 1,
            recorrenciaACada: 1,
            tipoRecorrencia: 'M',
            pessoa_id: voucher.responsavel.pessoa.id,
            unidade_id: voucher.padrao.unidade_id,
            numparcela: conta.parcelas.length,
            parcelas: conta.parcelas.map(parcela => {
              return {
                baixado: true,
                datavencimento: parcela.datavencimento,
                datavencimento_orig: parcela.datavencimento,
                datapagamento: parcela.datavencimento,
                valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
                multajuros: parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", ".")),
                num: parcela.num,
                contfin_id: parseInt(parcela.contfin_id),
                formas_pgto: [{
                  formapagto_id: parseInt(parcela.formapagto_id),
                  valor_pagamento: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))
                }]
              }
            })
          }
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Alterando datas...'
          }
        }))

        await axios.put(`${window._env_.REACT_APP_API_URL}/voucher/novaDataVencimento/${dadosAlteracaoVencimento.voucher_id}`, {...dados}, getToken())

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Datas Alteradas...'
          }
        }))

        handleCloseModalAlterarVencimentosVoucher()
        window.location.reload()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

    }else{
      handleCloseModalAlterarVencimentosVoucher()
    }
  } 

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))
      
      let permissoes = perfil.permissoes.filter(param => (param.tela.modulo.slug === 'venda-voucher' && param.tela.slug === 'cadastro'))[0]
      let permissoes_vencimento = perfil.permissoes.filter(param => param.tela.slug === 'alterar-vencimento-voucher')[0]
      
      if (!permissoes.visualizar) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warnig',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))

        backPage()
      }

      if(permissoes.alterar){
        setState(prevState => ({...prevState,
          acoesTabelaProduto: ['removeServicoVoucher', 'viewAgenda'],
          acoesTabelaClientes: ['remove']
        }))
      }

      if(permissoes_vencimento){
        setState(prevState => ({...prevState,
          permissoes_vencimento
        }))
      }

      setState(prevState => ({...prevState,
        permissoes
      }))

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]

      let perfilLogado = ''

      if (permissoesCaixa) {
        perfilLogado = 'caixa'
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        perfilLogado = 'contas-pagar-receber'
        setState(prevState => ({...prevState,
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      const caixa = JSON.parse(localStorage.getItem('caixa'))
      const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

      if(perfilLogado === 'contas-pagar-receber'){
        if(ultimoCaixa && !ultimoCaixa.fechado){
          setState(prevState => ({...prevState, logado: { caixa } }))
        }else{
          setState(prevState => ({...prevState,
            logado: {
              caixa: false
            }
          }))
        }

      }else{
        setState(prevState => ({...prevState, logado: { caixa } }))
      }  

      if(ultimoCaixa && ultimoCaixa['status_atual.status'] === 'ABERTO'){
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      let voucher = await consultaVoucher()
      await configuracaoUnidade(voucher.padrao.unidade_id)
      await consultarContaFinanceira(voucher.padrao.unidade_id)

      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

      setState(prevState => ({...prevState,
        formasPagamento: {
          list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
        }
      }))
    
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { voucher, cabecalhoClientes, acoesTabelaClientes, cabecalhoProdutos, acoesTabelaProduto, 
    clientes, cliente, itemSelecionado, itens_receita_despesa, dadosFinanceiro, formasPagamento,
    contasFinanceira, logado, permissoes, filtro, produto, produtos, lancamentos, cabecalhoProdutosCredito,
    conta, informar_financeiro, configuracao_unidade, permissoes_vencimento } = state

  let array = []
  let ultilizado = false
  let itens_pedido = []

  if(voucher.itens_voucher){
    itens_pedido = voucher.itens_voucher.map(value => {
      return {
        ...value
      }
    })
  }

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Voucher...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div id='view'>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={8} xs={12} sm={6}>
                <h1 className="titulo">
                  Voucher - 
                  <span style={{color:'green'}}>Nº {voucher.numero}</span>
                  {voucher.cancelamento_voucher &&
                    <span style={{color:'red'}}> (Cancelado) </span>
                  }
                </h1>
              </Grid>
              <Grid item md={2} xs={12} sm={3} className="mg_top_10">
                <TextField 
                  className="input" 
                  type='number'
                  label="Número Voucher" 
                  variant="outlined" 
                  size="small" 
                  name="numero" 
                  value={filtro.numero}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => updateFiltro(e)}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={4} className="mg_top_10">
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SearchIcon />} onClick={e => filtrar(e)}>Buscar</Button>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={3} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Cliente Comprador" 
                  variant="outlined" 
                  size="small" 
                  name="cliente" 
                  value={voucher.responsavel.pessoa.tipo === 'FISICA' ? 
                    (voucher.responsavel.pessoa.fisica.nome + (voucher.responsavel.pessoa.fisica.sobrenome ? ` ${voucher.responsavel.pessoa.fisica.sobrenome}` : '')) : 
                    voucher.responsavel.pessoa.juridica.nomefantasia}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}/>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Voucher" 
                  variant="outlined" 
                  size="small" 
                  name="voucher" 
                  value={voucher.padrao.nome}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}/>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Tipo Voucher" 
                  variant="outlined" 
                  size="small" 
                  name="habilita" 
                  value={voucher.padrao.habilita}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}/>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Valor do Credito" 
                  variant="outlined" 
                  size="small" 
                  name="valor_credito" 
                  value={'R$ ' + real(voucher.valor_credito)}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}/>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Saldo" 
                  variant="outlined" 
                  size="small" 
                  name="saldo" 
                  value={'R$ ' + real(voucher.saldo)}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}/>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Data da Compra" 
                  variant="outlined" 
                  size="small" 
                  name="cliente" 
                  value={moment(voucher.data_compra).format('DD/MM/YYYY')}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Data Validade Prom." 
                  variant="outlined" 
                  size="small" 
                  name="cliente" 
                  value={moment(voucher.data_valid_prom).format('DD/MM/YYYY')}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Data Validade Normal" 
                  variant="outlined" 
                  size="small" 
                  name="cliente" 
                  value={moment(voucher.data_valid_normal).format('DD/MM/YYYY')}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Ativo" 
                  variant="outlined" 
                  size="small" 
                  name="cliente" 
                  value={voucher.ativo ? 'Sim': 'Não'}
                  InputLabelProps={{ shrink: true }} 
                  disabled={true}/>
              </Grid>
            </Grid>
            <hr className="mg_top_10"/>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} xs={12} sm={4}>
                    <h4 className='titulo'>Presenteados:</h4>
                  </Grid>
                  {(!voucher.cancelamento_voucher && permissoes.alterar && voucher.saldo > 0) && 
                    <React.Fragment>
                      <Grid item md={3} xs={12} sm={3}>
                        <Autocomplete
                          onChange={(event, value) => updateFieldCliente(event, value)}
                          freeSolo
                          options={clientes.list}
                          value={cliente}
                          id='cliente'
                          getOptionLabel={option => `${option.nome}`}
                          renderInput={(params) => (
                            <TextField 
                              InputProps={{ ...params.InputProps, type: 'search' }} 
                              {...params} 
                              size="small" 
                              label="Novo Dependente*" 
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }} 
                              value={`${cliente.nome}`}
                              onChange={(e) => updateFiltroPessoa(e)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <Button 
                          size="small"
                          fullWidth
                          variant="contained" 
                          color="info" 
                          onClick={e => addDependente()}
                          startIcon={<AddIcon/>}
                        >
                          Adicionar
                        </Button>
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <Button 
                          size="small"
                          fullWidth
                          variant="contained" 
                          color="secondary" 
                          onClick={e => openCadastroClienteVoucher()}
                        >
                          Cadastrar Cliente
                        </Button>
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={6} xs={12} sm={6}>
                    <Table 
                      headCell={cabecalhoClientes} 
                      rows={voucher.clientes.filter(param => param.cliente.pessoa.tipo === 'FISICA').map((value, key) => {
                        return {
                          nome: value.cliente.pessoa.fisica.nome + (value.cliente.pessoa.fisica.sobrenome ? ` ${value.cliente.pessoa.fisica.sobrenome}` : ''),
                          index: key
                        }
                      })} 
                      acoes={acoesTabelaClientes} 
                      remove={e => removerDependente(e)}
                      qtdLinhas={voucher.clientes.length}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row">
              {voucher.padrao.habilita === 'PRODUTO' &&
                <Grid item md={12} xs={12} sm={12}>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} xs={12} sm={4}>
                      <h4 className='titulo'>Uso dos Produtos:</h4>
                    </Grid>
                    {(!voucher.cancelamento_voucher && permissoes.alterar) &&
                      <React.Fragment>
                        <Grid item md={3} xs={12} sm={3}>
                          <TextField
                            id="produto_id"
                            select
                            label="Serviço*"
                            variant="outlined"
                            className="input"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="id"
                            value={produto.id}
                            onChangeCapture={(e) => updateFieldProduto(e)}
                            InputLabelProps={{ shrink: true }}
                          >
                            <option value=""> Selecionar Serviço</option>
                            {produtos.list.map(produto => {
                              return (
                                <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                              )
                            })}
                          </TextField>
                        </Grid>
                        <Grid item md={2} xs={12} sm={6}>
                          <TextField 
                            id='ambiente'
                            className="input" 
                            label="Valor Serviço" 
                            variant="outlined" 
                            size="small" 
                            name="valor" 
                            value={produto.valor} 
                            onChange={(e) => updateFieldProduto(e)} 
                            onBlur={(e) => verificaValorProduto(e)}
                            InputLabelProps={{ shrink: true }}
                            disabled={produto.id === ''}
                          />
                        </Grid>
                        <Grid item md={2} xs={12} sm={4}>
                          <Button 
                            size="small"
                            fullWidth
                            variant="contained" 
                            color="info" 
                            onClick={e => addServico()}
                            startIcon={<AddIcon/>}
                          >
                            Adicionar Serviço
                          </Button>
                        </Grid>

                      </React.Fragment>
                    }
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12}>
                      <Table 
                        headCell={cabecalhoProdutos} 
                        remove={e => removeServico(e)}
                        rows={voucher.produtos.sort((a, b) => (a.produto.descricao > b.produto.descricao) ? 1 : ((b.produto.descricao > a.produto.descricao) ? -1 : 0)).map((value, key) => {
                          let utilizado_em = ''
                          let cliente = ''
                          let status = ''
                          let data_hora_inicio = ''
        
                          
                          if(value.utilizado && itens_pedido.length > 0){
                            let index = itens_pedido.findIndex(param => (param.produto_id === value.produto_id))
                            
                            if(index !== -1){
                              let index_array = array.findIndex(param => param === `${itens_pedido[index].agenda_id}-${value.id}` )
                            
                              if(index_array === -1){

                                utilizado_em = `${moment(itens_pedido[index].data_hora_inicio).format('DD/MM/YYYY')} - ${itens_pedido[index].situacao}`
                                cliente = itens_pedido[index].pessoa_tipo === 'FISICA' ? itens_pedido[index].nome : itens_pedido[index].nomefantasia
                                status = itens_pedido[index].situacao
                                data_hora_inicio = itens_pedido[index].data_hora_inicio
                                array.push(`${itens_pedido[index].agenda_id}-${value.id}`)

                                itens_pedido.splice(index, 1)

                              }
                            }
                          }
                          
                          if(value.utilizado && utilizado_em === ''){
                            utilizado_em = 'Utilizado como crédito'
                          }

                          return {
                            descricao: value.produto.descricao,
                            valor: 'R$ ' + real(value.valor),
                            valor_utilizado: 'R$ ' + real(value.valor),
                            utilizado: value.utilizado,
                            status,
                            utilizado_em,
                            data_hora_inicio,
                            cliente,
                            index: key,
                            tela: 'AGENDA'
                          }
                        })} 
                        acoes={acoesTabelaProduto}
                        viewAgenda={(e) => viewAgenda(e)}
                        qtdLinhas={state.voucher.produtos.length}
                      />

                    </Grid>
                  </Grid>
                </Grid>
              }
              <Grid item md={12} xs={12} sm={12}>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className='titulo'>Uso do Crédito:</h4>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    headCell={cabecalhoProdutosCredito} 
                    rows={state.itens_voucher_credito} 
                    acoes={acoesTabelaProduto} 
                    viewOS={(e) => viewOS(e)}
                    qtdLinhas={state.itens_voucher_credito.length}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Observação"
                  className="input"
                  multiline
                  rows={5}
                  size="small"
                  variant="outlined"
                  name="observacao"
                  value={voucher.observacao}
                  onChange={(e) => updateFieldVoucher(e)}
                  InputLabelProps={{ shrink: true }} 
                />
              </Grid>
            </Grid>
            {voucher.conta &&
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Lançamentos Financeiro: (Valor Total: R$ {real(somarValores(voucher.conta.parcelas.filter(param => param.situacao === 'BAIXADO').map(value => {return value.valorparcela})))})</h4>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (lancamentos.length) {
                      return (
                        <Table 
                          headCell={state.headCellLancamentos} 
                          rows={lancamentos} 
                          acoes={[]} 
                          ordem='desc' 
                          heightRows={15} />
                      )
                    } else {
                      return (<p>Não há lançamentos no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>
            }
            {!voucher.conta &&
              <React.Fragment>
                {(voucher.valor_credito !== '' && configuracao_unidade.perm_voucher_sem_fin) &&
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={6}>
                      <FormControl style={{marginLeft: '20px'}} component="fieldset">
                        <FormLabel component="legend">Informar Lançamento Financeiro?</FormLabel>
                        <FormControlLabel className="mg_left_10"
                          control={
                            <Switch
                              name="informar_financeiro"
                              color="primary"
                              checked={informar_financeiro}
                            />
                          }
                          size="small"
                          label={informar_financeiro ? 'Sim' : 'Não'}
                          name="informar_financeiro"
                          onChange={(e) => updateFieldInformarFinanceiro(e)}
                        />
                      </FormControl>
                      </Grid>
                  </Grid>
                }
                {(configuracao_unidade.perm_voucher_sem_fin && informar_financeiro) && 
                  <React.Fragment>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={4} xs={12} sm={8}>
                        <h4 className="titulo">Forma de Pagamento:</h4>
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField 
                          id='valor_credito'
                          className="input" 
                          label="Valor da Conta*" 
                          variant="outlined" 
                          size="small" 
                          name="valor_credito" 
                          value={real(voucher.valor_credito)} 
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: CurrencyInput }}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <div className='scroll'>
                          <table className="lista sempadding borderTable">
                            <thead>
                              <tr className="titulos acompanha">
                                <th>Formas de Pagto.</th>
                                <th>Valor*</th>
                                <th>Desconto</th>
                                <th>Data Pagto.*</th>
                                <th>Forma de Pagamento*</th>
                                <th>Contas Bancárias / Nº Vouchers</th>
                                <th>Documento</th>
                              </tr>
                            </thead>
                            <tbody>
                              {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                                return (
                                  <tr key={parcela.index}>
                                    <td>
                                      <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.num} InputLabelProps={{ shrink: true }} disabled />
                                      <span className="separacaoParcelas">/</span>
                                      <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) =>mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                                    </td>
                                    <td>
                                      <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                                    </td>
                                    <td>
                                      <TextField className="inputValor" variant="outlined" size="small" name="multajuros" value={parcela.multajuros} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                                    </td>
                                    <td>
                                      <TextField 
                                        type="date" 
                                        className="inputVencimento" 
                                        variant="outlined" 
                                        size="small" 
                                        name="datavencimento" 
                                        value={parcela.datavencimento}  
                                        onChange={(e) => updateFieldParcela(e, parcela.index)} 
                                        onBlur={(e) => verificaDataParcela(e, parcela.index)}
                                        InputLabelProps={{ shrink: true }} 
                                        // InputProps={{ inputProps: { max: dataHojeFormatado } }}
                                      />
                                    </td>
                                    <td>
                                      <TextField
                                        id="standard-select-currency"
                                        select
                                        variant="outlined"
                                        className="inputFormPagamento"
                                        fullWidth
                                        size="small"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        name="formapagto_id"
                                        value={parcela.formapagto_id}
                                        onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                        onBlur={(e) => marcaParcelas(e, parcela.index)}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        <option value="">--Escolha--</option>
                                        {formasPagamento.list.map(forma => {
                                          return (
                                            <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                          )
                                        })}
                                      </TextField>
                                    </td>
                                    <td>
                                      {parseInt(parcela.formapagto_id) === 9 &&
                                        <div 
                                          style={{
                                            cursor: 'pointer',
                                            width: '300px',
                                            border: '1px solid',
                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                            height: '38px',
                                            borderRadius: '5px',
                                            padding: '10px 10px',
                                            marginLeft: '37px'
                                          }} 
                                          onClick={() => openVouchers(parcela.index)}
                                        >
                                          <span>{parcela.vouchersCreditoSelecionadosString}</span>
                                        </div>
                                      }
                                      {parseInt(parcela.formapagto_id) !== 9 &&
                                        <TextField
                                          id="standard-select-currency"
                                          select
                                          variant="outlined"
                                          className="inputFormPagamento"
                                          fullWidth
                                          size="small"
                                          SelectProps={{
                                            native: true,
                                          }}
                                          name="contfin_id"
                                          value={parcela.contfin_id}
                                          onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                          onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                          InputLabelProps={{ shrink: true }}
                                        >
                                          <option value="">--Escolha--</option>
                                          {contasFinanceira.list.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && state.logado === 'caixa') ).map(conta => {
                                            return (
                                              <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                            )
                                          })}
                                        </TextField>
                                      }
                                    </td>
                                    <td>
                                      <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            <Grid container spacing={1} direction="row" className="mg_top_10">
              {(permissoes.deletar && !ultilizado && !voucher.cancelamento_voucher) &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon/>} onClick={e => voltar(e)}>Voltar</Button>
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button fullWidth color="warning" variant="contained" className="btn_salvar" size="small" onClick={e => cancelarVoucher(e)}>Cancelar Voucher</Button>
                  </Grid>
                  {permissoes_vencimento.inserir &&
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => alterarVencimentos(e)}>Alterar Vencimentos</Button>
                    </Grid>
                  }
                  <Grid item md={4} xs={12} sm={4}></Grid>
                  {!voucher.cancelamento_voucher && 
                    <Grid item md={2} xs={12} sm={4}>
                      <Button color='success' fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => salvarVoucher(e)} startIcon={<SaveIcon/>}>Salvar Voucher</Button>
                    </Grid>
                  }
                </React.Fragment>
              }
              {!permissoes.deletar &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon/>} onClick={e => voltar(e)}>Voltar</Button>
                  </Grid>
                  <Grid item md={8} xs={12} sm={4}></Grid>
                  {!voucher.cancelamento_voucher && 
                    <Grid item md={2} xs={12} sm={4}>
                      <Button color='success' fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => salvarVoucher(e)} startIcon={<SaveIcon/>}>Salvar Voucher</Button>
                    </Grid>
                  }
                </React.Fragment>
              }
              {(permissoes.deletar && (ultilizado || voucher.cancelamento_voucher)) &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => voltar(e)}>Voltar</Button>
                  </Grid>
                  <Grid item md={8} xs={12} sm={4}></Grid>
                  {!voucher.cancelamento_voucher && 
                    <Grid item md={2} xs={12} sm={4}>
                      <Button color='success' fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => salvarVoucher(e)} startIcon={<SaveIcon/>}>Salvar Voucher</Button>
                    </Grid>
                  }
                </React.Fragment>
              }
            </Grid>
          </div>
        }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />

      <ModalConfirmacao 
        open={state.modalConfirmarOpen} 
        handleClose={e => handleCloseCancelamento(e)} 
        dados={state.modal} 
        confirmar={e => verificaMovimentacao(e)}
      />

      <ModalFinanceiroCancelamentoVoucher 
        open={state.modalFinanceiroCancelamentoVoucherOpen} 
        handleClose={e => handleCloseFinanceiroCancelamentoVoucher(e)} 
        itemSelecionado={itemSelecionado}
        itens_receita_despesa={itens_receita_despesa.list}
        updateFieldItem={(e, value) => updateFieldItem(e, value)}
        dados={dadosFinanceiro}
        updateField={e => updateFieldFinanceiro(e)}
        formasPagamento={formasPagamento.list}
        contasFinanceira={contasFinanceira.list}
        logado={logado}
        confirmar={e => confirmarCancelamento(e)}
      />
      
      <ModalNovaParcela
        open={state.openModalNovaParcela} 
        handleClose={e => handleCloseNovaParcela(e)} 
        conta={state.conta}
        formasPagamento={formasPagamento.list}
        contasFinanceira={contasFinanceira.list}
        updateFieldConta={(e) => updateFieldConta(e)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        marcaParcelas={(e, index) => marcaParcelas(e, index)}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        mudarParcelas={e => mudarParcelas(e)}
        verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
        confirmar={e => confirmarSalvarVoucher(e)}
        logado={logado}
        vouchersCreditoSelecionadosString={state.vouchersCreditoSelecionadosString}
        openVouchers={() => openVouchers()}
      />

      <SelecionarVoucherCredito
        open={state.openModalSelecionarVoucherCredito} 
        handleClose={e => finalizarSelecaoVoucher(e)} 
        vouchersCliente={state.vouchersCliente}
        selecionarVoucherCredito={e => selecionarVoucherCredito(e)}
        voltar={e => finalizarSelecaoVoucher(e)}
        vouchersCreditoSelecionados={state.vouchersCreditoSelecionados}
        dados={state.dadosTelaVoucherCredito}
      />

      <VisualizarAgendaOS 
        open={state.openModalVisualizarAgendaOS} 
        handleClose={e => handleCloseVisualizarAgendaOS(e)} 
        dados={state.pedidoServico}
        viewAgenda={e => viewAgenda(e)}
      />

      <ModalAlterarVencimentosVoucher 
        open={state.openModalAlterarVencimentosVoucher} 
        handleClose={e => handleCloseModalAlterarVencimentosVoucher(e)}
        dados={state.dadosAlteracaoVencimento}
        updateField={(e) => updateFieldNovaValidade(e)}
        updateFieldLancFinanceiro={(e) => updateFieldLancFinanceiro(e)}
        conta={state.conta}
        formasPagamento={formasPagamento.list}
        contasFinanceira={contasFinanceira.list}
        itens={state.itens_receita.list}
        logado={logado}
        updateFieldConta={(e) => updateFieldConta(e)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        marcaParcelas={(e, index) => marcaParcelas(e, index)}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        mudarParcelas={e => mudarParcelas(e)}
        verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
        confirmar={(e) => confirmarNovasDatas(e)}
        vouchersCreditoSelecionadosString={state.vouchersCreditoSelecionadosString}
        openVouchers={() => openVouchers()}
      />
    </div>
  )
}

export default ViewVoucher