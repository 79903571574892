import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import calculaIdade from './../../services/calculaIdade'
import './Cliente.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField, FormControlLabel, FormControl, FormLabel, Switch } from '@mui/material'
import FormContratoCliente from '../../components/forms/FormContratoCliente'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalConfirmacaoInativa from './../../components/modals/Confirmacao'
import Loading from './../../components/templates/Loading'
import { format } from 'cnpj'
import real from './../../services/real'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Link } from 'react-router-dom'
import validacaoEmail from './../../services/validacaoEmail'
import FormPessoa from '../../components/forms/FormPessoa'
import ModalAjuda from './../../components/modals/Ajuda'
import validaCnpj from './../../services/validaCnpj'
import { useParams, useNavigate } from 'react-router-dom';
import CPForCNPJInput from './../../components/mask/CPForCNPJInput';
import getToken from './../../services/getToken'
import initialState from './initialState/cadastro';
import somarValores from '../../services/somarValores';
import formatarTelefone from './../../services/formatarTelefone'
import formatarCEP from './../../services/formatarCEP'
import {filesize} from "filesize";

const cpf = require('node-cpf-cnpj');

function contarValores(arr) {
  // Objeto para armazenar as contagens de cada valor
  const contagem = {};

  // Itera pelo array e conta as ocorrências de cada valor
  arr.forEach(valor => {
      if (contagem[valor]) {
          contagem[valor]++;
      } else {
          contagem[valor] = 1;
      }
  });

  // Transforma o objeto de contagem em um array de objetos
  const resultado = Object.keys(contagem).map(chave => {
      return { valor: chave, quantidade: contagem[chave] };
  });

  return resultado;
}

const CadastroCliente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { clienteId } = useParams();

  const getDadosCliente = async () => {

    let clienteAux = {
      id: "",
      pessoa_id: "",
      tipoPessoa: "FISICA",
      codigo:'',
      flags: [],
      ativo: true,
      pessoaFisica: {
        nome: "",
        sobrenome:"",
        cpf: "",
        rg: "",
        rg_orgao_emissor: "",
        rg_uf: "",
        rg_data_emissao: "",
        dataNascimento: "",
        idade: 0,
        dadosComplementar: {
          sexo: '',
          num_calcado: '',
          receber_novidades: '',
          quantidade_filhos: '',
          profissao: '',
          data_casamento: '',
          rg: '',
          rg_orgao_emissor: '',
          rg_uf: '',
          rg_data_emissao: '',
          estado_civil: '',
          colegio: '',
          nome_pai: '',
          nome_mae: '',
          cnh_cliente: '',
          nome_conjugue: '',
          cpf_conjugue: '',
          rg_conjugue: '',
          rg_orgao_emissor_conjugue: '',
          rg_data_emissao_conjugue: '',
          data_nascimento_conjugue: '',
          email_conjugue: '',
          telefone_conjugue: '',
        },
        files: {
          doc_rg_frente: [],
          doc_rg_verso: [],
          doc_cnh_frente: [],
          doc_cnh_verso: [],
          doc_comprovante_renda: [],
          doc_comprovante_endereco: [],
        }
      },
      maiorIdade: true,
      pessoaJuridica: {
        razaoSocial: "",
        nomeFantasia: "",
        cnpj: "",
        inscricaoEstadual: "",
        dataFundacao: "",
        nome_responsavel: ""
      },
      enderecos: [],
      emails: [],
      contatos: [],
      contrato: {
        id: "",
        data_ini_vig: "",
        data_fim_vig: "",
        codigo: "",
        tempo_contrato: "",
        unidade_tempo: "",
        cliente_id: "",
        unidade_id: state.cliente.contrato.unidade_id,
        usuario_id: "",
        situacao: "AGUARDANDO_APROVACAO",
        produtos: [],
        template_versao_id: "",
        assinar_contrato: false,
        cobrar_multa: false,
        agrupar_vencidas: false,
        tipo_multa: '',
        valor_multa: real(0),
        bonus_pont: false,
        porc_bonus_pont: '0'
      }
    }

    let responsavelAux = {
      tipoPessoa: "FISICA",
      pessoaFisica: {
        id:"",
        pessoa_id: "",
        nome: "",
        sobrenome:"",
        cpf: "",
        rg: "",
        rg_orgao_emissor: "",
        rg_uf: "",
        rg_data_emissao: "",
        dataNascimento: "",
        dadosComplementar: {
          sexo: '',
          num_calcado: '',
          receber_novidades: '',
          quantidade_filhos: '',
          profissao: '',
          data_casamento: '',
          rg: '',
          rg_orgao_emissor: '',
          rg_uf: '',
          rg_data_emissao: '',
          estado_civil: '',
          colegio: '',
          nome_pai: '',
          nome_mae: '',
          cnh_cliente: '',
          nome_conjugue: '',
          cpf_conjugue: '',
          rg_conjugue: '',
          rg_orgao_emissor_conjugue: '',
          rg_data_emissao_conjugue: '',
          data_nascimento_conjugue: '',
          email_conjugue: '',
          telefone_conjugue: '',
        },
        files: {
          doc_rg_frente: [],
          doc_rg_verso: [],
          doc_cnh_frente: [],
          doc_cnh_verso: [],
          doc_comprovante_renda: [],
          doc_comprovante_endereco: [],
        }
      },
      pessoaJuridica: {
        id:"",
        pessoa_id: "",
        razaoSocial: "",
        nomeFantasia: "",
        cnpj: "",
        inscricaoEstadual: "",
        dataFundacao: "",
        nome_responsavel: ""
      },
      enderecos: [],
      emails: [],
      contatos: [],
    }

    if (clienteId) {
      let auxPessoa = null
      let auxFisica = null
      let auxJuridica = null
      let auxCliente = null
      let cliente = null

      const { data: pessoa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/${clienteId}`, getToken())
      
      const flags = pessoa.flags.map(value => value.flag)

      auxPessoa = pessoa

      if(pessoa.tipo === 'FISICA'){
        auxFisica = pessoa.fisica
      }else{
        auxJuridica = pessoa.juridica
      }

      if(flags.includes('CLIENTE')){
        auxCliente = pessoa.cliente
      
        cliente = {
          id: auxCliente.id,
          pessoa_id: auxPessoa.id,
          tipoPessoa: auxPessoa.tipo,
          codigo: auxCliente.codigo,
          ativo: auxPessoa.ativo,
          flags
        }
      }else{
        cliente = {
          id: '',
          pessoa_id: auxPessoa.id,
          tipoPessoa:auxPessoa.tipo,
          ativo: auxPessoa.ativo,
          codigo: '',
          flags
        }
      }

      setState(prevState => ({...prevState,
        statusAtivoPessoa: auxPessoa.ativo,
        edit: true
      }))

      try {

        cliente.enderecos = auxPessoa.enderecos.map((end, key) => {
          return {
            id: end.id,
            cep_id: end.cep_id,
            complemento: end.complemento,
            rua: end.logradouro,
            numero: end.numero,
            bairro: end.bairro,
            cidade: end.cidade,
            estado: end.estado,
            cep: end.num_cep,
            principal: end.principal,
            tipoEndereco: end.tipo,
            index: key + 1
          }
        })

        cliente.contatos = auxPessoa.telefones.map((cont, key) => {
          return {
            id: cont.id,
            numero: formatarTelefone(cont.numero),
            tipoContato: cont.tipo,
            principal: cont.principal,
            index: key + 1
          }
        })

        cliente.emails = auxPessoa.emails.map((ema, key) => {
          return {
            id: ema.id,
            email: ema.email,
            principal: ema.principal,
            index: key + 1
          }
        })
        
        if(pessoa.tipo === 'FISICA'){
          
          setState(prevState => ({...prevState,
            enableDadosPessoaFisica: true,
            enableDadosPessoaJuridica: false,
          }))

          const idade = calculaIdade(moment(auxFisica.datanascimento).format('Y-MM-DD'))
          
          let dadosComplementarAux = {
            sexo: '',
            num_calcado: '',
            receber_novidades: 'SIM',
            quantidade_filhos: '',
            profissao: '',
            data_casamento: '',
            rg: '',
            rg_orgao_emissor: '',
            rg_uf: '',
            rg_data_emissao: '',
            estado_civil: '',
            colegio: '',
            nome_pai: '',
            nome_mae: '',
            cnh_cliente: '',
            nome_conjugue: '',
            cpf_conjugue: '',
            rg_conjugue: '',
            rg_orgao_emissor_conjugue: '',
            rg_data_emissao_conjugue: '',
            data_nascimento_conjugue: '',
            email_conjugue: '',
            telefone_conjugue: '',
          }

          let files = {
            doc_rg_frente: [],
            doc_rg_verso: [],
            doc_cnh_frente: [],
            doc_cnh_verso: [],
            doc_comprovante_renda: [],
            doc_comprovante_endereco: [],
          }

          if(auxFisica.dados_complementares){
            dadosComplementarAux = auxFisica.dados_complementares
            dadosComplementarAux.receber_novidades = auxFisica.dados_complementares.receber_novidades ? 'SIM': 'NAO'
            
            for (const key in files) {
                            
              let file = auxFisica.dados_complementares[`${key}_obj`]

              if(file){
                files[key] = [{
                  id: file.id,
                  name: file.nome,
                  preview: `${window._env_.REACT_APP_API_URL}/static/${file.key}`,
                  uploaded: true,
                  url: `${window._env_.REACT_APP_API_URL}/static/${file.key}`
                }]
              }
            }
          }

          cliente.pessoaFisica = {
            id: auxFisica.id,
            nome: auxFisica.nome,
            sobrenome: auxFisica.sobrenome || '',
            cpf: cpf.format(auxFisica.cpf),
            rg: auxFisica.rg || '',
            rg_orgao_emissor: auxFisica.rg_orgao_emissor || '',
            rg_uf: auxFisica.rg_uf || '',
            rg_data_emissao: auxFisica.rg_data_emissao ? moment(auxFisica.rg_data_emissao).format('YYYY-MM-DD') : '',
            dataNascimento: auxFisica.datanascimento ? moment(auxFisica.datanascimento).format('YYYY-MM-DD') : '',
            idade: idade,
            dadosComplementar: dadosComplementarAux,
            files
          }

          cliente.pessoaJuridica = {
            razaoSocial: "",
            nomeFantasia: "",
            cnpj: "",
            inscricaoEstadual: "",
            dataFundacao: "",
            nome_responsavel: ""
          }

          cliente.maiorIdade = idade < 18 ? false : true

        }else{
          setState(prevState => ({...prevState,
            enableDadosPessoaFisica: false,
            enableDadosPessoaJuridica: true,
          }))

          cliente.pessoaJuridica = {
            id: auxJuridica.id,
            razaoSocial:auxJuridica.razaosocial,
            nomeFantasia:auxJuridica.nomefantasia,
            cnpj:auxJuridica.cnpj,
            inscricaoEstadual:auxJuridica.inscricaoestadual,
            dataFundacao:auxJuridica.datafundacao,
            nome_responsavel: auxJuridica.nome_responsavel
          }

          cliente.pessoaFisica = {
            nome: "",
            sobrenome:"",
            cpf: "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            idade: 0,
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
              colegio: '',
              nome_pai: '',
              nome_mae: '',
              cnh_cliente: '',
              nome_conjugue: '',
              cpf_conjugue: '',
              rg_conjugue: '',
              rg_orgao_emissor_conjugue: '',
              rg_data_emissao_conjugue: '',
              data_nascimento_conjugue: '',
              email_conjugue: '',
              telefone_conjugue: '',
            },
            files: {
              doc_rg_frente: [],
              doc_rg_verso: [],
              doc_cnh_frente: [],
              doc_cnh_verso: [],
              doc_comprovante_renda: [],
              doc_comprovante_endereco: [],
            }
          }
        }

        cliente.contrato = {
          id: "",
          data_ini_vig: "",
          data_fim_vig: "",
          codigo: "",
          tempo_contrato: "",
          unidade_tempo: "",
          cliente_id: "",
          unidade_id: auxPessoa.unidade_pertence.id,
          usuario_id: "",
          situacao: "",
          resp_fin: flags.includes('CLIENTE') ? (auxCliente.contrato ? auxCliente.contrato.resp_fin : initialState.responsavel) : initialState.responsavel,
          produtos: [],
          template_versao_id: ""
        }

        setState(prevState => ({...prevState,
          unidade: {
            id: auxPessoa.unidade_pertence.id,
            descricao: auxPessoa.unidade_pertence.descricao,
            numero: auxPessoa.unidade_pertence.numero,
          }
        }))

        if(auxCliente){
          if(auxCliente.contrato){
            if (auxCliente.contrato.resp_fin.length > 0) {
              cliente.maiorIdade = false
            }else{
              cliente.maiorIdade = true
            }
          }else{
            cliente.maiorIdade = true
          }
        }
        
        clienteAux = cliente
        if(auxCliente){
          if(auxCliente.contrato){
            if (auxCliente.contrato.resp_fin) {
              if (auxCliente.contrato.resp_fin.length > 0) {
                const auxResp = auxCliente.contrato.resp_fin[0]
                
                setState(prevState => ({...prevState,
                  enableBuscaResp: false
                }))
    
                const enderecosResp = auxResp.pessoa.enderecos.map((end, key) => {
                  return {
                    id: end.id,
                    cep_id: end.cep_id,
                    complemento: end.complemento,
                    rua: end.logradouro,
                    numero: end.numero,
                    bairro: end.bairro,
                    cidade: end.cidade,
                    estado: end.estado,
                    cep: end.num_cep,
                    principal: end.principal,
                    tipoEndereco: end.tipo,
                    index: key + 1
                  }
                })
    
                const contatosResp = auxResp.pessoa.telefones.map((cont, key) => {
                  return {
                    id: cont.id,
                    numero: formatarTelefone(cont.numero),
                    tipoContato: cont.tipo,
                    principal: cont.principal,
                    index: key + 1
                  }
                })
    
                const emailsResp = auxResp.pessoa.emails.map((ema, key) => {
                  return {
                    id: ema.id,
                    email: ema.email,
                    principal: ema.principal,
                    index: key + 1
                  }
                })
  
                let responsavel = {}
  
                if(auxResp.pessoa.tipo === 'FISICA'){
                  let dadosComplementarResp = {
                    sexo: '',
                    num_calcado: '',
                    receber_novidades: '',
                    quantidade_filhos: '',
                    profissao: '',
                    data_casamento: '',
                    rg: '',
                    rg_orgao_emissor: '',
                    rg_uf: '',
                    rg_data_emissao: '',
                    como_conheceu_id: '',
                    estado_civil: '',
                    colegio: '',
                    nome_pai: '',
                    nome_mae: '',
                    cnh_cliente: '',
                    nome_conjugue: '',
                    cpf_conjugue: '',
                    rg_conjugue: '',
                    rg_orgao_emissor_conjugue: '',
                    rg_data_emissao_conjugue: '',
                    data_nascimento_conjugue: '',
                    email_conjugue: '',
                    telefone_conjugue: '',
                  }

                  if(auxResp.pessoa.fisica){
                    if(auxResp.pessoa.fisica.dados_complementares){
                      dadosComplementarResp = auxResp.pessoa.fisica.dados_complementares
                    }
                  }

                  responsavel = {
                    _id: auxResp.id,
                    tipoPessoa: 'FISICA',
                    pessoaFisica: {
                      id: auxResp.pessoa.fisica.id,
                      pessoa_id: auxResp.pessoa.fisica.pessoa_id,
                      nome: auxResp.pessoa.fisica.nome,
                      sobrenome: auxResp.pessoa.fisica.sobrenome || '',
                      cpf: cpf ? cpf.format(auxResp.pessoa.fisica.cpf) : '',
                      rg: auxResp.pessoa.fisica.rg || '',
                      rg_orgao_emissor: auxResp.pessoa.fisica.rg_orgao_emissor || '',
                      rg_uf: auxResp.pessoa.fisica.rg_uf || '',
                      rg_data_emissao: auxResp.pessoa.fisica.rg_data_emissao ?
                        moment(auxResp.pessoa.fisica.rg_data_emissao).format('YYYY-MM-DD') :
                        '',
                      dataNascimento: auxResp.pessoa.fisica.datanascimento ? moment(auxResp.pessoa.fisica.datanascimento).format('YYYY-MM-DD') : '',
                      dadosComplementar: dadosComplementarResp
                    },
                    pessoaJuridica: {
                      id:"",
                      pessoa_id: "",
                      razaoSocial: "",
                      nomeFantasia: "",
                      cnpj: "",
                      inscricaoEstadual: "",
                      dataFundacao: "",
                      nome_responsavel: ""
                    },
                    enderecos: enderecosResp,
                    emails: emailsResp,
                    contatos: contatosResp
                  }
                }else{
                  responsavel = {
                    _id: auxResp.id,
                    tipoPessoa: 'JURIDICA',
                    pessoaFisica: {
                      id: '',
                      nome: '',
                      sobrenome: '',
                      cpf: '',
                      rg: '',
                      rg_orgao_emissor: '',
                      rg_uf: '',
                      rg_data_emissao: '',
                      dataNascimento: ''
                    },
                    pessoaJuridica: {
                      id:auxResp.pessoa.juridica.id,
                      pessoa_id: auxResp.pessoa.juridica.pessoa_id,
                      razaoSocial: auxResp.pessoa.juridica.razaosocial,
                      nomeFantasia: auxResp.pessoa.juridica.nomefantasia,
                      cnpj: auxResp.pessoa.juridica.cnpj,
                      inscricaoEstadual: auxResp.pessoa.juridica.inscricaoestadual,
                      dataFundacao:  moment(auxResp.pessoa.juridica.datafundacao).format('YYYY-MM-DD'),
                      nome_responsavel: auxResp.pessoa.juridica.nome_responsavel
                    },
                    enderecos: enderecosResp,
                    emails: emailsResp,
                    contatos: contatosResp
                  }
                }
      
                responsavelAux = responsavel
    
                setState(prevState => ({...prevState,
                  searchResp: {
                    cpf: auxResp.cpf
                  },
                }))
              }
            }
          }
        }


      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }
    }

    setState(prevState => ({...prevState,
      clienteNovo: false
    }))

    return {
      cliente: clienteAux,
      responsavel: responsavelAux
    }
  }

  const getDadoComplementarUnidade = async (unidade_id) =>{
    try {
      const { data: dadosComplementaresPF } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPF/list/${unidade_id}`, getToken())

      setState(prevState => ({...prevState,
        dadosComplementaresPF: {
          list: dadosComplementaresPF
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
      return false
    }
  }

  const getConfiguracaoUnidade = async (unidade_id) => {
    try {
      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade_id}`, getToken())
        
      setState(prevState => ({...prevState,
        possui_emissao_nfse: configuracao ? configuracao.habilitar_emissao : false
      }))
    } catch ({response}) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: response.data.message
        },
      }));
      return
    }
  }

  const updateFieldPessoaFisica = (event) => {
    const cliente = { ...state.cliente }
    cliente.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({...prevState, cliente }))
  }

  const updateFieldDadosComplementarPF = (event) => {
    const cliente = { ...state.cliente }
    cliente.pessoaFisica.dadosComplementar[event.target.name] = event.target.value
    setState(prevState => ({...prevState, cliente }))
  }

  const updateFieldPessoaJuridica = (event) => {
    const cliente = { ...state.cliente }
    cliente.pessoaJuridica[event.target.name] = event.target.value
    setState(prevState => ({...prevState, cliente }))
  }

  const updateFieldPessoaFisicaResponsavel = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({...prevState, responsavel }))
  }

  const updateFieldDadosComplementarPFResponsavel = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel.pessoaFisica.dadosComplementar[event.target.name] = event.target.value
    setState(prevState => ({...prevState, responsavel }))
  }

  const updateFieldPessoaJuridicaResponsavel = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel.pessoaJuridica[event.target.name] = event.target.value
    setState(prevState => ({...prevState, responsavel }))
  }

  const updateFieldEndereco = (event, index) => {
    let { endereco } = state
    endereco[event.target.name] = event.target.value
    setState(prevState => ({...prevState, endereco }))
  }

  const updateFieldContato = (event, index) => {
    const {contato} = state
    contato[event.target.name] = event.target.value
    setState(prevState => ({...prevState, contato }))
  }

  const formataTelefoneResponsavel = (event) => {
    const { contatoResponsavel } = state;

    const telefone = event.target.value.trim().replace(/\D/g, "");
    
    let formatoTelefone;
    if (telefone.length === 11) {
      formatoTelefone = telefone.replace(/^(\d{2})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
    } else if (telefone.length === 10 || telefone.length === 12) {
      formatoTelefone = telefone.replace(/^(\d{3})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
    } else if (telefone.length === 8) {
      formatoTelefone = telefone.replace(/(\d)(\d{4})$/, "$1-$2");
    } else {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Telefone Inválido'
        },
        contatoResponsavel: { ...contatoResponsavel, numero: '55' }
      }));
      return false;
    }
  
    setState(prevState => ({...prevState, contatoResponsavel: { ...contatoResponsavel, numero: formatoTelefone } }));
  }

  const updateFieldEmail = (event) => {
    const {email} = state
    email[event.target.name] = event.target.value
    setState(prevState => ({...prevState, email }))
  }

  const updateFieldEnderecoResponsavel = (event) => {
    const {enderecoResponsavel} = state
    enderecoResponsavel[event.target.name] = event.target.value
    setState(prevState => ({...prevState, enderecoResponsavel }))
  }

  const updateFieldContatoResponsavel = (event) => {
    const {contatoResponsavel} = state

    let name = event.target.name
    let value = event.target.value

    contatoResponsavel[name] = value
    setState(prevState => ({...prevState, contatoResponsavel }))
  }

  const updateFieldEmailResponsavel = (event) => {
    const {emailResponsavel} = state
    emailResponsavel[event.target.name] = event.target.value
    setState(prevState => ({...prevState, emailResponsavel }))
  }

  const updateFieldAtivo = () => {
    let {cliente} = state
    cliente.ativo = !cliente.ativo
    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const updateFieldUnidade = async (event, value) => {
    if (!value) return false
    const cliente = { ...state.cliente }
    cliente.contrato.unidade_id = value.id

    await getDadoComplementarUnidade(parseInt(value.id))
    await getConfiguracaoUnidade(parseInt(value.id))

    setState(prevState => ({...prevState,
      unidade: {
        id: value.id,
        descricao: value.descricao,
        numero: value.numero
      }
    }))
  }

  const verificaUnidadeTempo = (value) => {
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  const updateFieldContrato = async (event) => {
    const { cliente, template_documentos, conta, servico } = state
    const { contrato } = cliente

    const name = event.target.name
    const value = event.target.value

    if(name === 'unidade_id'){
      await consultaMetodosDivugacao(parseInt(value))
      await consultaProdutos(parseInt(value))
      await consultarContasBancarias(parseInt(value))
    }

    contrato[name] = value

    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && contrato.data_ini_vig !== '' && contrato.unidade_tempo !== '' && contrato.tempo_contrato !== ''){
      contrato.data_fim_vig = moment(contrato.data_ini_vig).add(contrato.tempo_contrato, verificaUnidadeTempo(contrato.unidade_tempo)).format('YYYY-MM-DD')   
    }

    if(name === "template_versao_id" && value !== ""){
      let versao_selecionada = template_documentos.list.filter(param => param.versao_id = parseInt(value))[0]
      contrato.assinar_contrato = versao_selecionada.assinar
    }else{
      contrato.assinar_contrato = false
    }

    if(name === 'unidade_tempo'){
      servico.intervalo_parcela = value
      if(name === 'intervalo_parcela'){
        if(conta.parcelas.length >= 1){
          if(conta.parcelas[0].datavencimento){
            verificaDataParcela( {target: {value: conta.parcelas[0].datavencimento}} , 1)
          }
        }
      }
    }

    cliente.contrato = contrato

    setState(prevState => ({...prevState, cliente }))
  }

  const updateFieldAssinar = () => {
    const { cliente } = state
    const { contrato } = cliente
    contrato.assinar_contrato = !contrato.assinar_contrato
    cliente.contrato = contrato
    setState(prevState => ({...prevState, cliente }))
  }

  const consultaProdutos = async (unidade_id) => {
    if(unidade_id){
      try {
        const { data: produtosAux } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/byUnidade/${unidade_id}`, getToken())
        
        const produtos = []
        
        produtosAux.forEach(produto => {
          produtos.push({
            id: produto.id,
            mid_caminho: produto.midia ? produto.midia.mid_caminho : '',
            descricao: produto.descricao,
            unidademedida: produto.unidademedida,
            subgrupdescricao: produto.subgrupoproduto.subgrupdescricao,
            subgrupid: produto.subgrupoproduto.subgrupid,
            grupdescricao: produto.subgrupoproduto.grupoproduto.grupdescricao,
            visibilidade: produto.visibilidade,
            preco: produto.preco,
            ativo: produto.ativo
          })
        })

        setState(prevState => ({...prevState,
          produtos: {
            list: produtos
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }
  }

  const consultaMetodosDivugacao = async (unidade_id) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/metodoDivugacao/${unidade_id}`, getToken())
      
      setState(prevState => ({...prevState,
        metodos_divugacoes:{
          list: data
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldEnderecoPrincipal = (event, index) => {
    let { endereco, cliente } = state
    const indexAux = cliente.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== endereco.index)) {
      endereco.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse cliente já possui endereço principal'
        }
      }))
    }else{
      endereco.principal = !endereco.principal
    }

    setState(prevState => ({...prevState, endereco }))
  }

  const updateFieldContatoPrincipal = (event, index) => {
    let { contato, cliente } = state
    const indexAux = cliente.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contato.index)) {
      contato.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse cliente já possui contato principal'
        }
      }))
    }else{
      contato.principal = !contato.principal
    }

    setState(prevState => ({...prevState, contato }))
  }

  const updateFieldEmailPrincipal = (event, index) => {
    let { email, cliente } = state
    const indexAux = cliente.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== email.index)) {
      email.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse cliente já possui email principal'
        }
      }))
    }else{
      email.principal = !email.principal
    }

    setState(prevState => ({...prevState, email }))
  }

  const updateFieldEnderecoPrincipalResponsavel = (event, index) => {
    let { enderecoResponsavel, responsavel } = state
    const indexAux = responsavel.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== enderecoResponsavel.index)) {
      enderecoResponsavel.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse responsável já possui endereço principal'
        }
      }))
    }else{
      enderecoResponsavel.principal = !enderecoResponsavel.principal
    }

    setState(prevState => ({...prevState, enderecoResponsavel }))
  }

  const updateFieldContatoPrincipalResponsavel = (event, index) => {
    let { contatoResponsavel, responsavel } = state
    const indexAux = responsavel.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contatoResponsavel.index)) {
      contatoResponsavel.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse responsável já possui contato principal'
        }
      }))
    }else{
      contatoResponsavel.principal = !contatoResponsavel.principal
    }

    setState(prevState => ({...prevState, contatoResponsavel }))
  }

  const updateFieldEmailPrincipalResponsavel = (event, index) => {
    let { emailResponsavel, responsavel } = state
    const indexAux = responsavel.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== emailResponsavel.index)) {
      emailResponsavel.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse responsável já possui email principal'
        }
      }))
    }else{
      emailResponsavel.principal = !emailResponsavel.principal
    }

    setState(prevState => ({...prevState, emailResponsavel }))
  }

  const updateField = (event) => {
    const cliente = { ...state.cliente }

    let {name, value} = event.target


    if (name === "tipoPessoa") {
      if (value === "FISICA") {
        cliente.pessoaFisica.nome = cliente.pessoaJuridica.razaoSocial
        cliente.pessoaFisica.sobrenome = cliente.pessoaJuridica.nomeFantasia

        cliente.pessoaJuridica.pessoaJuridica = {
          razaoSocial: "",
          nomeFantasia: "",
          cnpj: "",
          inscricaoEstadual: "",
          dataFundacao: "",
          nome_responsavel: ""
        }
        setState(prevState => ({...prevState,
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        }))
      } else {
        cliente.pessoaJuridica.razaoSocial = cliente.pessoaFisica.nome
        cliente.pessoaJuridica.nomeFantasia = cliente.pessoaFisica.sobrenome
        
        cliente.pessoaFisica = {
          nome: "",
          sobrenome:"",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: "",
          idade: 0,
          dadosComplementar: {
            sexo: '',
            num_calcado: '',
            receber_novidades: '',
            quantidade_filhos: '',
            profissao: '',
            data_casamento: '',
            rg: '',
            rg_orgao_emissor: '',
            rg_uf: '',
            rg_data_emissao: '',
            estado_civil: '',
            como_conheceu_id: '',
            colegio: '',
            nome_pai: '',
            nome_mae: '',
            cnh_cliente: '',
            nome_conjugue: '',
            cpf_conjugue: '',
            rg_conjugue: '',
            rg_orgao_emissor_conjugue: '',
            rg_data_emissao_conjugue: '',
            data_nascimento_conjugue: '',
            email_conjugue: '',
            telefone_conjugue: '',
          },
          files: {
            doc_rg_frente: [],
            doc_rg_verso: [],
            doc_cnh_frente: [],
            doc_cnh_verso: [],
            doc_comprovante_renda: [],
            doc_comprovante_endereco: [],
          }
        }

        setState(prevState => ({...prevState,
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        }))
      }
    }

    cliente[name] = value
    setState(prevState => ({...prevState, cliente }))
  }

  const validarCpfCnpj = async (event) => {

    let cpf_cnpj = event.target.value
    const cliente = state.cliente

    cliente.tipoPessoa = cliente.tipoPessoa ? cliente.tipoPessoa : 'FISICA'
    
    if (cliente.tipoPessoa === "FISICA") {
     
      cpf_cnpj = cpf.format(cpf_cnpj)
  
      if (!cpf.isValid(cpf_cnpj)) {
        const idade = calculaIdade(moment(cliente.pessoaFisica.dataNascimento).format('Y-MM-DD'))
        
        cliente.pessoaFisica.idade = idade

        if(idade >= 18){
          if(cpf_cnpj !== '000.000.000-00'){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'CPF Invalido! Por favor corrija o campo CPF'
              }
            }))
          }
        }else{
          if(cpf_cnpj !== ''){
            if(cpf_cnpj !== '000.000.000-00'){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'CPF Invalido! Por favor corrija o campo CPF'
                }
              }))
            }
          }
        }
        
      }

      cliente.pessoaFisica.cpf = cpf.format(cpf_cnpj)
    }else{
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        cliente.pessoaJuridica.cnpj = cpf_cnpj
        setState(prevState => ({...prevState, cliente }))

      } else {
        if(cpf_cnpj !== '00.000.000/0000-00'){
          cliente.pessoaJuridica.cnpj = ""
          setState(prevState => ({...prevState,
            cliente,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'CNPJ Invalido! Por favor corrija o campo CNPJ'
            }
          }))
        }else{
          cliente.pessoaJuridica.cnpj = cpf_cnpj
          setState(prevState => ({...prevState, cliente }))
        }
      }
    }

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const validarCpfCnpjConjugue = async (event) => {

    let cpf_cnpj = event.target.value
    const cliente = state.cliente

    cpf_cnpj = cpf.format(cpf_cnpj)
  
    if (!cpf.isValid(cpf_cnpj)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF Invalido! Por favor corrija o campo CPF'
        }
      }))
      cliente.pessoaFisica.dadosComplementar.cpf_conjugue = ''
    }else{
      cliente.pessoaFisica.dadosComplementar.cpf_conjugue = cpf.format(cpf_cnpj)
    }


    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const validarCpfCnpjResponsavel = async (event) => {

    let cpf_cnpj = event.target.value
    const responsavel = state.responsavel
    cpf_cnpj = cpf.format(cpf_cnpj)

    const dados = {
      id: responsavel.id
    }

    if (!cpf.isValid(cpf_cnpj)) {
      responsavel.pessoaFisica.cpf = ""
      setState(prevState => ({...prevState, responsavel }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF Inválido. Por favor corrija o campo CPF'
        }
      }))
      return
    }if (cpf_cnpj !== '000.000.000-00') {
      try {
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, getToken())

        if (data) {
          responsavel.pessoaFisica.cpf = ""
          setState(prevState => ({...prevState, responsavel }))

          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Já existe um cadastro com este CPF do Responsável'
            }
          }))
          return
        } else {
          responsavel.pessoaFisica.cpf = cpf_cnpj
          setState(prevState => ({...prevState, responsavel }))
        }

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }
    }

     
  }

  const pegaEndereco = async (event) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { endereco } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco.numero,
        complemento: endereco.complemento,
        cep: formatarCEP(endereco.cep),
        principal: endereco.principal,
        tipoEndereco: endereco.tipoEndereco,
        index: endereco.index
      };
  
      setState(prevState => ({...prevState, endereco: novoEndereco }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: endereco.complemento,
          rua: resp.logradouro,
          numero: endereco.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({...prevState,
          endereco: enderecoEncontrado,
          disableEndereco: true,
          modalErro: false
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco.numero,
          cep: "",
          complemento: endereco.complemento,
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({...prevState,
          endereco: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const pegaEnderecoResponsavel = async (event) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { enderecoResponsavel } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecoResponsavel.numero,
        complemento: enderecoResponsavel.complemento,
        cep: formatarCEP(enderecoResponsavel.cep),
        principal: enderecoResponsavel.principal,
        tipoEndereco: enderecoResponsavel.tipoEndereco,
        index: enderecoResponsavel.index
      };
  
      setState(prevState => ({...prevState, enderecoResponsavel: novoEndereco }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: enderecoResponsavel.complemento,
          rua: resp.logradouro,
          numero: enderecoResponsavel.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: enderecoResponsavel.principal,
          tipoEndereco: enderecoResponsavel.tipoEndereco,
          index: enderecoResponsavel.index
        };
  
        setState(prevState => ({...prevState,
          enderecoResponsavel: enderecoEncontrado,
          disableEndereco: true,
          modalErro: false
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecoResponsavel.numero,
          cep: "",
          complemento: enderecoResponsavel.complemento,
          principal: enderecoResponsavel.principal,
          tipoEndereco: enderecoResponsavel.tipoEndereco,
          index: enderecoResponsavel.index
        };
  
        setState(prevState => ({...prevState,
          enderecoResponsavel: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const addNovoEndereco = () => {
    const { endereco, cliente } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        endereco.cep !== "" &&
        endereco.rua !== "" &&
        endereco.bairro !== "" &&
        endereco.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços do cliente
    const novosEnderecos = [...cliente.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (endereco.index === "") {
      endereco.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...endereco });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[endereco.index - 1] = { ...endereco };
    }
  
    // Atualize os endereços do cliente
    cliente.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({...prevState,
      cliente,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEndereco: false,
      alerta: { open: false } // Feche o alerta após adicionar o novo endereço
    }));
  }

  const addNovoContato = () => {
    let {contato, cliente} = state

    let valido = true

    if (contato.numero === "") valido = false
    if (contato.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    const contatos = cliente.contatos

    if(contato.index === ""){
      contato.index = contatos.length + 1

      contatos.push({
        ...contato
      })
    }else{
      contatos[contato.index - 1] = {
        ...contato
      }
    }

    cliente.contatos = contatos

    setState(prevState => ({...prevState, 
      cliente,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContato: false
    }))
  }

  const addNovoEmail = () => {
    let {email, cliente} = state

    let valido = true

    if (email.email === "") valido = false

    if (!valido) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(email.email)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do cliente é inválido!'
        }
      }))
      return
    }

    const emails = cliente.emails

    if(email.index === ""){
      email.index = emails.length + 1

      emails.push({
        ...email
      })
    }else{
      emails[email.index - 1] = {
        ...email
      }
    }

    cliente.emails = emails

    setState(prevState => ({...prevState, 
      cliente,
      email: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmail: false
    }))
  }

  const addNovoEnderecoResponsavel = () => {
    const { enderecoResponsavel, responsavel } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        enderecoResponsavel.cep !== "" &&
        enderecoResponsavel.rua !== "" &&
        enderecoResponsavel.bairro !== "" &&
        enderecoResponsavel.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços do responsavel
    const novosEnderecos = [...responsavel.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (enderecoResponsavel.index === "") {
      enderecoResponsavel.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...enderecoResponsavel });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[enderecoResponsavel.index - 1] = { ...enderecoResponsavel };
    }
  
    // Atualize os endereços do responsavel
    responsavel.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({...prevState,
      responsavel,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEnderecoResponsavel: false,
      alerta: { open: false } // Feche o alerta após adicionar o novo endereço
    }));
  }

  const addNovoContatoResponsavel = () => {
    let {contatoResponsavel, responsavel} = state

    let valido = true

    if (contatoResponsavel.numero === "") valido = false
    if (contatoResponsavel.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    const contatos = responsavel.contatos

    if(contatoResponsavel.index === ""){
      contatoResponsavel.index = contatos.length + 1

      contatos.push({
        ...contatoResponsavel
      })
    }else{
      contatos[contatoResponsavel.index - 1] = {
        ...contatoResponsavel
      }
    }

    responsavel.contatos = contatos

    setState(prevState => ({...prevState, 
      responsavel,
      contatoResponsavel: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContatoResponsavel: false
    }))
  }

  const addNovoEmailResponsavel = () => {
    let {emailResponsavel, responsavel} = state

    let valido = true

    if (emailResponsavel.email === "") valido = false

    if (!valido) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(emailResponsavel.email)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do responsável é inválido!'
        }
      }))
      return
    }

    const emails = responsavel.emails

    if(emailResponsavel.index === ""){
      emailResponsavel.index = emails.length + 1

      emails.push({
        ...emailResponsavel
      })
    }else{
      emails[emailResponsavel.index - 1] = {
        ...emailResponsavel
      }
    }

    responsavel.emails = emails

    setState(prevState => ({...prevState, 
      responsavel,
      emailResponsavel: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmailResponsavel: false
    }))
  }

  const proximo =  async (atual, prox) => {
    const { cliente, responsavel } = state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      const {cliente, dadosComplementaresPF} = state
      
      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

      if(!state.edit){
        if(cliente.flags.length === 0){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:  "Informar ao menos um tipo de cadastro."
            }
          }))
          return
        }
      }

      if (tipo === "MANTENEDOR" || tipo === "GRUPOECONOMICO") {
        if (!cliente.contrato.unidade_id) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:  "Por favor informar a unidade."
            }
          }))
          return
        }
      }

      if (cliente.tipoPessoa === "FISICA") {
        
        if (
          cliente.pessoaFisica.nome === ""
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o nome do cliente.'
            }
          }))
          return
        }

        if (
          cliente.pessoaFisica.dataNascimento === ""
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento do cliente.'
            }
          }))
          return
        }

        const idade = calculaIdade(moment(cliente.pessoaFisica.dataNascimento).format('Y-MM-DD'))
        
        cliente.pessoaFisica.idade = idade

        if (!cliente.contrato.resp_fin) {
          cliente.maiorIdade = idade < 18 ? false : true
        }

        if(cliente.maiorIdade){
          if (
            cliente.pessoaFisica.cpf === "" 
          ) {
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar o CPF do cliente.'
              }
            }))
            return
          }
       
          let cpf_cnpj = cliente.pessoaFisica.cpf
  
          cpf_cnpj = cpf.format(cpf_cnpj)
    
          if (!cpf.isValid(cpf_cnpj)) {
  
            if(cpf_cnpj !== '000.000.000-00'){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'CPF Inválido! Por favor corrija o campo CPF'
                }
              }))
              return
            }
          }
        }else{
          if(cliente.pessoaFisica.cpf !== ''){
            let cpf_cnpj = cliente.pessoaFisica.cpf
  
            cpf_cnpj = cpf.format(cpf_cnpj)
      
            if (!cpf.isValid(cpf_cnpj)) {
    
              if(cpf_cnpj !== '000.000.000-00'){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: 'CPF Inválido! Por favor corrija o campo CPF'
                  }
                }))
                return
              }
            }
          }
        }
        

        const anoNascimentoCliente = moment(cliente.pessoaFisica.dataNascimento).format('YYYY')
  
        if (parseInt(anoNascimentoCliente) > 9999) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Data de Nascimento Inválido!'
            }
          }))
  
          return
        }

        setState(prevState => ({...prevState, cliente }))
  
        if (cliente.maiorIdade) {
          if(cliente.pessoaFisica.cpf){
            // if (cliente.pessoaFisica.cpf === '000.000.000-00') {
            //   setState(prevState => ({...prevState,
            //     alerta: {
            //       open: true,
            //       severity: 'warning',
            //       message: 'CPF Inválido! Por favor informar o campo CPF.'
            //     }
            //   })
            //   return
            // }
          }
        }


        for (const item of dadosComplementaresPF.list) {
          // const dadosComplementar = cliente.pessoaFisica.dadosComplementar
          if(item.obrigatorio){
            if(item.column_name === 'doc_rg_frente'){
              if(cliente.pessoaFisica.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_rg_verso'){
              if(cliente.pessoaFisica.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_cnh_frente'){
              if(cliente.pessoaFisica.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_cnh_verso'){
              if(cliente.pessoaFisica.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_comprovante_renda'){
              if(cliente.pessoaFisica.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_comprovante_endereco'){
              if(cliente.pessoaFisica.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(cliente.pessoaFisica.dadosComplementar[item.column_name] === '' || !cliente.pessoaFisica.dadosComplementar[item.column_name]){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }
        }
      }else{
        
        cliente.maiorIdade = true

        setState(prevState => ({...prevState,
          cliente
        }))

        if (
          cliente.pessoaJuridica.razaoSocial === "" ||
          cliente.pessoaJuridica.nomeFantasia === ""
        ) {
          setState(prevState => ({...prevState,
            cliente,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Existem campo obrigatorios vazios(*).'
            }
          }))
          return
        }
      }
     

      setState(prevState => ({...prevState,
        enableDadosPessoa: false,
        enableDadosEndereco: true
      }))

    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      if(cliente.enderecos.length > 0){
        cliente.enderecos.forEach(endereco => {
          if (endereco.cep === "") valido = false
          if (endereco.rua === "") valido = false
          if (endereco.bairro === "") valido = false
          if (endereco.numero === "") valido = false
        })
      }

      if (!valido) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      if(cliente.enderecos.length === 0 && cliente.id){
        try {
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/verificaCliente/${cliente.pessoa_id}`, getToken())
          
          if(data){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Para esse cliente foram gerados contratos com emissão de nfse, por favor informar o endereço do responsável.'
              }
            }))
            return
          }

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }

       
      }

      setState(prevState => ({...prevState,
        enableDadosEndereco: false,
        enableDadosContato: true
      }))

    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      
      if(cliente.contatos.length > 0){
        cliente.contatos.forEach(contato => {
          if (contato.numero === "") valido = false
        })
      }

      if (!valido) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosContato: false,
        enableDadosEmail: true
      }))
    } else if (atual === "enableDadosEmail" && prox === "enableDadosContrato") {
      let valido = true
      
      if(cliente.emails.length > 0){
        cliente.emails.forEach(email => {
          if (email.email === "") valido = false
        })
      }

      if (!valido) {
         setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      cliente.contrato.data_ini_vig = moment(new Date()).format('YYYY-MM-DD')
      setState(prevState => ({...prevState,
        enableDadosEmail: false,
        enableDadosContrato: true,
        cliente
      }))

    } else if (atual === "enableDadosEmail" && prox === "enableDadosPessoaResponsavel") {
      let valido = true
     
      if(cliente.emails.length > 0){
        cliente.emails.forEach(email => {
          if (email.email === "") valido = false
        })
      }

      if (!valido) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosEmail: false,
        enableDadosPessoaResponsavel: true
      }))

    } else if (atual === "enableDadosContrato" && prox === "enableDadosPessoaResponsavel") {
      if(cliente.flags.includes('CLIENTE')){
        if(cliente.tipoPessoa === "FISICA"){
          // if(cliente.pessoaFisica.dataNascimento){
          //   const idade = calculaIdade(moment(cliente.pessoaFisica.dataNascimento).format('Y-MM-DD'))
    
          //   if (!cliente.contrato.resp_fin) {
          //     cliente.maiorIdade = idade < 18 ? false : true
          //   }
  
          //   setState(prevState => ({...prevState, cliente })
          // }
        }else if (cliente.tipoPessoa === "JURIDICA") {
          cliente.maiorIdade = false
          setState(prevState => ({...prevState,cliente})  ) 
        }

        const anoContrato = moment(cliente.contrato.data_ini_vig).format('YYYY')
  
        if (parseInt(anoContrato) > 9999) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Data de Inicio da Vigência Inválido!'
            },
          }));
          return
        }
  
        if (
          cliente.contrato.data_ini_vig === ""
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Existem campo obrigatorios vazios(*).'
            }
          }))
          return
        }        
      }

      responsavel.enderecos = cliente.enderecos

      setState(prevState => ({...prevState,
        enableDadosContrato: false,
        enableDadosPessoaResponsavel: true,
        responsavel
      }))

    } else if (atual === "enableDadosPessoaResponsavel" && prox === "enableDadosEnderecoResponsavel") {
      if(responsavel.tipoPessoa === 'FISICA'){
        const idade = calculaIdade(moment(responsavel.pessoaFisica.dataNascimento).format('Y-MM-DD'))

        const maiorIdade = idade < 18 ? false : true

        const anoNascimentoResp = moment(responsavel.pessoaFisica.dataNascimento).format('YYYY')

        if (parseInt(anoNascimentoResp) > 9999) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Data de Nascimento do Responsável Inválido!'
            }
          }))
          return
        }

        if (maiorIdade) {
          if (responsavel.pessoaFisica.cpf === '000.000.000-00') {
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'CPF Inválido! Por favor informar o campo CPF.'
              }
            }))
            return
          }

          let cpf_cnpj = responsavel.pessoaFisica.cpf

          cpf_cnpj = cpf.format(cpf_cnpj)
    
          if (!cpf.isValid(cpf_cnpj)) {
            if(cpf_cnpj !== '000.000.000-00'){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'CPF Inválido! Por favor corrija o campo CPF'
                }
              }))
              return
            }
          }
        } else {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O Responsável do cliente precisa ser maior de idade.'
            }
          }))
          return
        }

        if (
          responsavel.pessoaFisica.nome === "" ||
          responsavel.pessoaFisica.cpf === ""
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Existem campo obrigatorios vazios(*).'
            }
          }))
          return
        }

        responsavel.enderecos = cliente.enderecos

      }else{
        if (
          responsavel.pessoaJuridica.razaoSocial === "" ||
          responsavel.pessoaJuridica.nomeFantasia === "" ||
          responsavel.pessoaJuridica.cnpj === "" ||
          responsavel.pessoaJuridica.inscricaoEstadual === "" ||
          responsavel.pessoaJuridica.dataFundacao === ""
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Existem campo obrigatorios vazios(*).'
            }
          }))
          return
        }
      }

      setState(prevState => ({...prevState,
        responsavel,
        enableDadosPessoaResponsavel: false,
        enableDadosEnderecoResponsavel: true
      }))
    } else if (atual === "enableDadosEnderecoResponsavel" && prox === "enableDadosContatoResponsavel") {
      let valido = true
      if(responsavel.enderecos.length > 0){
        responsavel.enderecos.forEach(endereco => {
          if (endereco.cep === "") valido = false
          if (endereco.rua === "") valido = false
          if (endereco.bairro === "") valido = false
          if (endereco.numero === "") valido = false
        })
      }

      if (!valido) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      let servicos_com_nfse = cliente.contrato.produtos.filter(param => param.gerar_nfse)

      if(servicos_com_nfse.length > 0 && responsavel.enderecos.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Para esse cliente foram gerados contratos com emissão de nfse, por favor informar o endereço do responsável.'
          }
        }))
        return
      }

      if(responsavel.enderecos.length === 0 && responsavel._id){
        try {
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/verificaCliente/${responsavel.tipoPessoa === 'FISICA' ? responsavel.pessoaFisica.pessoa_id : responsavel.pessoaJuridica.pessoa_id}`, getToken())
          
          if(data){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Para esse cliente foram gerados contratos com emissão de nfse, por favor informar o endereço do responsável.'
              }
            }))
            return
          }

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }

       
      }

      setState(prevState => ({...prevState,
        enableDadosEnderecoResponsavel: false,
        enableDadosContatoResponsavel: true
      }))

    } else if (atual === "enableDadosContatoResponsavel" && prox === "enableDadosEmailResponsavel") {
      let valido = true

      if(responsavel.contatos.length > 0){
        responsavel.contatos.forEach(contato => {
          if (contato.numero === "") valido = false
        })
      }

      if (!valido) {
         setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosContatoResponsavel: false,
        enableDadosEmailResponsavel: true
      }))
    }
  }

  const voltar = (atual, anterior) => {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      setState(prevState => ({...prevState,
        enableDadosPessoa: true,
        enableDadosEndereco: false
      }))
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      setState(prevState => ({...prevState,
        enableDadosEndereco: true,
        enableDadosContato: false
      }))
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      setState(prevState => ({...prevState,
        enableDadosContato: true,
        enableDadosEmail: false
      }))
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosContrato") {
      setState(prevState => ({...prevState,
        enableDadosEmail: true,
        enableDadosContrato: false
      }))
    } else if (anterior === "enableDadosContrato" && atual === "enableDadosPessoaResponsavel") {
      setState(prevState => ({...prevState,
        enableDadosContrato: true,
        enableDadosPessoaResponsavel: false
      }))
    } else if (anterior === "enableBuscaResp" && atual === "enableDadosPessoaResponsavel") {
      setState(prevState => ({...prevState,
        enableBuscaResp: true
      }))
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosPessoaResponsavel") {
      setState(prevState => ({...prevState,
        enableDadosEmail: true,
        enableDadosPessoaResponsavel: false
      }))
    } else if (anterior === "enableDadosPessoaResponsavel" && atual === "enableDadosEnderecoResponsavel") {
      setState(prevState => ({...prevState,
        enableDadosPessoaResponsavel: true,
        enableDadosEnderecoResponsavel: false
      }))
    } else if (anterior === "enableDadosEnderecoResponsavel" && atual === "enableDadosContatoResponsavel") {
      setState(prevState => ({...prevState,
        enableDadosEnderecoResponsavel: true,
        enableDadosContatoResponsavel: false
      }))
    } else if (anterior === "enableDadosContatoResponsavel" && atual === "enableDadosEmailResponsavel") {
      setState(prevState => ({...prevState,
        enableDadosContatoResponsavel: true,
        enableDadosEmailResponsavel: false
      }))
    }
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      let url = criaUrl()
      navigate(url);
    }, timeout)
  }

  const validateFormEmailResponsavel = () => {
    const { responsavel } = state

    for (const email of responsavel.emails) {
      if (email.email === '') return false
    }

    return true
  }

  const removeEnderecoCliente = (index) => {
    const cliente = { ...state.cliente }
    const enderecos = cliente.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    cliente.enderecos = aux
    setState(prevState => ({...prevState, cliente }))
  }

  const removeContatoCliente = (index) => {
    const cliente = { ...state.cliente }
    const contatos = cliente.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    cliente.contatos = aux
    setState(prevState => ({...prevState, cliente }))
  }

  const removeEmailCliente = (index) => {
    const cliente = { ...state.cliente }
    const emails = cliente.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    cliente.emails = aux
    setState(prevState => ({...prevState, cliente }))
  }

  const removeEnderecoResponsavel = (index) => {
    const responsavel = { ...state.responsavel }
    const enderecos = responsavel.enderecos
    const aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.enderecos = aux
    setState(prevState => ({...prevState, responsavel }))
  }

  const removeContatoResponsavel = (index) => {
    const responsavel = { ...state.responsavel }
    const contatos = responsavel.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.contatos = aux
    setState(prevState => ({...prevState, responsavel }))
  }

  const removeEmailResponsavel = (index) => {
    const responsavel = { ...state.responsavel }
    const emails = responsavel.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.emails = aux
    setState(prevState => ({...prevState, responsavel }))
  }

  const validateFormContrato = () => {
    const { cliente } = state

    if(cliente.flags.includes('CLIENTE')){
      if (
        cliente.contrato.data_ini_vig === ""
      ) {
        return false
      }
    }

    if (
      cliente.contrato.unidade_id === ""
    ) {
      return false
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    if (tipo === "MANTENEDOR" || tipo === "GRUPOECONOMICO") {
      if (!cliente.contrato.unidade_id) return false
    }

    return true
  }

  const handleCloseConfirmacao = () => {
    setState(prevState => ({...prevState,
      modalConfirmacaoOpen: false
    }))
  }

  const imprimirContrato = async () => {
    const { cliente, responsavel } = state

    if (clienteId) {
      try {

        const data = await getDadosCliente()

        const clienteNaoAlterado = data.cliente
        const responsavelNaoAlterado = data.responsavel

        let diferenca = false
        if (JSON.stringify(clienteNaoAlterado) !== JSON.stringify(cliente)) {
          diferenca = true
        }

        if (JSON.stringify(responsavel) !== JSON.stringify(responsavelNaoAlterado)) {
          diferenca = true
        }

        if (diferenca) {
          setState(prevState => ({...prevState,
            modalConfirmacao: {
              mensagem: "Existem alterações no contrato, deseja salvar antes de imprimir?",
            },
            modalConfirmacaoOpen: true
          }))
          return
        }

        const respImprimir = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente/contrato/matricula/${cliente.contrato.id}/imprimir`, {}, getToken())
        const dados = { path: respImprimir.data }
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Contrato-cliente.pdf`);

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }

    }

  }

  const confirmarSalvamento = async (resposta) => {
    setState(prevState => ({...prevState,
      modalConfirmacaoOpen: false
    }))

    if (resposta) {
      try {
        await salvar(false)

        const { cliente} = state

        const respImprimir = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente/contrato/matricula/${cliente.contrato.id}/imprimir`, {}, getToken())
        const dados = { path: respImprimir.data }
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Contrato-cliente.pdf`);

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }


    } else {
      const { cliente } = state

      const respImprimir = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente/contrato/matricula/${cliente.contrato.id}/imprimir`, {}, getToken())
      const dados = { path: respImprimir.data }
      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `Contrato-cliente.pdf`);

    }
  }

  const updateFieldSearchResp = (event) => {
    const { searchResp } = state

    searchResp[event.target.name] = event.target.value.replaceAll('_','')

    setState(prevState => ({...prevState, searchResp }))
  }

  const buscarResp = async () => {
    const { searchResp, cliente } = state

    if(searchResp.cpf.length !== 18 && searchResp.cpf.length !== 14){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF/CNPJ Inválido! Por favor corrija o campo.'
        }
      }))
      return
    }

    let buscaPor
    const search = searchResp.cpf

    if(searchResp.cpf.length === 14){
      buscaPor = 'cpf'

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      let unidade_id = cliente.contrato.unidade_id === "" ? pessoa.fisica.funcionario.contrato.unidadetrabalho.id : cliente.contrato.unidade_id

      if (!cpf.isValid(cpf.format(search))) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Inválido! Por favor corrija o campo CPF.'
          }
        }))
        return
      }

      if(cliente.tipoPessoa === 'FISICA'){
        if (cpf.format(search) === cpf.format(cliente.pessoaFisica.cpf)) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O CPF do Responsável não pode ser igual ao do cliente.'
            }
          }))
          return
        }
      }

      try {

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/search/${cpf.strip(search)}`, { buscaPor, busca: 'responsavel', unidade_id }, getToken())

        let responsavel = {
          tipoPessoa: 'FISICA',
          pessoaFisica: {
            id: data.id,
            pessoa_id: data.pessoa_id,
            nome: data.nome,
            cpf: cpf.format(data.cpf),
            rg: data.rg ? data.rg : '',
            rg_orgao_emissor: data.rg_orgao_emissor ? data.rg_orgao_emissor : '',
            rg_uf: data.rg_uf ? data.rg_uf : '',
            rg_data_emissao: data.rg_data_emissao ? data.rg_data_emissao : '',
            dataNascimento: data.datanascimento,
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
              colegio: '',
              nome_pai: '',
              nome_mae: '',
              cnh_cliente: '',
              nome_conjugue: '',
              cpf_conjugue: '',
              rg_conjugue: '',
              rg_orgao_emissor_conjugue: '',
              rg_data_emissao_conjugue: '',
              data_nascimento_conjugue: '',
              email_conjugue: '',
              telefone_conjugue: '',
            },
            files: {
              doc_rg_frente: [],
              doc_rg_verso: [],
              doc_cnh_frente: [],
              doc_cnh_verso: [],
              doc_comprovante_renda: [],
              doc_comprovante_endereco: [],
            }
          },
          pessoaJuridica: {
            id: "",
            pessoa_id: '',
            razaoSocial: '',
            nomeFantasia: '',
            cnpj: '',
            inscricaoEstadual: '',
            dataFundacao: '',
            nome_responsavel: ""
          },
          enderecos: data.pessoa.enderecos.map((end, key) => {
            return {
              id: end.id,
              cep_id: end.cep_id,
              rua: end.logradouro,
              complemento: end.complemento,
              numero: end.numero,
              bairro: end.bairro,
              cidade: end.cidade,
              estado: end.estado,
              cep: end.num_cep,
              principal: end.principal,
              tipoEndereco: end.tipo,
              index: key + 1
            }
          }),
          emails: data.pessoa.emails.map((ema, key) => {
            return {
              id: ema.id,
              email: ema.email,
              principal: ema.principal,
              index: key + 1
            }
          }),
          contatos: data.pessoa.telefones.map((tel, key) => {
            return {
              id: tel.id,
              numero: tel.numero,
              tipoContato: tel.tipo,
              principal: tel.principal,
              index: key + 1
            }
          }),
        }

        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      } catch ({ response }) {
        let responsavel = {
          tipoPessoa: 'FISICA',
          pessoaFisica: {
            id: "",
            pessoa_id: '',
            nome: "",
            cpf: cpf.format(searchResp.cpf),
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
              colegio: '',
              nome_pai: '',
              nome_mae: '',
              cnh_cliente: '',
              nome_conjugue: '',
              cpf_conjugue: '',
              rg_conjugue: '',
              rg_orgao_emissor_conjugue: '',
              rg_data_emissao_conjugue: '',
              data_nascimento_conjugue: '',
              email_conjugue: '',
              telefone_conjugue: '',
            },
            files: {
              doc_rg_frente: [],
              doc_rg_verso: [],
              doc_cnh_frente: [],
              doc_cnh_verso: [],
              doc_comprovante_renda: [],
              doc_comprovante_endereco: [],
            }
          },
          pessoaJuridica: {
            id: "",
            pessoa_id: '',
            razaoSocial: '',
            nomeFantasia: '',
            cnpj: '',
            inscricaoEstadual: '',
            dataFundacao: '',
            nome_responsavel: ""
          },
          enderecos: state.responsavel.enderecos,
          emails: [],
          contatos: [],
        }
        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      }

    }else{
      if(cliente.tipoPessoa === 'JURIDICA'){
        if (search === format(cliente.pessoaJuridica.cnpj)) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O CNPJ do Responsável não pode ser igual ao do cliente.'
            }
          }))
          return
        }
      }

      try {
        buscaPor = 'cnpj'
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/search/${search.replaceAll('.','').replaceAll('/','').replaceAll('-','')}`, { buscaPor, busca: 'responsavel' }, getToken())

        let responsavel = {
          tipoPessoa: 'JURIDICA',
          pessoaFisica: {
            id: "",
            pessoa_id: '',
            nome: "",
            cpf:  "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
              colegio: '',
              nome_pai: '',
              nome_mae: '',
              cnh_cliente: '',
              nome_conjugue: '',
              cpf_conjugue: '',
              rg_conjugue: '',
              rg_orgao_emissor_conjugue: '',
              rg_data_emissao_conjugue: '',
              data_nascimento_conjugue: '',
              email_conjugue: '',
              telefone_conjugue: '',
            },
            files: {
              doc_rg_frente: [],
              doc_rg_verso: [],
              doc_cnh_frente: [],
              doc_cnh_verso: [],
              doc_comprovante_renda: [],
              doc_comprovante_endereco: [],
            }
          },
          pessoaJuridica: {
            id: data.id,
            pessoa_id: data.pessoa_id,
            razaoSocial: data.razaosocial,
            nomeFantasia: data.nomefantasia,
            cnpj: search,
            inscricaoEstadual: data.inscricaoestadual,
            dataFundacao: data.datafundacao,
            nome_responsavel: data.nome_responsavel
          },
          enderecos: data.pessoa.enderecos.map((end, key) => {
            return {
              id: end.id,
              cep_id: end.cep_id,
              rua: end.logradouro,
              complemento: end.complemento,
              numero: end.numero,
              bairro: end.bairro,
              cidade: end.cidade,
              estado: end.estado,
              cep: end.num_cep,
              principal: end.principal,
              tipoEndereco: end.tipo,
              index: key + 1
            }
          }),
          emails: data.pessoa.emails.map((ema, key) => {
            return {
              id: ema.id,
              email: ema.email,
              principal: ema.principal,
              index: key + 1
            }
          }),
          contatos: data.pessoa.telefones.map((tel, key) => {
            return {
              id: tel.id,
              numero: tel.numero,
              tipoContato: tel.tipo,
              principal: tel.principal,
              index: key + 1
            }
          }),
        }
        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      } catch ({ response }) {
        let responsavel = {
          tipoPessoa: 'JURIDICA',
          pessoaFisica: {
            id: "",
            pessoa_id: '',
            nome: "",
            cpf:  "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
              colegio: '',
              nome_pai: '',
              nome_mae: '',
              cnh_cliente: '',
              nome_conjugue: '',
              cpf_conjugue: '',
              rg_conjugue: '',
              rg_orgao_emissor_conjugue: '',
              rg_data_emissao_conjugue: '',
              data_nascimento_conjugue: '',
              email_conjugue: '',
              telefone_conjugue: '',
            },
            files: {
              doc_rg_frente: [],
              doc_rg_verso: [],
              doc_cnh_frente: [],
              doc_cnh_verso: [],
              doc_comprovante_renda: [],
              doc_comprovante_endereco: [],
            }
          },
          pessoaJuridica: {
            id: "",
            pessoa_id: '',
            razaoSocial: '',
            nomeFantasia: '',
            cnpj: search,
            inscricaoEstadual: '',
            dataFundacao: '',
            nome_responsavel: ""
          },
          enderecos: state.responsavel.enderecos,
          emails: [],
          contatos: [],
        }

        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      }

    }
    
  }

  const updateFieldMaiorMenor = () => {
    const { cliente } = state

    if(cliente.pessoaFisica.idade >= 18){

      cliente.maiorIdade = !cliente.maiorIdade
  
      setState(prevState => ({...prevState,
        cliente
      }))
    }else{
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Cliente menor de idade. Por favor informar o responsavel financeiro.'
        }
      }))
      return
    }

  }

  const verifyFinishedDate = (event) => {
    const dataHoje = new Date(moment().format('YYYY-MM-DD'))
    const dataInformada = new Date(event.target.value)

    if (dataInformada < dataHoje) {
      const { cliente } = state
      cliente.contrato[event.target.name] = ''
      setState(prevState => ({...prevState,
        cliente
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:  'A data informada é menor que a data atual. Favor informar uma data válida'
        },
      }));
      return
    }

  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const addFlag = (flag) => {
    const { cliente } = state
    let flags = cliente.flags

    if(!cliente.flags.includes(flag)){
      flags.push(flag)
    }else{
      flags.splice(flags.indexOf(flag), 1);
    }

    cliente.flags = flags
    setState(prevState => ({...prevState,cliente}))
  }

  const consultarContasBancarias = async (unidade_id) => {
    try {
      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${parseInt(unidade_id)}`, getToken())
  
      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const updateServico = async (event) => {
    const {servico, formasPagamento, cliente, produtos, conta} = state

    const name = event.target.name
    const value = event.target.value

    servico[name] = value

    if(name === 'formapagto_id'){
      try {

        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${parseInt(cliente.contrato.unidade_id)}`, getToken())
  
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira.filter(param => param.tipo_conta === formaPagtoSelecionada.habilita)
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }

    if(name === 'produto_id'){
      const produtoSelecionado = produtos.list.filter(param => param.id === parseInt(value))[0]

      setState(prevState => ({...prevState,
        produtoSelecionado
      }))
    }

    if(name === 'valor'){
      if(servico.parcelado){
        let valorTotal = parseFloat(value.replaceAll(".", "").replaceAll(",", "."))
  
        let valorDividido = valorTotal / conta.qtd_parcelas
        valorDividido = parseFloat(valorDividido.toFixed(2))
  
        let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)
  
        sobra = parseFloat(sobra.toFixed(2))
  
        for (let i = 0; i < conta.qtd_parcelas; i++) {
          conta.parcelas[i].valorparcela = real(valorDividido)
        }
  
        if (sobra !== 0) {
          conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
        }
  
        conta.valortotal = value

        setState(prevState => ({
          ...prevState,
          conta
        }))
      }
    }

    if(name === 'intervalo_parcela'){
      if(conta.parcelas.length >= 1){
        if(conta.parcelas[0].datavencimento){
          verificaDataParcela( {target: {value: conta.parcelas[0].datavencimento}} , 1)
        }
      }
    }

    if(name === 'cobrar_domingos'){
      if(conta.parcelas.length >= 1){
        if(conta.parcelas[0].datavencimento){
          verificaDataParcela( {target: {value: conta.parcelas[0].datavencimento}} , 1)
        }
      }
    }

    setState(prevState => ({...prevState,servico}))
  }

  const verificaDataParcela = (event, index) => {
    const {conta, servico} = state

    conta.parcelas[index - 1].datavencimento_orig = event.target.value
    
    let novaData = moment(event.target.value)

    for (let i = index; i < conta.parcelas.length; i++) {
      novaData = moment(novaData).add(1, verificaUnidadeTempo(servico.intervalo_parcela))

      if(servico.cobrar_domingos === 'NAO' && moment(novaData).format('dddd') === 'domingo'){
        novaData = moment(novaData).add(1, 'day')
      }

      conta.parcelas[i].datavencimento = moment(novaData).format('YYYY-MM-DD')
      conta.parcelas[i].datavencimento_orig = novaData    
    }

    setState(prevState => ({...prevState, conta }))
  }

  const validateServico = () => {
    
    const {servico} = state

    if(!servico.produto_id) return false
    if(!servico.valor) return false
    
    if(!servico.parcelado && !servico.recorrencia) return false
      
    if(servico.recorrencia){
      if(!servico.formapagto_id) return false
      if(!servico.contfin_id) return false
      if(!servico.venc_prim_parcela) return false
      if(!servico.qtdRecorrencia) return false
      if(!servico.recorrenciaACada) return false
      if(!servico.tipoRecorrencia) return false
    }

    return true
  }

  const addServico = () => {
    if (!validateServico()) {
      setState(prevState => ({...prevState, loading: false }))
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios do serviço (*)."
        }
      }))
      return
    }

    const {servico, cliente, produtos, formasPagamento, contasFinanceira, conta} = state

    let parcelas = []

    if(servico.parcelado){
      for (const parcela of conta.parcelas) {

        if(parcela.valorparcela === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar o valor da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.datavencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a data de vencimento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.formapagto_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a forma de pagamento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.contfin_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a conta financeira da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }
      }

      parcelas = [...conta.parcelas]
    }

    let descricao_parcelamento = ''

    if(servico.parcelado){
      let contagem = contarValores(parcelas.map(value => {return value.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")})).sort((a, b) => (a.quantidade > b.quantidade) ? 1 : ((b.quantidade > a.quantidade) ? -1 : 0))
    
      for (let i = 0; i < contagem.length; i++) {
        const element = contagem[i];

        if((i + 1) === contagem.length){
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}`
        }else{
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}, `
        }
      }
    }

    const produto_selecionado = produtos.list.filter(param => param.id === parseInt(servico.produto_id))[0]
    const forma_selecionada = formasPagamento.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.formapagto_id : parcelas[0].formapagto_id))[0]
    const conta_fin_selecionada = contasFinanceira.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.contfin_id : parcelas[0].contfin_id))[0]
    
    if(forma_selecionada.slug === 'boleto' &&
      conta_fin_selecionada.conta_corrente.parceiro){
        
      if(moment(servico.venc_prim_parcela).isBefore(moment(new Date()))){
        setState(prevState => ({...prevState, loading: false }))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: "A data de vencimento do boleto não pode ser inferior ao dia de amanhã."
          }
        }))
        return
      }

      if(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) < 3){
        setState(prevState => ({...prevState, loading: false }))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: "O Valor do boleto não pode ser inferior a 3 Reias!"
          }
        }))
        return
      }
    }

    if(forma_selecionada.slug === 'pix' &&
      conta_fin_selecionada.conta_corrente.parceiro){
        
      if(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) < 3){
        setState(prevState => ({...prevState, loading: false }))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: "O Valor do pix não pode ser inferior a 3 Reias!"
          }
        }))
        return
      }
    }   

    if(servico.gerar_nfse){
      if(cliente.maiorIdade && cliente.enderecos.length === 0){
        setState(prevState => ({...prevState, loading: false }))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: "Para gerar a nfse é preciso informar o endereço do cliente."
          }
        }))
        return
      }
    }
   
    let obj = {
      produto_id: parseInt(servico.produto_id),
      descricao: produto_selecionado.descricao,
      formapagto_id: servico.recorrencia ? parseInt(servico.formapagto_id) : '',
      forma_pagto: forma_selecionada.descricao,
      valor: servico.valor,
      num_parcelas: servico.recorrencia ? parseInt(servico.num_parcelas) : parcelas.length,
      contfin_id: servico.recorrencia ? parseInt(servico.contfin_id) : '',
      conta_fin: conta_fin_selecionada.descricao,
      venc_prim_parcela: servico.recorrencia ? servico.venc_prim_parcela : parcelas[0].datavencimento,
      recorrencia: servico.recorrencia,
      gerar_nfse: servico.gerar_nfse,
      parcelado: servico.parcelado,
      recorrenciaACada:servico.recorrenciaACada,
      tipoRecorrencia: servico.tipoRecorrencia,
      qtdRecorrencia:servico.qtdRecorrencia,
      parcelas,
      descricao_parcelamento: servico.recorrencia ? `${servico.qtdRecorrencia}x de R$ ${servico.valor}`  : descricao_parcelamento,
      intervalo_parcela: servico.intervalo_parcela,
      cobrar_domingos: servico.cobrar_domingos
    }

    if(servico.index === ''){
      cliente.contrato.produtos.push({
        ...obj,
        index: cliente.contrato.produtos.length,
      })
    }else{
      cliente.contrato.produtos[servico.index] = {
        ...obj,
        index: servico.index,
      }
    }

    setState(prevState => ({...prevState,
      cliente,
      servico:{
        produto_id: '',
        descricao: '',
        formapagto_id: '',
        forma_pagto: '',
        valor: '',
        num_parcelas: '',
        contfin_id: '',
        conta_fin: '',
        venc_prim_parcela: '',
        gerar_nfse: false,
        recorrencia: false,
        parcelado: false,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        qtdRecorrencia: 1,
        intervalo_parcela: cliente.contrato.unidade_tempo,
        cobrar_domingos: 'SIM',
        index: ''
      },
      alteraServico: false,
      conta: {
        descricao: "",
        tipo: "A RECEBER",
        valortotal: "",
        numparcela: 1,
        item_id: '',
        tipo_item: 'ITEMRECDESP',
        qtd_parcelas: 1,
        gerar_nfse: false,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      }
    }))
  }

  const editarServico = (index) => {
    const {cliente} = state
    
    let conta = {
      descricao: "",
      tipo: "A RECEBER",
      valortotal: cliente.contrato.produtos[index].valor,
      numparcela: cliente.contrato.produtos[index].parcelas.length,
      item_id: '',
      tipo_item: 'ITEMRECDESP',
      qtd_parcelas: cliente.contrato.produtos[index].parcelas.length,
      gerar_nfse: false,
      parcelas: cliente.contrato.produtos[index].parcelas
    }

    setState(prevState => ({...prevState,
      servico: cliente.contrato.produtos[index],
      alteraServico: true,
      conta
    }))    
  }

  const removerServico = (row) => {
    let {cliente} = state

    cliente.contrato.produtos.splice(row.index, 1)

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const formataTelefoneCliente = (event) => {
    const { contato } = state;

    const telefone = event.target.value.trim().replace(/\D/g, "");
    
    let formatoTelefone;
    if (telefone.length === 11) {
      formatoTelefone = telefone.replace(/^(\d{2})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
    } else if (telefone.length === 10 || telefone.length === 12) {
      formatoTelefone = telefone.replace(/^(\d{3})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
    } else if (telefone.length === 8) {
      formatoTelefone = telefone.replace(/(\d)(\d{4})$/, "$1-$2");
    } else {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Telefone Inválido'
        },
        contato: { ...contato, numero: '55' }
      }));
      return false;
    }
  
    setState(prevState => ({...prevState, contato: { ...contato, numero: formatoTelefone } }));
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = real((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const uploadDoc = async (pessoa_id, doc) => {
    try {
      let {cliente} = state

      let files = cliente.pessoaFisica.files

      let file = files[doc]
      
      if(file.length > 0){
        console.log(file)
  
        const data = new FormData();
        data.append("file", file[0].file, file[0].name);
        const USER_TOKEN = localStorage.getItem('token')

        // await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/uploadDocumento/${pessoa_id}/${doc}/${cliente.contrato.unidade_id}`, data, getToken())
      
        await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/uploadDocumento/${pessoa_id}/${doc}/${cliente.contrato.unidade_id}`, data, {
          headers: {
            'Authorization': 'Bearer ' + USER_TOKEN,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          },
        }).catch((error) => {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        });
      }
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const salvar = async (reload, statusAtivoPessoa) => {

    const { cliente, responsavel } = state

    if(cliente.flags.length === 0){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:  "Informar ao menos um tipo de cadastro."
        }
      }))
      return
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    if (tipo === "MANTENEDOR" || tipo === "GRUPOECONOMICO") {
      if (!cliente.contrato.unidade_id) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:  "Por favor informar a unidade."
          }
        }))
        return
      }
    }

    if (cliente.tipoPessoa === "FISICA") {
      
      if (
        cliente.pessoaFisica.nome === ""
      ) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o nome do cliente.'
          }
        }))
        return
      }

      if (
        cliente.pessoaFisica.dataNascimento === ""
      ) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data de nascimento do cliente.'
          }
        }))
        return
      }

      const idade = calculaIdade(moment(cliente.pessoaFisica.dataNascimento).format('Y-MM-DD'))
      
      cliente.pessoaFisica.idade = idade

      if (!cliente.contrato.resp_fin) {
        cliente.maiorIdade = idade < 18 ? false : true
      }

      if(cliente.maiorIdade){
        if (
          cliente.pessoaFisica.cpf === "" 
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o CPF do cliente.'
            }
          }))
          return
        }
     
        let cpf_cnpj = cliente.pessoaFisica.cpf

        cpf_cnpj = cpf.format(cpf_cnpj)
  
        if (!cpf.isValid(cpf_cnpj)) {

          if(cpf_cnpj !== '000.000.000-00'){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'CPF Inválido! Por favor corrija o campo CPF'
              }
            }))
            return
          }
        }
      }else{
        if(cliente.pessoaFisica.cpf !== ''){
          let cpf_cnpj = cliente.pessoaFisica.cpf

          cpf_cnpj = cpf.format(cpf_cnpj)
    
          if (!cpf.isValid(cpf_cnpj)) {
  
            if(cpf_cnpj !== '000.000.000-00'){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'CPF Inválido! Por favor corrija o campo CPF'
                }
              }))
              return
            }
          }
        }
      }

      const anoNascimentoCliente = moment(cliente.pessoaFisica.dataNascimento).format('YYYY')

      if (parseInt(anoNascimentoCliente) > 9999) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Data de Nascimento Inválido!'
          }
        }))

        return
      }

      setState(prevState => ({...prevState, cliente }))

      if (cliente.maiorIdade) {
        if(cliente.pessoaFisica.cpf){
          // if (cliente.pessoaFisica.cpf === '000.000.000-00') {
          //   setState(prevState => ({...prevState,
          //     alerta: {
          //       open: true,
          //       severity: 'warning',
          //       message: 'CPF Inválido! Por favor informar o campo CPF.'
          //     }
          //   })
          //   return
          // }
        }
      }

      for (const item of dadosComplementaresPF.list) {
        // const dadosComplementar = cliente.pessoaFisica.dadosComplementar
        if(item.obrigatorio){
          if(item.column_name === 'doc_rg_frente'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_rg_verso'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_cnh_frente'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_cnh_verso'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_comprovante_renda'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_comprovante_endereco'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(cliente.pessoaFisica.dadosComplementar[item.column_name] === '' || !cliente.pessoaFisica.dadosComplementar[item.column_name]){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar o(a) ${item.column_comment}`
              }
            }))
            return
          }
        }
      }
    }else{
      
      cliente.maiorIdade = true

      setState(prevState => ({...prevState,
        cliente
      }))

      if (
        cliente.pessoaJuridica.razaoSocial === "" ||
        cliente.pessoaJuridica.nomeFantasia === ""
      ) {
        setState(prevState => ({...prevState,
          cliente,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }
    }

    if (cliente.maiorIdade) {
      if (cliente.contrato.situacao === 'AGUARDANDO_APROVACAO') {
        if (!validateFormContrato()) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Preencha os campos obrigatorios (*).'
            }
          }))
          return
        }

        const anoContrato = moment(cliente.contrato.data_ini_vig).format('YYYY')

        if (parseInt(anoContrato) > 9999) {
          setState(prevState => ({...prevState, loading: false }))
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Data de Inicio da Vigência Inválido!'
            }
          }))
          return
        }
      }
    } else {
      if (!validateFormEmailResponsavel()) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o email do responsável!'
          }
        }))
        return
      }
    }

    setState(prevState => ({...prevState, loading: true }))

    let dados = {}

    if (cliente.contrato.situacao === 'AGUARDANDO_APROVACAO' || state.clienteNovo) {
      if(cliente.tipoPessoa === 'FISICA'){
        let dadosComplementarAux = cliente.pessoaFisica.dadosComplementar

        dadosComplementarAux.receber_novidades = dadosComplementarAux.receber_novidades === 'SIM' ? true : false

        if(cliente.pessoaFisica.dadosComplementar.data_casamento === '') delete  dadosComplementarAux.data_casamento
        if(cliente.pessoaFisica.dadosComplementar.rg_data_emissao === '') delete  dadosComplementarAux.rg_data_emissao
        if(cliente.pessoaFisica.dadosComplementar.sexo === '') delete  dadosComplementarAux.sexo
        if(cliente.pessoaFisica.dadosComplementar.estado_civil === '') delete  dadosComplementarAux.estado_civil
        if(cliente.pessoaFisica.dadosComplementar.quantidade_filhos === '') delete  dadosComplementarAux.quantidade_filhos
        if(cliente.pessoaFisica.dadosComplementar.como_conheceu_id === '') delete  dadosComplementarAux.como_conheceu_id
        if(cliente.pessoaFisica.dadosComplementar.colegio === '') delete  dadosComplementarAux.colegio
        if(cliente.pessoaFisica.dadosComplementar.nome_pai === '') delete  dadosComplementarAux.nome_pai
        if(cliente.pessoaFisica.dadosComplementar.nome_mae === '') delete  dadosComplementarAux.nome_mae
        if(cliente.pessoaFisica.dadosComplementar.cnh_cliente === '') delete  dadosComplementarAux.cnh_cliente
        if(cliente.pessoaFisica.dadosComplementar.nome_conjugue === '') delete  dadosComplementarAux.nome_conjugue
        if(cliente.pessoaFisica.dadosComplementar.cpf_conjugue === '') delete  dadosComplementarAux.cpf_conjugue
        if(cliente.pessoaFisica.dadosComplementar.rg_conjugue === '') delete  dadosComplementarAux.rg_conjugue
        if(cliente.pessoaFisica.dadosComplementar.rg_orgao_emissor_conjugue === '') delete  dadosComplementarAux.rg_orgao_emissor_conjugue
        if(cliente.pessoaFisica.dadosComplementar.rg_data_emissao_conjugue === '') delete  dadosComplementarAux.rg_data_emissao_conjugue
        if(cliente.pessoaFisica.dadosComplementar.data_nascimento_conjugue === '') delete  dadosComplementarAux.data_nascimento_conjugue
        if(cliente.pessoaFisica.dadosComplementar.email_conjugue === '') delete  dadosComplementarAux.email_conjugue
        if(cliente.pessoaFisica.dadosComplementar.telefone_conjugue === '') delete  dadosComplementarAux.telefone_conjugue

        dados = {
          flags: cliente.flags,
          pessoa_id: cliente.pessoa_id,
          cliente: {
            codigo: cliente.codigo,
            tipo_pessoa: cliente.tipoPessoa,
            ativo: cliente.ativo
          },
          contrato: {
            id: cliente.contrato.id,
            data_ini_vig: cliente.contrato.data_ini_vig,
            data_fim_vig: cliente.contrato.data_fim_vig,
            codigo: cliente.contrato.codigo,
            tempo_contrato: cliente.contrato.tempo_contrato,
            unidade_tempo: cliente.contrato.unidade_tempo,
            unidade_id: cliente.contrato.unidade_id === "" ? pessoa.fisica.funcionario.contrato.unidadetrabalho.id : cliente.contrato.unidade_id,
            produtos: cliente.contrato.produtos.map(value => {
              let dados = {
                produto_id: parseInt(value.produto_id),
                valor: parseFloat(value.valor.replace("R$ ", "").replace(".", "").replace(",", ".")),
                num_parcelas: parseInt(value.num_parcelas || value.qtdRecorrencia),
                formapagto_id: value.recorrencia ? parseInt(value.formapagto_id) : '',
                contfin_id: value.recorrencia ? parseInt(value.contfin_id) : '',
                venc_prim_parcela: value.recorrencia ? value.venc_prim_parcela : value.parcelas[0].datavencimento,
                gerar_nfse: value.gerar_nfse,
                recorrencia: value.recorrencia,
                recorrenciaACada: value.recorrenciaACada,
                tipoRecorrencia: value.tipoRecorrencia,
                qtdRecorrencia: value.qtdRecorrencia
              }
    
              if(value.parcelado){
                let parcelas = []
    
                for (const parcela of value.parcelas) {
                  parcelas.push({
                    valorparcela: parseFloat(parcela.valorparcela.replace(".", "").replace(",", ".")),
                    datavencimento: parcela.datavencimento,
                    contfin_id: parseInt(parcela.contfin_id),
                    formapagto_id: parseInt(parcela.formapagto_id),
                    num: parcela.index,
                    documento: parcela.documento
                  })
                }
    
                dados.parcelas = parcelas
              }
    
              return {
                ...dados           
              }
            }),
            template_versao_id: cliente.contrato.template_versao_id || '',
            assinar_contrato: cliente.contrato.assinar_contrato,
            cobrar_multa: cliente.contrato.cobrar_multa,
            agrupar_vencidas: cliente.contrato.agrupar_vencidas,
            tipo_multa: cliente.contrato.tipo_multa,
            valor_multa: parseFloat(cliente.contrato.valor_multa.replace(".", "").replace(",", ".")),
            bonus_pont: cliente.contrato.bonus_pont,
            porc_bonus_pont: parseFloat(cliente.contrato.porc_bonus_pont.replace(".", "").replace(",", ".")),
          },
          pessoaFisica: {
            id: cliente.pessoaFisica.id || '',
            nome: cliente.pessoaFisica.nome,
            sobrenome: cliente.pessoaFisica.sobrenome,
            cpf: cliente.pessoaFisica.cpf,
            rg: cliente.pessoaFisica.rg,
            rg_orgao_emissor: cliente.pessoaFisica.rg_orgao_emissor,
            rg_uf: cliente.pessoaFisica.rg_uf,
            rg_data_emissao: cliente.pessoaFisica.rg_data_emissao,
            datanascimento: cliente.pessoaFisica.dataNascimento,
            dadosComplementar: dadosComplementarAux,
            enderecos: cliente.enderecos.map(end => {
              return {
                id: end.id || '',
                cep_id: end.cep_id,
                numero: end.numero,
                complemento: end.complemento,
                rua: end.rua,
                bairro: end.bairro,
                cidade: end.cidade,
                estado: end.estado,
                cep: end.cep,
                principal: end.principal,
                tipo: end.tipoEndereco
              }
            }),
            emails: cliente.emails,
            telefones: cliente.contatos.map(cont => {
              return {
                ...cont,
                tipo: cont.tipoContato
              }
            }),
          }
        }
      }else{
        dados = {
          flags: cliente.flags,
          pessoa_id: cliente.pessoa_id,
          cliente: {
            codigo: cliente.codigo,
            tipo_pessoa: cliente.tipoPessoa,
            ativo: cliente.ativo
          },
          contrato: {
            id: cliente.contrato.id,
            data_ini_vig: cliente.contrato.data_ini_vig,
            data_fim_vig: cliente.contrato.data_fim_vig,
            codigo: cliente.contrato.codigo,
            tempo_contrato: cliente.contrato.tempo_contrato,
            unidade_tempo: cliente.contrato.unidade_tempo,
            unidade_id: cliente.contrato.unidade_id === "" ? pessoa.fisica.funcionario.contrato.unidadetrabalho.id : cliente.contrato.unidade_id,
            produtos: cliente.contrato.produtos.map(value => {
              let dados = {
                produto_id: parseInt(value.produto_id),
                valor: parseFloat(value.valor.replace("R$ ", "").replace(".", "").replace(",", ".")),
                num_parcelas: parseInt(value.num_parcelas || value.qtdRecorrencia),
                formapagto_id: value.recorrencia ? parseInt(value.formapagto_id) : '',
                contfin_id: value.recorrencia ? parseInt(value.contfin_id) : '',
                venc_prim_parcela: value.recorrencia ? value.venc_prim_parcela : value.parcelas[0].datavencimento,
                gerar_nfse: value.gerar_nfse,
                recorrencia: value.recorrencia,
                recorrenciaACada: value.recorrenciaACada,
                tipoRecorrencia: value.tipoRecorrencia,
                qtdRecorrencia: value.qtdRecorrencia
              }
    
              if(value.parcelado){
                let parcelas = []
    
                for (const parcela of value.parcelas) {
                  parcelas.push({
                    valorparcela: parseFloat(parcela.valorparcela.replace(".", "").replace(",", ".")),
                    datavencimento: parcela.datavencimento,
                    contfin_id: parseInt(parcela.contfin_id),
                    formapagto_id: parseInt(parcela.formapagto_id),
                    num: parcela.index,
                    documento: parcela.documento
                  })
                }
    
                dados.parcelas = parcelas
              }
    
              return {
                ...dados           
              }
            }),
            template_versao_id: cliente.contrato.template_versao_id || '',
            assinar_contrato: cliente.contrato.assinar_contrato,
            cobrar_multa: cliente.contrato.cobrar_multa,
            agrupar_vencidas: cliente.contrato.agrupar_vencidas,
            tipo_multa: cliente.contrato.tipo_multa,
            valor_multa: parseFloat(cliente.contrato.valor_multa.replace(".", "").replace(",", ".")),
            bonus_pont: cliente.contrato.bonus_pont,
            porc_bonus_pont: parseFloat(cliente.contrato.porc_bonus_pont.replace(".", "").replace(",", ".")),
          },
          pessoaJuridica:{
            id: cliente.pessoaJuridica.id || '',
            razaosocial: cliente.pessoaJuridica.razaoSocial,
            nomefantasia: cliente.pessoaJuridica.nomeFantasia,
            inscricaoestadual: cliente.pessoaJuridica.inscricaoEstadual,
            datafundacao: cliente.pessoaJuridica.dataFundacao,
            cnpj: cliente.pessoaJuridica.cnpj,
            enderecos: cliente.enderecos.map(end => {
              return {
                id: end.id || '',
                cep_id: end.cep_id,
                numero: end.numero,
                complemento: end.complemento,
                rua: end.rua,
                bairro: end.bairro,
                cidade: end.cidade,
                estado: end.estado,
                cep: end.cep,
                principal: end.principal,
                tipo: end.tipoEndereco
              }
            }),
            emails: cliente.emails,
            telefones: cliente.contatos.map(cont => {
              return {
                ...cont,
                tipo: cont.tipoContato
              }
            }),
          }
        }
      }

      if(dados.contrato.id === ''){
        delete dados.contrato.id
      }

      if(dados.contrato.data_fim_vig === ''){
        delete dados.contrato.data_fim_vig
      }

      if(dados.contrato.tempo_contrato === ''){
        delete dados.contrato.tempo_contrato
      }

      if(dados.contrato.unidade_tempo === ''){
        delete dados.contrato.unidade_tempo
      }

      if(dados.contrato.codigo === ''){
        delete dados.contrato.codigo
      }
      
    } else {
      if(cliente.tipoPessoa === 'FISICA'){
        let dadosComplementarAux = cliente.pessoaFisica.dadosComplementar

        dadosComplementarAux.receber_novidades = dadosComplementarAux.receber_novidades === 'SIM' ? true : false

        if(cliente.pessoaFisica.dadosComplementar.data_casamento === '') delete  dadosComplementarAux.data_casamento
        if(cliente.pessoaFisica.dadosComplementar.rg_data_emissao === '') delete  dadosComplementarAux.rg_data_emissao
        if(cliente.pessoaFisica.dadosComplementar.sexo === '') delete  dadosComplementarAux.sexo
        if(cliente.pessoaFisica.dadosComplementar.estado_civil === '') delete  dadosComplementarAux.estado_civil
        if(cliente.pessoaFisica.dadosComplementar.quantidade_filhos === '') delete  dadosComplementarAux.quantidade_filhos
        if(cliente.pessoaFisica.dadosComplementar.como_conheceu_id === '') delete  dadosComplementarAux.como_conheceu_id
        if(cliente.pessoaFisica.dadosComplementar.colegio === '') delete  dadosComplementarAux.colegio
        if(cliente.pessoaFisica.dadosComplementar.nome_pai === '') delete  dadosComplementarAux.nome_pai
        if(cliente.pessoaFisica.dadosComplementar.nome_mae === '') delete  dadosComplementarAux.nome_mae
        if(cliente.pessoaFisica.dadosComplementar.cnh_cliente === '') delete  dadosComplementarAux.cnh_cliente
        if(cliente.pessoaFisica.dadosComplementar.nome_conjugue === '') delete  dadosComplementarAux.nome_conjugue
        if(cliente.pessoaFisica.dadosComplementar.cpf_conjugue === '') delete  dadosComplementarAux.cpf_conjugue
        if(cliente.pessoaFisica.dadosComplementar.rg_conjugue === '') delete  dadosComplementarAux.rg_conjugue
        if(cliente.pessoaFisica.dadosComplementar.rg_orgao_emissor_conjugue === '') delete  dadosComplementarAux.rg_orgao_emissor_conjugue
        if(cliente.pessoaFisica.dadosComplementar.rg_data_emissao_conjugue === '') delete  dadosComplementarAux.rg_data_emissao_conjugue
        if(cliente.pessoaFisica.dadosComplementar.data_nascimento_conjugue === '') delete  dadosComplementarAux.data_nascimento_conjugue
        if(cliente.pessoaFisica.dadosComplementar.email_conjugue === '') delete  dadosComplementarAux.email_conjugue
        if(cliente.pessoaFisica.dadosComplementar.telefone_conjugue === '') delete  dadosComplementarAux.telefone_conjugue

        dados = {
          flags: cliente.flags,
          pessoa_id: cliente.pessoa_id,
          cliente: {
            id: cliente.id,
            codigo: cliente.codigo,
            tipo_pessoa: cliente.tipoPessoa,
            ativo: cliente.ativo
          },
          pessoaFisica: {
            id: cliente.pessoaFisica.id,
            nome: cliente.pessoaFisica.nome,
            sobrenome: cliente.pessoaFisica.sobrenome,
            cpf: cliente.pessoaFisica.cpf,
            rg: cliente.pessoaFisica.rg,
            rg_orgao_emissor: cliente.pessoaFisica.rg_orgao_emissor,
            rg_uf: cliente.pessoaFisica.rg_uf,
            rg_data_emissao: cliente.pessoaFisica.rg_data_emissao,
            datanascimento: cliente.pessoaFisica.dataNascimento,
            dadosComplementar: dadosComplementarAux,
            enderecos: cliente.enderecos.map(end => {
              return {
                id: end.id || '',
                cep_id: end.cep_id,
                numero: end.numero,
                complemento: end.complemento,
                rua: end.rua,
                bairro: end.bairro,
                cidade: end.cidade,
                estado: end.estado,
                cep: end.cep,
                principal: end.principal,
                tipo: end.tipoEndereco
              }
            }),
            emails: cliente.emails,
            telefones: cliente.contatos.map(cont => {
              return {
                ...cont,
                tipo: cont.tipoContato
              }
            }),
          }
        }
      }else{
        dados = {
          flags: cliente.flags,
          pessoa_id: cliente.pessoa_id,
          cliente: {
            id: cliente.flags.includes('CLIENTE') ? cliente.id : null,
            codigo: cliente.flags.includes('CLIENTE') ? cliente.codigo : null,
            tipo_pessoa: cliente.tipoPessoa,
            ativo: cliente.ativo
          },
          pessoaJuridica:{
            id: cliente.pessoaJuridica.id,
            razaosocial: cliente.pessoaJuridica.razaoSocial,
            nomefantasia: cliente.pessoaJuridica.nomeFantasia,
            inscricaoestadual: cliente.pessoaJuridica.inscricaoEstadual,
            datafundacao: cliente.pessoaJuridica.dataFundacao,
            cnpj: cliente.pessoaJuridica.cnpj,
            enderecos: cliente.enderecos.map(end => {
              return {
                id: end.id || '',
                cep_id: end.cep_id,
                numero: end.numero,
                complemento: end.complemento,
                rua: end.rua,
                bairro: end.bairro,
                cidade: end.cidade,
                estado: end.estado,
                cep: end.cep,
                principal: end.principal,
                tipo: end.tipoEndereco
              }
            }),
            emails: cliente.emails,
            telefones: cliente.contatos.map(cont => {
              return {
                ...cont,
                tipo: cont.tipoContato
              }
            }),
          }
        }
      }
    }

    if (!cliente.maiorIdade) {   
      let dadosComplementarAuxResp = responsavel.pessoaFisica.dadosComplementar

      if(dadosComplementarAuxResp){
        
        dadosComplementarAuxResp.receber_novidades = dadosComplementarAuxResp.receber_novidades === 'SIM' ? true : false

        if(responsavel.pessoaFisica.dadosComplementar.data_casamento === '') delete dadosComplementarAuxResp.data_casamento
        if(responsavel.pessoaFisica.dadosComplementar.rg_data_emissao === '') delete dadosComplementarAuxResp.rg_data_emissao
        if(responsavel.pessoaFisica.dadosComplementar.sexo === '') delete  dadosComplementarAuxResp.sexo
        if(responsavel.pessoaFisica.dadosComplementar.estado_civil === '') delete dadosComplementarAuxResp.estado_civil
        if(responsavel.pessoaFisica.dadosComplementar.quantidade_filhos === '') delete dadosComplementarAuxResp.quantidade_filhos
        if(responsavel.pessoaFisica.dadosComplementar.como_conheceu_id === '') delete  dadosComplementarAuxResp.como_conheceu_id
        if(responsavel.pessoaFisica.dadosComplementar.colegio === '') delete  dadosComplementarAuxResp.colegio
        if(responsavel.pessoaFisica.dadosComplementar.nome_pai === '') delete  dadosComplementarAuxResp.nome_pai
        if(responsavel.pessoaFisica.dadosComplementar.nome_mae === '') delete  dadosComplementarAuxResp.nome_mae
        if(responsavel.pessoaFisica.dadosComplementar.cnh_cliente === '') delete  dadosComplementarAuxResp.cnh_cliente
        if(responsavel.pessoaFisica.dadosComplementar.nome_conjugue === '') delete  dadosComplementarAuxResp.nome_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.cpf_conjugue === '') delete  dadosComplementarAuxResp.cpf_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.rg_conjugue === '') delete  dadosComplementarAuxResp.rg_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.rg_orgao_emissor_conjugue === '') delete  dadosComplementarAuxResp.rg_orgao_emissor_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.rg_data_emissao_conjugue === '') delete  dadosComplementarAuxResp.rg_data_emissao_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.data_nascimento_conjugue === '') delete  dadosComplementarAuxResp.data_nascimento_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.email_conjugue === '') delete  dadosComplementarAuxResp.email_conjugue
        if(responsavel.pessoaFisica.dadosComplementar.telefone_conjugue === '') delete  dadosComplementarAuxResp.telefone_conjugue
      }


      const resp_fin = {
        tipo_pessoa: responsavel.tipoPessoa,
        pessoaFisica:{
          id: responsavel.pessoaFisica.id,
          pessoa_id: responsavel.pessoaFisica.pessoa_id,
          nome: responsavel.pessoaFisica.nome,
          sobrenome: responsavel.pessoaFisica.sobrenome,
          cpf: responsavel.pessoaFisica.cpf,
          rg: responsavel.pessoaFisica.rg,
          rg_orgao_emissor: responsavel.pessoaFisica.rg_orgao_emissor,
          rg_uf: responsavel.pessoaFisica.rg_uf,
          rg_data_emissao: responsavel.pessoaFisica.rg_data_emissao,
          datanascimento: responsavel.pessoaFisica.dataNascimento,
          dadosComplementar: dadosComplementarAuxResp,
          enderecos: responsavel.enderecos.map(end => {
            return {
              id: end.id || '',
              cep_id: end.cep_id,
              numero: end.numero,
              complemento: end.complemento,
              rua: end.rua,
              bairro: end.bairro,
              cidade: end.cidade,
              estado: end.estado,
              cep: end.cep,
              principal: end.principal,
              tipo: end.tipoEndereco
            }
          }),
          emails: responsavel.emails,
          telefones: responsavel.contatos.map(cont => {
            return {
              ...cont,
              tipo: cont.tipoContato
            }
          })
        },
        pessoaJuridica:{
          id: responsavel.pessoaJuridica.id,
          pessoa_id: responsavel.pessoaJuridica.pessoa_id,
          razaosocial: responsavel.pessoaJuridica.razaoSocial,
          nomefantasia: responsavel.pessoaJuridica.nomeFantasia,
          inscricaoestadual: responsavel.pessoaJuridica.inscricaoEstadual,
          datafundacao: responsavel.pessoaJuridica.dataFundacao,
          cnpj: responsavel.pessoaJuridica.cnpj,
          enderecos: responsavel.enderecos.map(end => {
            return {
              id: end.id || '',
              cep_id: end.cep_id,
              numero: end.numero,
              complemento: end.complemento,
              rua: end.rua,
              bairro: end.bairro,
              cidade: end.cidade,
              estado: end.estado,
              cep: end.cep,
              principal: end.principal,
              tipo: end.tipoEndereco
            }
          }),
          emails: responsavel.emails,
          telefones: responsavel.contatos.map(cont => {
            return {
              ...cont,
              tipo: cont.tipoContato
            }
          })
        }
      }

      if(resp_fin.tipo_pessoa === 'FISICA'){
        if (resp_fin.pessoaFisica.id === '') {
          delete resp_fin.pessoaFisica.id
          delete resp_fin.pessoaFisica.pessoa_id
        }

        for (let i = 0; i < resp_fin.pessoaFisica.enderecos.length; i++) {
          if (resp_fin.pessoaFisica.enderecos[i].complemento === "" || !resp_fin.pessoaFisica.enderecos[i].complemento) {
            delete resp_fin.pessoaFisica.enderecos[i].id
            delete resp_fin.pessoaFisica.enderecos[i].complemento
          }
        }

        if (resp_fin.pessoaFisica.rg === '' || !resp_fin.pessoaFisica.rg) {
          delete resp_fin.pessoaFisica.rg
        }

        if (resp_fin.pessoaFisica.datanascimento === '' || !resp_fin.pessoaFisica.datanascimento) {
          delete resp_fin.pessoaFisica.datanascimento
        }

        if (resp_fin.pessoaFisica.rg_orgao_emissor === '' || !resp_fin.pessoaFisica.rg_orgao_emissor) {
          delete resp_fin.pessoaFisica.rg_orgao_emissor
        }

        if (resp_fin.pessoaFisica.rg_uf === '' || !resp_fin.pessoaFisica.rg_uf) {
          delete resp_fin.pessoaFisica.rg_uf
        }

        if (resp_fin.pessoaFisica.rg_data_emissao === '' || !resp_fin.pessoaFisica.rg_data_emissao) {
          delete resp_fin.pessoaFisica.rg_data_emissao
        }

        if(resp_fin.pessoaFisica.sobrenome === '' || !resp_fin.pessoaFisica.sobrenome){
          delete resp_fin.pessoaFisica.sobrenome
        }

        delete resp_fin.pessoaJuridica
      }else{
        if (resp_fin.pessoaJuridica.id === '') {
          delete resp_fin.pessoaJuridica.id
          delete resp_fin.pessoaJuridica.pessoa_id
        }

        for (let i = 0; i < resp_fin.pessoaJuridica.enderecos.length; i++) {
          if (resp_fin.pessoaJuridica.enderecos[i].complemento === "" || !resp_fin.pessoaJuridica.enderecos[i].complemento) {
            delete resp_fin.pessoaJuridica.enderecos[i].id
            delete resp_fin.pessoaJuridica.enderecos[i].complemento
          }
        }

        delete resp_fin.pessoaFisica
      }

      dados.resp_fin = resp_fin

    }

    if(dados.cliente.tipo_pessoa === 'FISICA'){
      for (let i = 0; i < dados.pessoaFisica.enderecos.length; i++) {
        if (dados.pessoaFisica.enderecos[i].complemento === "" || !dados.pessoaFisica.enderecos[i].complemento) {
          delete dados.pessoaFisica.enderecos[i].complemento
        }
        if (dados.pessoaFisica.enderecos[i].id === "" || !dados.pessoaFisica.enderecos[i].id) {
          delete dados.pessoaFisica.enderecos[i].id
        }
      }
      delete dados.pessoaJuridica
    }else{
      for (let i = 0; i < dados.pessoaJuridica.enderecos.length; i++) {
        if (dados.pessoaJuridica.enderecos[i].complemento === "" || !dados.pessoaJuridica.enderecos[i].complemento) {
          delete dados.pessoaJuridica.enderecos[i].complemento
        }
        if (dados.pessoaJuridica.enderecos[i].id === "" || !dados.pessoaJuridica.enderecos[i].id) {
          delete dados.pessoaJuridica.enderecos[i].id
        }
      }
      delete dados.pessoaFisica
    }

    try {

      if(dados.cliente.tipo_pessoa === 'FISICA'){
        if (dados.pessoaFisica.id === '' || !dados.pessoaFisica.id) {
          delete dados.pessoaFisica.id
        }
        if (dados.pessoaFisica.datanascimento === '' || !dados.pessoaFisica.datanascimento) {
          delete dados.pessoaFisica.datanascimento
        }
        if (dados.pessoaFisica.cpf === '' || !dados.pessoaFisica.cpf) {
          delete dados.pessoaFisica.cpf
        }
        if (dados.pessoaFisica.rg === '' || !dados.pessoaFisica.rg) {
          delete dados.pessoaFisica.rg
        }
        if (dados.pessoaFisica.rg_orgao_emissor === '' || !dados.pessoaFisica.rg_orgao_emissor) {
          delete dados.pessoaFisica.rg_orgao_emissor
        }
        if (dados.pessoaFisica.rg_uf === '' || !dados.pessoaFisica.rg_uf) {
          delete dados.pessoaFisica.rg_uf
        }
        if (dados.pessoaFisica.rg_data_emissao === '' || !dados.pessoaFisica.rg_data_emissao) {
          delete dados.pessoaFisica.rg_data_emissao
        }
        if(dados.pessoaFisica.sobrenome === '' || !dados.pessoaFisica.sobrenome){
          delete dados.pessoaFisica.sobrenome
        }
      }else{
        if (dados.pessoaJuridica.id === '' || !dados.pessoaJuridica.id) {
          delete dados.pessoaJuridica.id
        }

        if (dados.pessoaJuridica.cnpj === '' || !dados.pessoaJuridica.cnpj) {
          delete dados.pessoaJuridica.cnpj
        }

        if (dados.pessoaJuridica.inscricaoestadual === '' || !dados.pessoaJuridica.inscricaoestadual) {
          delete dados.pessoaJuridica.inscricaoestadual
        }

        if (dados.pessoaJuridica.datafundacao === '' || !dados.pessoaJuridica.datafundacao) {
          delete dados.pessoaJuridica.datafundacao
        }
      }

      if(dados.cliente.codigo === '' || !dados.cliente.codigo){
        delete dados.cliente.codigo
      }

      if(dados.cliente.id === '' || !dados.cliente.id){
        delete dados.cliente.id
      }

      if(dados.flags.length === 0){
        delete dados.flags
      }

      if(dados.contrato){
        if(dados.contrato.produtos.length === 0){
          delete dados.contrato.produtos
        }
      }

      if (cliente.pessoa_id !== "" && state.edit) {
        if(statusAtivoPessoa && !cliente.ativo){
          
          await verificaInativaPessoa(cliente.pessoa_id)
         
          return false
        }else{
          await axios.put(`${window._env_.REACT_APP_API_URL}/cliente/${cliente.id ? cliente.id : cliente.pessoa_id}`, dados, getToken())
        
          for (const key in cliente.pessoaFisica.files) {
            console.log(key)
            await uploadDoc(cliente.pessoa_id, key)
          }
        }
      } else {

        if (state.novaMatricula) {
          dados.novoContrato = true
        } else {
          delete dados.cliente.id
          delete dados.contrato.id
        }

        dados.informar_contrato = state.informar_contrato

        const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente`, dados, getToken())
        
        for (const key in cliente.pessoaFisica.files) {
          await uploadDoc(data.pessoa.id, key)
        }

        if(data.contas_criadas.length > 0){
          for (const conta_pr of data.contas_criadas) {
            if(conta_pr.forma_pgto === 'boleto'){
              await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, conta_pr, getToken())
            }else if(conta_pr.forma_pgto === 'pix'){
              // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, conta_pr, getToken())
            }
          }
        }

        let ultima_tela = localStorage.getItem('ultima_tela_cliente')

        if(ultima_tela){
          localStorage.setItem('pessoa_cadastrada', JSON.stringify(data.pessoa));

          navigate(ultima_tela);
          return;
        }

      }

      if (reload) {
        setState(prevState => ({...prevState,
          cliente: {
            id: "",
            pessoa_id: "",
            tipoPessoa: "FISICA",
            codigo: '',
            maiorIdade: true,
            flags: [],
            ativo: true,
            pessoaFisica: {
              nome: "",
              sobrenome:"",
              cpf: "",
              rg: "",
              rg_orgao_emissor: "",
              rg_uf: "",
              rg_data_emissao: "",
              dataNascimento: "",
              idade: 0,
              dadosComplementar: {
                sexo: '',
                num_calcado: '',
                receber_novidades: '',
                quantidade_filhos: '',
                profissao: '',
                data_casamento: '',
                rg: '',
                rg_orgao_emissor: '',
                rg_uf: '',
                rg_data_emissao: '',
                estado_civil: '',
                como_conheceu_id: '',
                colegio: '',
                nome_pai: '',
                nome_mae: '',
                cnh_cliente: '',
                nome_conjugue: '',
                cpf_conjugue: '',
                rg_conjugue: '',
                rg_orgao_emissor_conjugue: '',
                rg_data_emissao_conjugue: '',
                data_nascimento_conjugue: '',
                email_conjugue: '',
                telefone_conjugue: '',
              },
              files: {
                doc_rg_frente: [],
                doc_rg_verso: [],
                doc_cnh_frente: [],
                doc_cnh_verso: [],
                doc_comprovante_renda: [],
                doc_comprovante_endereco: [],
              }
            },
            pessoaJuridica: {
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: "",
            },
            enderecos: [],
            emails: [],
            contatos: [],
            contrato: {
              id: "",
              data_ini_vig: new Date(),
              data_fim_vig: "",
              codigo: "",
              tempo_contrato: "",
              unidade_tempo: "",
              cliente_id: "",
              unidade_id: "",
              usuario_id: "",
              situacao: "AGUARDANDO_APROVACAO",
              produtos: [],
              template_versao_id: "",
              assinar_contrato: false,
              cobrar_multa: false,
              agrupar_vencidas: false,
              tipo_multa: '',
              valor_multa: real(0),
              bonus_pont: false,
              porc_bonus_pont: '0'
            }
          },
          responsavel: {
            id: "",
            tipoPessoa: 'FISICA',
            pessoaFisica: {
              id: "",
              pessoa_id: "",
              nome: "",
              sobrenome:"",
              cpf: "",
              rg: "",
              rg_orgao_emissor: "",
              rg_uf: "",
              rg_data_emissao: "",
              dataNascimento: "",
              maiorIdade: true,
              dadosComplementar: {
                sexo: '',
                num_calcado: '',
                receber_novidades: '',
                quantidade_filhos: '',
                profissao: '',
                data_casamento: '',
                rg: '',
                rg_orgao_emissor: '',
                rg_uf: '',
                rg_data_emissao: '',
                estado_civil: '',
                como_conheceu_id: '',
                colegio: '',
                nome_pai: '',
                nome_mae: '',
                cnh_cliente: '',
                nome_conjugue: '',
                cpf_conjugue: '',
                rg_conjugue: '',
                rg_orgao_emissor_conjugue: '',
                rg_data_emissao_conjugue: '',
                data_nascimento_conjugue: '',
                email_conjugue: '',
                telefone_conjugue: '',
              },
              files: {
                doc_rg_frente: [],
                doc_rg_verso: [],
                doc_cnh_frente: [],
                doc_cnh_verso: [],
                doc_comprovante_renda: [],
                doc_comprovante_endereco: [],
              }
            },
            pessoaJuridica: {
              id:"",
              pessoa_id: "",
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: "",
            },
            enderecos: [],
            emails: [],
            contatos: [],
          },
          servico:{
            produto_id: '',
            descricao: '',
            formapagto_id: '',
            forma_pagto: '',
            valor: '',
            num_parcelas: '',
            contfin_id: '',
            conta_fin: '',
            venc_prim_parcela: '',
            gerar_nfse: false,
            index: ''
          },
          searchResp: {
            cpf: ''
          },
          alerta: {
            open: true,
            severity: 'success',
            message: 'Cadastro realizado com sucesso.'
          },
          loading: false
        }))
        backPage()
      }else{
        setState(prevState => ({...prevState,
          cliente: {
            id: "",
            pessoa_id: "",
            tipoPessoa: "FISICA",
            codigo: '',
            maiorIdade: true,
            flags: [],
            ativo: true,
            pessoaFisica: {
              nome: "",
              sobrenome:"",
              cpf: "",
              rg: "",
              rg_orgao_emissor: "",
              rg_uf: "",
              rg_data_emissao: "",
              dataNascimento: "",
              idade: 0,
              dadosComplementar: {
                sexo: '',
                num_calcado: '',
                receber_novidades: '',
                quantidade_filhos: '',
                profissao: '',
                data_casamento: '',
                rg: '',
                rg_orgao_emissor: '',
                rg_uf: '',
                rg_data_emissao: '',
                estado_civil: '',
                como_conheceu_id: '',
                colegio: '',
                nome_pai: '',
                nome_mae: '',
                cnh_cliente: '',
                nome_conjugue: '',
                cpf_conjugue: '',
                rg_conjugue: '',
                rg_orgao_emissor_conjugue: '',
                rg_data_emissao_conjugue: '',
                data_nascimento_conjugue: '',
                email_conjugue: '',
                telefone_conjugue: '',
              },
              files: {
                doc_rg_frente: [],
                doc_rg_verso: [],
                doc_cnh_frente: [],
                doc_cnh_verso: [],
                doc_comprovante_renda: [],
                doc_comprovante_endereco: [],
              }
            },
            pessoaJuridica: {
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: "",
            },
            enderecos: [],
            emails: [],
            contatos: [],
            contrato: {
              id: "",
              data_ini_vig: new Date(),
              data_fim_vig: "",
              codigo: "",
              tempo_contrato: "",
              unidade_tempo: "",
              cliente_id: "",
              unidade_id: "",
              usuario_id: "",
              situacao: "AGUARDANDO_APROVACAO",
              produtos: [],
              template_versao_id: "",
              assinar_contrato: false,
              cobrar_multa: false,
              agrupar_vencidas: false,
              tipo_multa: '',
              valor_multa: real(0),
              bonus_pont: false,
              porc_bonus_pont: '0'
            }
          },
          responsavel: {
            id: "",
            tipoPessoa: 'FISICA',
            pessoaFisica: {
              id: "",
              pessoa_id: "",
              nome: "",
              sobrenome:"",
              cpf: "",
              rg: "",
              rg_orgao_emissor: "",
              rg_uf: "",
              rg_data_emissao: "",
              dataNascimento: "",
              maiorIdade: true,
              dadosComplementar: {
                sexo: '',
                num_calcado: '',
                receber_novidades: '',
                quantidade_filhos: '',
                profissao: '',
                data_casamento: '',
                rg: '',
                rg_orgao_emissor: '',
                rg_uf: '',
                rg_data_emissao: '',
                estado_civil: '',
                como_conheceu_id: '',
                colegio: '',
                nome_pai: '',
                nome_mae: '',
                cnh_cliente: '',
                nome_conjugue: '',
                cpf_conjugue: '',
                rg_conjugue: '',
                rg_orgao_emissor_conjugue: '',
                rg_data_emissao_conjugue: '',
                data_nascimento_conjugue: '',
                email_conjugue: '',
                telefone_conjugue: '',
              },
              files: {
                doc_rg_frente: [],
                doc_rg_verso: [],
                doc_cnh_frente: [],
                doc_cnh_verso: [],
                doc_comprovante_renda: [],
                doc_comprovante_endereco: [],
              }
            },
            pessoaJuridica: {
              id:"",
              pessoa_id: "",
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: "",
            },
            enderecos: [],
            emails: [],
            contatos: [],
          },
          servico:{
            produto_id: '',
            descricao: '',
            formapagto_id: '',
            forma_pagto: '',
            valor: '',
            num_parcelas: '',
            contfin_id: '',
            conta_fin: '',
            venc_prim_parcela: '',
            gerar_nfse: false,
            index: ''
          },
          searchResp: {
            cpf: ''
          },
          loading: false
        }))
      }
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const verificaInativaPessoa = async (pessoa_id) => {
    try {
      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/verificaInativacao/${pessoa_id}`, getToken())
    
      if(data.libera){
        setState(prevState => ({...prevState,
          statusAtivoPessoa: false
        }))

        await salvar(true, false)
      }else{
        setState(prevState => ({...prevState,
          modalConfirmacaoInativaOpen: true,
          modalConfirmacao: {
            mensagem: data.mensagem,
          }
        }))
      }

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const confirmarInativacao = async (value) => {
    if(value){
      setState(prevState => ({...prevState,
        statusAtivoPessoa: false,
        modalConfirmacaoInativaOpen: false,
        modalConfirmacao: {
          mensagem: '',
        },
      }))

      await salvar(true, false)
    }else{
      setState(prevState => ({...prevState,
        modalConfirmacaoInativaOpen: false,
        modalConfirmacao: {
          mensagem: '',
        },
        loading: false
      }))
    }
  }

  const handleCloseInativacao = () => {
    setState(prevState => ({...prevState,
      modalConfirmacaoInativaOpen: false,
      modalConfirmacao: {
        mensagem: '',
      },
      loading: false
    }))
  }

  const updateFieldGerarNfse = () => {
    let {servico} = state

    servico.gerar_nfse = !servico.gerar_nfse

    setState(prevState => ({...prevState,servico}))
  }

  const updateRecorrencia = () => {
    let {servico} = state

    servico.recorrencia = !servico.recorrencia
    servico.parcelado = false
    servico.qtd_parcelas = 1
    servico.recorrenciaACada = 1
    servico.tipoRecorrencia = 'M'
    servico.qtdRecorrencia = 1
    
    setState(prevState => ({...prevState,servico}))
  }

  const updateParcelado = () => {
    let {servico} = state
    servico.parcelado = !servico.parcelado
    servico.recorrencia = false
    setState(prevState => ({...prevState,servico}))
  }

  const criaUrl = () => {
    const filtro = JSON.parse(localStorage.getItem('filtro_pessoas'))

    if(filtro){
      let url = `/cliente?limit=${filtro.limit}&offset=${filtro.offset}`
  
      if(filtro.filtrar_por !== ''){
        url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
      }
  
      if(filtro.nome !== ''){
        url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
      }
  
      if(filtro.cpf_cnpj !== ''){
        url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
      }
  
      if(filtro.telefone !== ''){
        url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
      }
  
      if(filtro.ativo !== ''){
        url += `&ativo=${filtro.ativo.replaceAll(' ', '')}`
      }
  
      if(filtro.flag !== ''){
        url += `&flag=${filtro.flag.replaceAll(' ', '')}`
      }

      return url
    }

    return `/cliente?limit=10&offset=0`

  }

  const validarDadosPessoa = async () => {
    const {cliente, dadosComplementaresPF} = state
      
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    if(!state.edit){
      if(cliente.flags.length === 0){
        return {
          valido: false,
          mensagem: 'Informar ao menos um tipo de cadastro.'
        }
      }
    }

    if (tipo === "MANTENEDOR" || tipo === "GRUPOECONOMICO") {
      if (!cliente.contrato.unidade_id) {
        return {
          valido: false,
          mensagem: 'Por favor informar a unidade.'
        }
      }
    }

    if (cliente.tipoPessoa === "FISICA") {
      
      if (cliente.pessoaFisica.nome === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar o nome do cliente.'
        }
      }

      if (cliente.pessoaFisica.dataNascimento === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar a data de nascimento do cliente.'
        }
      }

      if (cliente.pessoaFisica.cpf === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar o CPF do cliente.'
        }
      }

      const idade = calculaIdade(moment(cliente.pessoaFisica.dataNascimento).format('Y-MM-DD'))
      
      cliente.pessoaFisica.idade = idade

      if (!cliente.contrato.resp_fin) {
        cliente.maiorIdade = idade < 18 ? false : true
      }

      if(cliente.maiorIdade){
        let cpf_cnpj = cliente.pessoaFisica.cpf

        cpf_cnpj = cpf.format(cpf_cnpj)
  
        if (!cpf.isValid(cpf_cnpj)) {
          if(cpf_cnpj !== '000.000.000-00'){
            return {
              valido: false,
              mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
            }
          }
        }
      }else{
        if(cliente.pessoaFisica.cpf !== ''){
          let cpf_cnpj = cliente.pessoaFisica.cpf

          cpf_cnpj = cpf.format(cpf_cnpj)
    
          if (!cpf.isValid(cpf_cnpj)) {
            if(cpf_cnpj !== '000.000.000-00'){
              return {
                valido: false,
                mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
              }
            }
          }
        }
      }

      const anoNascimentoCliente = moment(cliente.pessoaFisica.dataNascimento).format('YYYY')

      if (parseInt(anoNascimentoCliente) > 9999) {
        return {
          valido: false,
          mensagem: 'Data de Nascimento Inválido!'
        }
      }

      setState(prevState => ({...prevState, cliente }))

      for (const item of dadosComplementaresPF.list) {
        if(item.obrigatorio){
          if(item.column_name === 'doc_rg_frente'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              return {
                valido: false,
                mensagem: `Por favor informar o(a) ${item.column_comment}`
              }
            }
          }else if(item.column_name === 'doc_rg_verso'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              return {
                valido: false,
                mensagem: `Por favor informar o(a) ${item.column_comment}`
              }
            }
          }else if(item.column_name === 'doc_cnh_frente'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              return {
                valido: false,
                mensagem: `Por favor informar o(a) ${item.column_comment}`
              }
            }
          }else if(item.column_name === 'doc_cnh_verso'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              return {
                valido: false,
                mensagem: `Por favor informar o(a) ${item.column_comment}`
              }
            }
          }else if(item.column_name === 'doc_comprovante_renda'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              return {
                valido: false,
                mensagem: `Por favor informar o(a) ${item.column_comment}`
              }
            }
          }else if(item.column_name === 'doc_comprovante_endereco'){
            if(cliente.pessoaFisica.files[item.column_name].length === 0){
              return {
                valido: false,
                mensagem: `Por favor informar o(a) ${item.column_comment}`
              }
            }
          }else if(cliente.pessoaFisica.dadosComplementar[item.column_name] === '' || !cliente.pessoaFisica.dadosComplementar[item.column_name]){
            return {
              valido: false,
              mensagem: `Por favor informar o(a) ${item.column_comment}`
            }
          }
        }
      }
    }else{
      
      cliente.maiorIdade = true

      setState(prevState => ({...prevState,
        cliente
      }))

      if (
        cliente.pessoaJuridica.razaoSocial === "" ||
        cliente.pessoaJuridica.nomeFantasia === ""
      ) {
        setState(prevState => ({...prevState,
          cliente
        }))

        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    if(cliente.enderecos.length > 0){
      let valido = true
      cliente.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
  
      if(cliente.enderecos.length === 0 && cliente.id){
        try {
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/verificaCliente/${cliente.pessoa_id}`, getToken())
          
          if(data){
            return {
              valido: false,
              mensagem: `Para esse cliente foram gerados contratos com emissão de nfse, por favor informar o endereço do responsável.`
            }
          }
  
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }        
      }
    }
    
    if(cliente.contatos.length > 0){
      cliente.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato do cliente.`
          }
        }
      })
    }

    if(cliente.emails.length > 0){
      let valido = true
      cliente.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    setState(prevState => ({...prevState,
      cliente
    }))

    return {
      valido: true,
      mensagem: ''
    }
  }

  const informarContrato = async () => {
    const {cliente} = state

    let validacao = await validarDadosPessoa()

    if(!validacao.valido){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: validacao.mensagem
        }
      }))
      return
    }

    cliente.contrato.data_ini_vig = moment().format('YYYY-MM-DD')

    await consultarTemplatesDocumento(cliente.contrato.unidade_id)

    setState(prevState => ({...prevState,
      cliente,
      enableDadosPessoa: false,
      enableDadosContrato: true,
      informar_contrato: true
    }))
  }

  const consultarTemplatesDocumento = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/list`, dados, getToken())

      let array = []

      for (const template of data) {
        for (const versao of template.versoes) {
          if(versao.ativo && versao.publicado && moment(versao.datainicio).isBefore(moment())){
            array.push({
              template_id: template.id,
              versao_id: versao.id,
              titulo: template.titulo,
              assinar: template.assinar,
              versao: versao.versao,
              ativo: versao.ativo
            })
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        template_documentos: {
          list: array
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const voltarDadosPessoa = () => {
    setState(prevState => ({...prevState,
      enableDadosPessoa: true,
      enableDadosContrato: false
    }))
  }

  const voltarBuscaResp = () => {
    setState(prevState => ({...prevState,
      enableBuscaResp: true
    }))
  }

  const informarEndereco = () => {
    const { cliente } = state;
    let principal = !cliente.enderecos.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarEndereco: true,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }))
  }

  const editarEndereco = (row) => {
    let {cliente} = state

    setState(prevState => ({...prevState,
      endereco: {...cliente.enderecos[row.index - 1]},
      informarEndereco: true
    }))
  }

  const informarContato = () => {
    const { cliente } = state;
    let principal = !cliente.contatos.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarContato: true,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }))
  }

  const editarContato = (row) => {
    let {cliente} = state

    setState(prevState => ({...prevState,
      contato: {...cliente.contatos[row.index - 1]},
      informarContato: true
    }))
  }

  const editarEmail = (row) => {
    let {cliente} = state

    setState(prevState => ({...prevState,
      email: {...cliente.emails[row.index - 1]},
      informarEmail: true
    }))
  }

  const informarEmail = () => {
    const { cliente } = state;
    let principal = !cliente.emails.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarEmail: true,
      email: {
        email: "",
        principal,
        index: ""
      },
    }));
  }

  const informarEnderecoResponsavel = () => {
    const { responsavel } = state;
    let principal = !responsavel.enderecos.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarEnderecoResponsavel: true,
      enderecoResponsavel: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }))
  }

  const editarEnderecoResponsavel = (row) => {
    let {responsavel} = state

    setState(prevState => ({...prevState,
      enderecoResponsavel: {...responsavel.enderecos[row.index - 1]},
      informarEnderecoResponsavel: true
    }))
  }

  const informarContatoResponsavel = () => {
    const { responsavel } = state;
    let principal = !responsavel.contatos.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarContatoResponsavel: true,
      contatoResponsavel: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }))
  }

  const editarContatoResponsavel = (row) => {
    let {responsavel} = state

    setState(prevState => ({...prevState,
      contatoResponsavel: {...responsavel.contatos[row.index - 1]},
      informarContatoResponsavel: true
    }))
  }

  const informarEmailResponsavel = () => {
    const { responsavel } = state;
    let principal = !responsavel.emails.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarEmailResponsavel: true,
      emailResponsavel: {
        email: "",
        principal,
        index: ""
      },
    }));
  }

  const editarEmailResponsavel = (row) => {
    let {responsavel} = state

    setState(prevState => ({...prevState,
      emailResponsavel: {...responsavel.emails[row.index - 1]},
      informarEmailResponsavel: true
    }))
  }

  const updateFieldConta = (event) => {
    const conta = { ...state.conta }

    if (event.target.name === 'valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value


    setState(prevState => ({...prevState, conta }))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = real(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const mudarParcelas = (event) => {
    const {conta, servico} = state

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))  

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))
    let data_acrecentada = moment()
    
    for (let i = 0; i < qtdParcelasInfo; i++) {
      data_acrecentada = moment(data_acrecentada).add(1, verificaUnidadeTempo(servico.intervalo_parcela))

      if(servico.cobrar_domingos === 'NAO' && moment(data_acrecentada).format('dddd') === 'domingo'){
        data_acrecentada = moment(data_acrecentada).add(1, 'day')
      }

      array.push({
        baixado: "",
        datavencimento: moment(data_acrecentada).format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array


    setState(prevState => ({...prevState, conta }))
  }

  const updateFieldParcela = (event, index) => {
    const {conta, formasPagamento} = state
    
    let value = event.target.value
    let name = event.target.name
    
    if(name === 'formapagto_id'){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
      
      conta.parcelas[index - 1].habilita = formaPagtoSelecionada.habilita
      conta.parcelas[index - 1].contfin_id = ""
    }

    conta.parcelas[index - 1][name] = value
    
    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelas = (event, index) => {
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelasContaFin = (event, index) => {
    
    const {conta, contasFinanceira} = state
        
    const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(contaFinanceiraSelecionada){
        if(conta.parcelas[i].habilita === contaFinanceiraSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const updateFieldCobrarMulta = () => {
    let { cliente } = state

    cliente.contrato.cobrar_multa = !cliente.cobrar_multa

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  const handleUpload = (files, name) => {
    let {cliente} = state  

    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    cliente.pessoaFisica.files[name] = uploadedFiles

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const updateFieldAgruparVencidas = () => {
    let { cliente } = state

    cliente.contrato.agrupar_vencidas = !cliente.agrupar_vencidas

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  const updateFieldBonusPontualidade = () => {
    let { cliente } = state

    cliente.contrato.bonus_pont = !cliente.bonus_pont

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/cliente/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })
      
      setState(prevState => ({...prevState,
        loading: true
      }))

      let cliente = {
        id: "",
        pessoa_id: "",
        tipoPessoa: "FISICA",
        codigo: '',
        maiorIdade: true,
        flags: [],
        ativo: true,
        pessoaFisica: {
          nome: "",
          sobrenome:"",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: "",
          idade: 0,
          dadosComplementar: {
            sexo: '',
            num_calcado: '',
            receber_novidades: '',
            quantidade_filhos: '',
            profissao: '',
            data_casamento: '',
            rg: '',
            rg_orgao_emissor: '',
            rg_uf: '',
            rg_data_emissao: '',
            estado_civil: '',
            como_conheceu_id: '',
            colegio: '',
            nome_pai: '',
            nome_mae: '',
            cnh_cliente: '',
            nome_conjugue: '',
            cpf_conjugue: '',
            rg_conjugue: '',
            rg_orgao_emissor_conjugue: '',
            rg_data_emissao_conjugue: '',
            data_nascimento_conjugue: '',
            email_conjugue: '',
            telefone_conjugue: '',
          },
          files: {
            doc_rg_frente: [],
            doc_rg_verso: [],
            doc_cnh_frente: [],
            doc_cnh_verso: [],
            doc_comprovante_renda: [],
            doc_comprovante_endereco: [],
          }
        },
        pessoaJuridica: {
          id:"",
          pessoa_id: "",
          razaoSocial: "",
          nomeFantasia: "",
          cnpj: "",
          inscricaoEstadual: "",
          dataFundacao: "",
          nome_responsavel: ""
        },
        enderecos: [],
        emails: [],
        contatos: [],
        contrato: {
          id: "",
          data_ini_vig: new Date(),
          data_fim_vig: "",
          codigo: "",
          tempo_contrato: "",
          unidade_tempo: "",
          cliente_id: "",
          unidade_id: "",
          usuario_id: "",
          situacao: "AGUARDANDO_APROVACAO",
          produtos: [],
          template_versao_id: "",
          assinar_contrato: false,
          cobrar_multa: false,
          agrupar_vencidas: false,
          tipo_multa: '',
          valor_multa: real(0),
          bonus_pont: false,
          porc_bonus_pont: '0'
        }
      }

      let responsavel = {
        id: "",
        tipoPessoa: 'FISICA',
        pessoaFisica: {
          id: "",
          pessoa_id: "",
          nome: "",
          sobrenome:"",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: "",
          maiorIdade: true,
          dadosComplementar: {
            sexo: '',
            num_calcado: '',
            receber_novidades: '',
            quantidade_filhos: '',
            profissao: '',
            data_casamento: '',
            rg: '',
            rg_orgao_emissor: '',
            rg_uf: '',
            rg_data_emissao: '',
            estado_civil: '',
            como_conheceu_id: '',
            colegio: '',
            nome_pai: '',
            nome_mae: '',
            cnh_cliente: '',
            nome_conjugue: '',
            cpf_conjugue: '',
            rg_conjugue: '',
            rg_orgao_emissor_conjugue: '',
            rg_data_emissao_conjugue: '',
            data_nascimento_conjugue: '',
            email_conjugue: '',
            telefone_conjugue: '',
          },
          files: {
            doc_rg_frente: [],
            doc_rg_verso: [],
            doc_cnh_frente: [],
            doc_cnh_verso: [],
            doc_comprovante_renda: [],
            doc_comprovante_endereco: [],
          }
        },
        pessoaJuridica: {
          id:"",
          pessoa_id: "",
          razaoSocial: "",
          nomeFantasia: "",
          cnpj: "",
          inscricaoEstadual: "",
          dataFundacao: "",
          nome_responsavel: "",
        },
        enderecos: [],
        emails: [],
        contatos: [],
      }

      try {
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
          }
        }))

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        let unidade_aux = unidades.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0)).filter(param => (param.status === true && (param.tipo === 'UNIDADE' || param.tipo === "MANTENEDOR")))

        setState(prevState => ({...prevState,
          unidades: {
            list: unidade_aux
          }
        }))
        
        if(unidade_aux.length === 1){          
          cliente.contrato.unidade_id = unidades[0].id

          await consultaMetodosDivugacao(unidades[0].id)
          await getDadoComplementarUnidade(unidades[0].id)
          await getConfiguracaoUnidade(unidades[0].id)
          await consultaProdutos(unidades[0].id)
          await consultarContasBancarias(unidades[0].id)

          setState(prevState => ({...prevState, cliente}))
        }

        if (clienteId) {
          const data = await getDadosCliente()

          cliente = data.cliente
          responsavel = data.responsavel
        }  
        setState(prevState => ({...prevState,
          cliente,
          responsavel,
          loading: false
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    };
    
    fetchData();
  }, []);

  const { cliente, responsavel, permissoesAprovar, endereco, email, enderecoResponsavel, contatoResponsavel, 
    emailResponsavel, contato, metodos_divugacoes, dadosComplementaresPF, template_documentos } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={12}>
                <h1 className="titulo">Cadastro de Clientes</h1>
              </Grid>
            </Grid>

            {state.enableDadosPessoa && 
              <React.Fragment>
                <Grid container direction="row">
                  <Grid item md={12}>
                    <h5 className="mg_top_20">Informar tipo do cadastro:</h5>
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={2}>
                    <Button
                      fullWidth 
                      color={cliente.flags.includes('CLIENTE') ? 'primary' : 'inherit'} 
                      variant="contained" 
                      size="small" 
                      endIcon={cliente.flags.includes('CLIENTE') ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
                      onClick={e => addFlag('CLIENTE')}
                    >
                      Cliente
                    </Button>
                  </Grid>
                  <Grid item md={2}>
                    <Button 
                      fullWidth 
                      color={cliente.flags.includes('FORNECEDOR') ? 'secondary' : 'inherit'} 
                      variant="contained" 
                      size="small" 
                      endIcon={cliente.flags.includes('FORNECEDOR') ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
                      onClick={e => addFlag('FORNECEDOR')}
                    >
                      Fornecedor(a)
                    </Button>
                  </Grid>
                  <Grid item md={2}>
                    <Button 
                      fullWidth 
                      color={cliente.flags.includes('REVENDEDOR') ? 'warning' : 'inherit'} 
                      variant="contained" 
                      size="small" 
                      endIcon={cliente.flags.includes('REVENDEDOR') ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
                      onClick={e => addFlag('REVENDEDOR')}
                    >
                      Revendedor(a)
                    </Button>
                  </Grid>
                  {state.tipo !== "UNIDADE" &&
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Unidade"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="unidade_id"
                        value={cliente.contrato.unidade_id}
                        onChangeCapture={(e) => updateFieldContrato(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Selecionar Unidade</option>
                        {state.unidades.list.map(unidade => {
                          return (
                            <option key={unidade.id} value={unidade.id}> {unidade.numero + ' - ' + unidade.descricao} </option>
                          )
                        })}
                      </TextField>
                    </Grid>
                  }
                </Grid>

                <br />
                <hr />

                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Pessoa*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoPessoa"
                      value={cliente.tipoPessoa}
                      onChangeCapture={(e) => updateField(e)}
                    >
                      <option value="FISICA">
                        Pessoa Física
                      </option>
                      <option value="JURIDICA">
                        Pessoa Júridica
                      </option>
                    </TextField>
                  </Grid>
                  {cliente.flags.includes('CLIENTE') &&
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Codigo do Cliente" variant="outlined" size="small" name="codigo" value={cliente.codigo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                  }
                  <Grid item md={3} xs={12} sm={6}>
                    <FormControl component="fieldset" style={{marginLeft: 10}}>
                      <FormLabel component="legend">Ativo?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="aula_ativo"
                            color="primary"
                            checked={cliente.ativo}
                          />
                        }
                        size="small"
                        label={cliente.ativo ? 'Ativo' : 'Inativo'}
                        name="aula_ativo"
                        onChange={(e) => updateFieldAtivo(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <FormPessoa
                  pessoa={cliente}
                  informarEndereco={state.informarEndereco}
                  endereco={endereco}
                  informarContato={state.informarContato}
                  contato={contato}
                  informarEmail={state.informarEmail}
                  email={email}
                  updateFieldPessoaFisica={(e) => updateFieldPessoaFisica(e)}
                  validarCpfCnpj={(e) => validarCpfCnpj(e)}
                  updateFieldPessoaJuridica={(e) => updateFieldPessoaJuridica(e)}
                  dadosComplementaresPF={dadosComplementaresPF}
                  metodos_divugacoes={metodos_divugacoes.list}
                  informarNovoEndereco={() => informarEndereco()}
                  pegaEndereco={e => pegaEndereco(e)}
                  updateFieldEndereco={e => updateFieldEndereco(e)} 
                  updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipal(e)} 
                  addNovoEndereco={(e) => addNovoEndereco(e)}
                  cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                  editarEndereco={(e) => editarEndereco(e)}
                  removeEndereco={(e) => removeEnderecoCliente(e)}
                  informarNovoContato={() => informarContato()}
                  formataTelefone={e => formataTelefoneCliente(e)}  
                  updateFieldContato={e => updateFieldContato(e)} 
                  updateFieldContatoPrincipal={e => updateFieldContatoPrincipal(e)} 
                  addNovoContato={() => addNovoContato()}
                  editarContato={(e) => editarContato(e)}
                  removeContato={(e) => removeContatoCliente(e)}
                  informarNovoEmail={() => informarEmail()}
                  updateFieldEmail={e => updateFieldEmail(e)} 
                  updateFieldEmailPrincipal={e => updateFieldEmailPrincipal(e)}
                  addNovoEmail={() => addNovoEmail()}
                  editarEmail={(e) => editarEmail(e)}
                  removeEmail={(index) => removeEmailCliente(index)}
                  acoesTabelaEndereco={state.acoesTabelaEndereco}
                  cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                  cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                  updateFieldDadosComplementar={e => updateFieldDadosComplementarPF(e)}
                  validarCpfCnpjConjugue={(e) => validarCpfCnpjConjugue(e)}
                  handleUpload={(e, name) => handleUpload(e, name)}
                />

                <br />
                <hr />

                <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                  <Grid item md={2} xs={12} sm={3}>
                    <Link to={criaUrl()}>
                      <Button color='warning' fullWidth size='small' variant="contained" startIcon={<ArrowBackIcon />}>
                        Voltar Listagem
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item md={6} xs={false} sm={3}></Grid>
                  {!state.edit &&
                    <Grid item md={2} xs={12} sm={3}>
                      <Button color='success' fullWidth onClick={() => informarContrato()} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Informar Contrato</Button>
                    </Grid>
                  }
                  {state.edit &&
                    <Grid item md={2} xs={12} sm={3}></Grid>
                  }
                  <Grid item md={2} xs={12} sm={3}>
                    <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => salvar(true, state.statusAtivoPessoa)} disabled={state.loading}>Salvar</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            
            {state.enableDadosContrato &&
              <React.Fragment>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <h5 className="mg_top_10">Dados do Contrato do Cliente:</h5>
                  </Grid>
                </Grid>

                <FormContratoCliente
                  unidades={state.unidades.list}
                  unidade={state.unidade}
                  updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
                  updateField={e => updateFieldContrato(e)}
                  dados={cliente.contrato}
                  cliente={cliente}
                  maiorIdade={cliente.maiorIdade}
                  tipo={state.tipo}
                  updateFieldMaiorMenor={e => updateFieldMaiorMenor(e)}
                  calculaIdade={e => calculaIdade(e)}
                  pessoaFisica={cliente.pessoaFisica}
                  permissoesAprovar={permissoesAprovar}
                  verifyFinishedDate={e => verifyFinishedDate(e)}
                  travaUnidade={state.habilitaDocumento}
                  produtos={state.produtos.list}
                  servico={state.servico}
                  addServico={e => addServico(e)}
                  updateServico={e => updateServico(e)}
                  removerServico={e => removerServico(e)}
                  formasPagamento={state.formasPagamento.list}
                  contasFinanceira={state.contasFinanceira.list}
                  editarServico={e => editarServico(e)}
                  alteraServico={state.alteraServico}
                  produtoSelecionado={state.produtoSelecionado}
                  possui_emissao_nfse={state.possui_emissao_nfse}
                  updateFieldGerarNfse={() => updateFieldGerarNfse()}
                  updateRecorrencia={(e) => updateRecorrencia(e) }
                  updateParcelado={(e) => updateParcelado(e) }
                  conta={state.conta}
                  recalcularParcela={(e, value) => recalcularParcela(e, value)}
                  mudarParcelas={e => mudarParcelas(e)}
                  updateFieldConta={e => updateFieldConta(e)}
                  updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
                  marcaParcelas={(e, index) => marcaParcelas(e, index)}
                  marcaParcelasContaFin={(e, index) => marcaParcelasContaFin(e, index)}
                  template_documentos={template_documentos.list}
                  updateFieldAssinar={() => updateFieldAssinar()}
                  updateFieldCobrarMulta={() => updateFieldCobrarMulta()}
                  updateFieldAgruparVencidas={() => updateFieldAgruparVencidas()}
                  updateFieldBonusPontualidade={() => updateFieldBonusPontualidade()}
                  verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
                />
                <br />
                <hr />

                <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                  <Grid item md={2}>
                    <Button color='success' fullWidth onClick={() => voltarDadosPessoa()} variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                  </Grid>
                  <Grid item md={4}></Grid>
                  {!state.habilitaDocumento &&
                    <Grid item md={3}></Grid>
                  }
                  {state.habilitaDocumento &&
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => imprimirContrato()} color="secondary" variant="contained" size="small" startIcon={<PrintIcon />}>Imprimir Contrato</Button>
                    </Grid>
                  }
                  {!cliente.maiorIdade &&
                    <Grid item md={3}>
                      <Button fullWidth color="primary" onClick={() => proximo("enableDadosContrato", "enableDadosPessoaResponsavel")} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Informar Responsável</Button>
                    </Grid>
                  }
                  {cliente.maiorIdade &&
                    <React.Fragment>
                      <Grid item md={1}></Grid>
                      <Grid item md={2}>
                        <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => salvar(true, state.statusAtivoPessoa)} disabled={state.loading}>Salvar</Button>
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
              </React.Fragment>
            }

            {state.enableDadosPessoaResponsavel &&
              <div className="formFranqueado">
                <Grid container direction="row">
                  <Grid item md={12}>
                    <h5 className="mg_top_20">Dados do Responsável Financeiro</h5>
                  </Grid>
                </Grid>

                {state.enableBuscaResp &&
                  <React.Fragment>
                    <Grid container direction="row" className="mg_top_10">
                      <Grid item md={12}>
                        <p style={{margin:'0'}}>Por favor informar o CPF/CNPJ do responsável</p>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={3} xs={12} sm={6}>
                        <CPForCNPJInput 
                          label="CPF/CNPJ" 
                          name="cpf" 
                          value={state.searchResp.cpf} 
                          onChange={(e) => updateFieldSearchResp(e)}
                          size="small"
                        />                      
                      </Grid>
                      <Grid item md={2} xs={12} sm={3}>
                        <Button fullWidth onClick={() => buscarResp()} color="primary" variant="contained">Buscar</Button>
                      </Grid>
                    </Grid>
                    <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                      {cliente.contrato.situacao === 'AGUARDANDO_APROVACAO' &&
                        <Grid item md={2}>
                          <Button color='success' fullWidth onClick={() => voltar("enableDadosPessoaResponsavel", "enableDadosContrato")} variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                      }
                    </Grid>
                  </React.Fragment>
                }

                {!state.enableBuscaResp &&
                  <React.Fragment>

                    <FormPessoa
                      pessoa={responsavel}
                      informarEndereco={state.informarEnderecoResponsavel}
                      endereco={enderecoResponsavel}
                      informarContato={state.informarContatoResponsavel}
                      contato={contatoResponsavel}
                      informarEmail={state.informarEmailResponsavel}
                      email={emailResponsavel}
                      updateFieldPessoaFisica={(e) => updateFieldPessoaFisicaResponsavel(e)}
                      validarCpfCnpj={(e) => validarCpfCnpjResponsavel(e)}
                      updateFieldPessoaJuridica={(e) => updateFieldPessoaJuridicaResponsavel(e)}
                      dadosComplementaresPF={dadosComplementaresPF}
                      metodos_divugacoes={metodos_divugacoes.list}
                      informarNovoEndereco={() => informarEnderecoResponsavel()}
                      pegaEndereco={e => pegaEnderecoResponsavel(e)}
                      updateFieldEndereco={e => updateFieldEnderecoResponsavel(e)} 
                      updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipalResponsavel(e)} 
                      addNovoEndereco={(e) => addNovoEnderecoResponsavel(e)}
                      cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                      editarEndereco={(e) => editarEnderecoResponsavel(e)}
                      removeEndereco={(e) => removeEnderecoResponsavel(e)}
                      informarNovoContato={() => informarContatoResponsavel()}
                      formataTelefone={e => formataTelefoneResponsavel(e)}  
                      updateFieldContato={e => updateFieldContatoResponsavel(e)} 
                      updateFieldContatoPrincipal={e => updateFieldContatoPrincipalResponsavel(e)} 
                      addNovoContato={() => addNovoContatoResponsavel()}
                      editarContato={(e) => editarContatoResponsavel(e)}
                      removeContato={(e) => removeContatoResponsavel(e)}
                      informarNovoEmail={() => informarEmailResponsavel()}
                      updateFieldEmail={e => updateFieldEmailResponsavel(e)} 
                      updateFieldEmailPrincipal={e => updateFieldEmailPrincipalResponsavel(e)}
                      addNovoEmail={() => addNovoEmailResponsavel()}
                      editarEmail={(e) => editarEmailResponsavel(e)}
                      removeEmail={(index) => removeEmailResponsavel(index)}
                      acoesTabelaEndereco={state.acoesTabelaEndereco}
                      cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                      cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                      updateFieldDadosComplementar={e => updateFieldDadosComplementarPFResponsavel(e)}
                    />

                    <br />
                    <hr />
                    
                    <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                      {state.habilitaDocumento &&
                        <React.Fragment>
                          <Grid item md={3}></Grid>
                          <Grid item md={2}>
                            <Button fullWidth onClick={() => imprimirContrato()} color="secondary" variant="contained" size="small" startIcon={<PrintIcon />}>Imprimir Contrato</Button>
                          </Grid>
                        </React.Fragment>
                      }

                      {!state.habilitaDocumento &&
                        <Grid item md={5}></Grid>
                      }
                      <Grid item md={3}></Grid>
                      <Grid item md={2}>
                        <Button color='success' fullWidth onClick={() => voltarBuscaResp()} variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                      </Grid>
                      <Grid item md={2}>
                        <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => salvar(true, state.statusAtivoPessoa)} disabled={state.loading}>Salvar</Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }

              </div>
            }
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Cliente"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
      <ModalConfirmacao 
        open={state.modalConfirmacaoOpen} 
        dados={state.modalConfirmacao} 
        handleClose={e => handleCloseConfirmacao(e)} 
        confirmar={e => confirmarSalvamento(e)} 
      />
      <ModalConfirmacaoInativa
        open={state.modalConfirmacaoInativaOpen} 
        dados={state.modalConfirmacao} 
        handleClose={e => handleCloseInativacao(e)} 
        confirmar={e => confirmarInativacao(e)} 
      />
    </React.Fragment>
  )
}

export default CadastroCliente
