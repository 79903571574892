import React, { useState, useEffect } from 'react';
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../../components/templates/Alert'
import ImageIcon from '@mui/icons-material/Image';
import ModalSelecionarLogo from '../../../components/modals/SelecionarLogo';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { EditorState, convertToRaw, ContentState } from "draft-js"
import Table from './../../../components/Table'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import { useNavigate } from 'react-router-dom';

const initialState = {
  unidade: {},
  loading: true,
  configuracao: {
    id: '',
    unidade_id: '',
    fuso: '',
    nome_fuso: '',
    intervalo_agenda: 0,
    inicio_atendimento: '',
    fim_atendimento: '',
    momento_comissao: '',
    item_despesa_troco_id: '',
    midia_log_id: '',
    exige_solic_abertura_caixa: false,
    perm_notificacao_agenda: false,
    perm_alt_voucher_agenda: false,
    item_recdesp_cortesia_id: '',
    item_recdesp_desc_concedido_id: '',
    item_recdesp_desc_obitido_id: '',
    item_recdesp_multa_recebida_id: '',
    item_recdesp_multa_paga_id: '',
    item_recdesp_taxa_pix_boleto: '',
    mensagem_boas_vindas: '',
    perm_voucher_sem_fin: false,
    perm_agenda_sem_atend: false,
    requer_atendente_agenda: false,
    exibir_preparo_limpeza_agenda: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loadingSalvar: false,
  itens: {
    list: []
  },
  openModalSelecionarLogo: false,
  logo_unidade:{
    id: '',
    key: ''
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  desabilitarCampos: false,
  tab: 0,
  filtro: {
    descricao: ''
  },
  templateSelecionado: {
    id: '',
    descricao: '',
    tipo: '',
    variaveis: []
  },
  variaveisFiltrada:{
    list: []
  },
  templates: {
    list: []
  },
  editorState: EditorState.createEmpty(),
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'variavel', numeric: false, disablePadding: true, label: 'Variavel' }
  ],
  acoesTabela: ['selectVar']
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

const Configuracao = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultarItens = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados, getToken())

      const itens = []

      data.sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0).forEach(item => {
        itens.push({
          id: parseInt(item.id),
          descricao: item.descricao,
          planocontas: item.plano_contas.codigo + ' - ' + item.plano_contas.descricao,
          sequencia: item.plano_contas.sequencia,
          tipo: item.plano_contas.tipo === 'DESPESA' ? 'PAGAR' : 'RECEBER',
          planocontas_id: item.plano_contas.id,
          codigo: item.plano_contas.codigo,
          unidade_id: item.plano_contas.unidade_id,
          ativo: item.ativo
        })
      })

      setState(prevState => ({...prevState,
        itens: {
          list: itens
        },
        loading: false
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      voltar();
    }, timeout)
  }

  const updateField = (event) => {
    const { configuracao } = state

    configuracao[event.target.name] = event.target.value

    setState(prevState => ({...prevState, configuracao }))
  }

  const voltar = () => {
    navigate("/unidade");
    return;
  }

  const validateForm = () => {
    // const { configuracao } = state

    // if (!configuracao.fuso) return false
    // if (!configuracao.nome_fuso) return false

    
    return true
  }

  const salvar = async () => {

    setState(prevState => ({...prevState, loadingSalvar: true }))

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        loadingSalvar: false,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    try {
      const { configuracao } = state

      let dados = {
        unidade_id: configuracao.unidade_id,
        fuso: configuracao.fuso,
        nome_fuso: configuracao.nome_fuso,
        intervalo_agenda: parseInt(configuracao.intervalo_agenda),
        inicio_atendimento: configuracao.inicio_atendimento,
        fim_atendimento: configuracao.fim_atendimento,
        momento_comissao: configuracao.momento_comissao,
        item_despesa_troco_id: configuracao.item_despesa_troco_id,
        midia_logo_id: configuracao.midia_logo_id,
        exige_solic_abertura_caixa: configuracao.exige_solic_abertura_caixa,
        item_recdesp_cortesia_id: configuracao.item_recdesp_cortesia_id,
        perm_notificacao_agenda: configuracao.perm_notificacao_agenda,
        perm_alt_voucher_agenda: configuracao.perm_alt_voucher_agenda,
        item_recdesp_desc_concedido_id: configuracao.item_recdesp_desc_concedido_id,
        item_recdesp_desc_obitido_id: configuracao.item_recdesp_desc_obitido_id,
        item_recdesp_multa_recebida_id: configuracao.item_recdesp_multa_recebida_id,
        item_recdesp_multa_paga_id: configuracao.item_recdesp_multa_paga_id,
        item_recdesp_taxa_pix_boleto: configuracao.item_recdesp_taxa_pix_boleto,
        mensagem_boas_vindas: configuracao.mensagem_boas_vindas,
        perm_voucher_sem_fin: configuracao.perm_voucher_sem_fin,
        perm_agenda_sem_atend: configuracao.perm_agenda_sem_atend,
        requer_atendente_agenda: configuracao.requer_atendente_agenda,
        exibir_preparo_limpeza_agenda: configuracao.exibir_preparo_limpeza_agenda
      }

      if(dados.fuso === '') delete dados.fuso
      if(dados.intervalo_agenda === '') delete dados.intervalo_agenda
      if(dados.inicio_atendimento === '') delete dados.inicio_atendimento
      if(dados.fim_atendimento === '') delete dados.fim_atendimento
      if(dados.momento_comissao === '') delete dados.momento_comissao
      if(dados.item_despesa_troco_id === '') delete dados.item_despesa_troco_id
      if(dados.midia_logo_id === '') delete dados.midia_logo_id
      if(dados.item_recdesp_desc_concedido_id === '') delete dados.item_recdesp_desc_concedido_id
      if(dados.item_recdesp_cortesia_id === '') delete dados.item_recdesp_cortesia_id
      if(dados.item_recdesp_desc_obitido_id === '') delete dados.item_recdesp_desc_obitido_id
      if(dados.item_recdesp_multa_recebida_id === '') delete dados.item_recdesp_multa_recebida_id
      if(dados.item_recdesp_multa_paga_id === '') delete dados.item_recdesp_multa_paga_id
      if(dados.item_recdesp_taxa_pix_boleto === '') delete dados.item_recdesp_taxa_pix_boleto
      if(dados.mensagem_boas_vindas === '') delete dados.mensagem_boas_vindas

      if (configuracao.id !== "") {
        await axios.put(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/${configuracao.unidade_id}`, dados, getToken())
      } else {
        
        await axios.post(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade`, dados, getToken())
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

      setState(prevState => ({...prevState,loadingSalvar: false}))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        loadingSalvar: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const openModalSelecionarLogo = () => {
    setState(prevState => ({...prevState,
      openModalSelecionarLogo: true
    }))
  }

  const handleCloseModalSelecionarLogo = () => {
    setState(prevState => ({...prevState,
      openModalSelecionarLogo: false
    }))
  }

  const selecionarLogo = (img) => {
    const {configuracao} = state

    configuracao.midia_logo_id = img.midia_id

    setState(prevState => ({...prevState,
      configuracao,
      logo_unidade:{
        id: img.midia_id,
        key: img.midia.key
      }
    }))

    handleCloseModalSelecionarLogo()
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const updateFiltro = (event) => {
    setState(prevState => ({...prevState, term: event.target.value }))
    const variaveis = state.templateSelecionado.variaveis
    const filtro = state.filtro

    const list = variaveis.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      variaveisFiltrada: list,
      filtro
    }))
  }

  const selecionarVar = (variavel) => {
    let configuracao = { ...state.configuracao }

    configuracao.mensagem_boas_vindas = configuracao.mensagem_boas_vindas.replace('{%}', `%%${variavel.variavel}`)

    const contentBlock = htmlToDraft(configuracao.mensagem_boas_vindas);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setState(prevState => ({...prevState, configuracao, editorState }))
  }

  const onEditorStateChange = (editorState) => {
    const {configuracao } = state

    configuracao.mensagem_boas_vindas = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    setState(prevState => ({...prevState,editorState, configuracao}))
  } 

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'unidade')[0]
    
      if(permissoes){
        setState(prevState => ({...prevState,
          permissoes
        }))

        if(!permissoes.alterar && !permissoes.inserir){
          setState(prevState => ({...prevState,
            desabilitarCampos: true
          }))
        }
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

      const unidade = props.unidade

      await consultarItens(unidade.id)

      try {

        const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade.id}`, getToken())

        if (configuracao) {
          setState(prevState => ({...prevState,
            configuracao: {
              id: configuracao.id || '',
              unidade_id: configuracao.unidade_id || '',
              fuso: configuracao.fuso || '',
              nome_fuso: configuracao.nome_fuso || '',
              intervalo_agenda: configuracao.intervalo_agenda || '',
              inicio_atendimento: configuracao.inicio_atendimento || '',
              fim_atendimento: configuracao.fim_atendimento || '',
              momento_comissao: configuracao.momento_comissao || '',
              item_despesa_troco_id: configuracao.item_despesa_troco_id || '',
              midia_logo_id: configuracao.midia_logo_id || '',
              exige_solic_abertura_caixa: configuracao.exige_solic_abertura_caixa || false,
              perm_notificacao_agenda: configuracao.perm_notificacao_agenda || false,
              perm_alt_voucher_agenda: configuracao.perm_alt_voucher_agenda || false,
              item_recdesp_cortesia_id: configuracao.item_recdesp_cortesia_id || '',
              item_recdesp_desc_concedido_id: configuracao.item_recdesp_desc_concedido_id || '',
              item_recdesp_desc_obitido_id: configuracao.item_recdesp_desc_obitido_id || '',
              item_recdesp_multa_recebida_id: configuracao.item_recdesp_multa_recebida_id || '',
              item_recdesp_multa_paga_id: configuracao.item_recdesp_multa_paga_id || '',
              item_recdesp_taxa_pix_boleto: configuracao.item_recdesp_taxa_pix_boleto || '',
              mensagem_boas_vindas: configuracao.mensagem_boas_vindas || '',
              perm_voucher_sem_fin: configuracao.perm_voucher_sem_fin,
              perm_agenda_sem_atend: configuracao.perm_agenda_sem_atend,
              requer_atendente_agenda: configuracao.requer_atendente_agenda,
              exibir_preparo_limpeza_agenda: configuracao.exibir_preparo_limpeza_agenda
            }
          }))


          if(configuracao.mensagem_boas_vindas){
            const contentBlock = htmlToDraft(configuracao.mensagem_boas_vindas);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            setState(prevState => ({...prevState,
              editorState
            }))
          }

          if(configuracao.midia_logo_id){
            setState(prevState => ({...prevState,
              logo_unidade:{
                id: configuracao.midia.id,
                key: configuracao.midia.key
              }
            }))
          }
        }else{
          setState(prevState => ({...prevState,
            configuracao: {
              id: '',
              unidade_id: unidade.id,
              fuso: '',
              nome_fuso: '',
              intervalo_agenda: 0,
              inicio_atendimento: '',
              fim_atendimento: '',
              momento_comissao: '',
              item_despesa_troco_id: '',
              midia_log_id: '',
              exige_solic_abertura_caixa: false,
              perm_notificacao_agenda: false,
              perm_alt_voucher_agenda: false,
              item_recdesp_cortesia_id: '',
              item_recdesp_desc_concedido_id: '',
              item_recdesp_desc_obitido_id: '',
              item_recdesp_multa_recebida_id: '',
              item_recdesp_multa_paga_id: '',
              item_recdesp_taxa_pix_boleto: '',
              mensagem_boas_vindas: '',
              perm_voucher_sem_fin: false,
              perm_agenda_sem_atend: false,
              requer_atendente_agenda: false,
              exibir_preparo_limpeza_agenda: false
            },
          }))
        }

        const { data: templates } = await axios.get(`${window._env_.REACT_APP_API_URL}/templatesvariaveis`, getToken())

        setState(prevState => ({...prevState,
          templates:{
            list: templates.filter(param => param.tipo === 'BOAS_VINDAS')
          },
          variaveisFiltrada:{
            list: templates.filter(param => param.tipo === 'BOAS_VINDAS')[0].variaveis
          },
          templateSelecionado: templates.filter(param => param.tipo === 'BOAS_VINDAS')[0]
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState,
        unin_tipo,
        unidade: unidade,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { configuracao, itens, logo_unidade, tab, desabilitarCampos, filtro, variaveisFiltrada, editorState } = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Dados da Configuração...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h1 className="titulo">Configurações da Unidade: </h1>
            </Grid>
          </Grid>

          <AppBar position="static" className="mg_top_10"> 
            <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Agenda" {...a11yProps(0)} />
              <Tab label="Financeiro" {...a11yProps(1)} />
              <Tab label="Unidade" {...a11yProps(2)} />
              <Tab label="Notificação" {...a11yProps(3)} />
              <Tab label="Vouchers" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tab} index={0}>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              {/* <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Fuso" 
                  variant="outlined" 
                  size="small" 
                  name="fuso" 
                  value={configuracao.fuso} 
                  onChange={(e) => updateField(e)} 
                  InputLabelProps={{ shrink: true }} 
                  disabled={desabilitarCampos}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Nome Fuso" 
                  variant="outlined" 
                  size="small" 
                  name="nome_fuso" 
                  value={configuracao.nome_fuso} 
                  onChange={(e) => updateField(e)} 
                  InputLabelProps={{ shrink: true }} 
                  disabled={desabilitarCampos}
                />
              </Grid> */}
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Intervalo da Agenda"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="intervalo_agenda"
                  value={configuracao.intervalo_agenda}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Intervalo </option>
                  <option value={5}> 5 Minutos </option>
                  <option value={10}> 10 Minutos </option>
                  <option value={15}> 15 Minutos </option>
                  <option value={30}> 30 Minutos </option>
                  <option value={60}> 60 Minutos </option>
                </TextField>
                {/* <TextField type='number' className="input" label="Intervalo da Agenda (Min)" variant="outlined" size="small" name="intervalo_agenda" value={configuracao.intervalo_agenda} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} /> */}
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  type='time' 
                  className="input" 
                  label="Inicio do Atendimento" 
                  variant="outlined" 
                  size="small" 
                  name="inicio_atendimento" 
                  value={configuracao.inicio_atendimento} 
                  onChange={(e) => updateField(e)} 
                  InputLabelProps={{ shrink: true }} 
                  disabled={desabilitarCampos}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  type='time' 
                  className="input" 
                  label="Fim do Atendimento" 
                  variant="outlined" 
                  size="small" 
                  name="fim_atendimento" 
                  value={configuracao.fim_atendimento} 
                  onChange={(e) => updateField(e)} 
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Momento da Comissão"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="momento_comissao"
                  value={configuracao.momento_comissao}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Sem Comissão </option>
                  <option value={'PAGAMENTO'}> No Pagamento </option>
                  <option value={'ATENDIMENTO'}> Finalizar Atendimento </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Perm. a Agenda sem Atend."
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="perm_agenda_sem_atend"
                  value={configuracao.perm_agenda_sem_atend}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value={true}> Sim </option>
                  <option value={false}> Não </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Perm. Notif. na Agenda"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="perm_notificacao_agenda"
                  value={configuracao.perm_notificacao_agenda}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value={true}> Sim </option>
                  <option value={false}> Não </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Perm. Alt. do Voucher na Agenda"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="perm_alt_voucher_agenda"
                  value={configuracao.perm_alt_voucher_agenda}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value={true}> Sim </option>
                  <option value={false}> Não </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Exibir Opção Prep. e Limp."
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="exibir_preparo_limpeza_agenda"
                  value={configuracao.exibir_preparo_limpeza_agenda}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value={true}> Sim </option>
                  <option value={false}> Não </option>
                </TextField>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Exigir Solic. Abertura de Caixa"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="exige_solic_abertura_caixa"
                  value={configuracao.exige_solic_abertura_caixa}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value={true}> Sim </option>
                  <option value={false}> Não </option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Item Cortesia"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="item_recdesp_cortesia_id"
                  value={configuracao.item_recdesp_cortesia_id}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Item </option>
                  {itens.list.map(item => {
                    return (
                      <option value={item.id}>{item.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Item de Despesa Desconto Concedido"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="item_recdesp_desc_concedido_id"
                  value={configuracao.item_recdesp_desc_concedido_id}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Item </option>
                  {itens.list.filter(param => param.tipo === 'PAGAR').map(item => {
                    return (
                      <option value={item.id}>{item.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Item de Receita Desconto Obitido"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="item_recdesp_desc_obitido_id"
                  value={configuracao.item_recdesp_desc_obitido_id}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Item </option>
                  {itens.list.filter(param => param.tipo === 'RECEBER').map(item => {
                    return (
                      <option value={item.id}>{item.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Item de Receita Multa Recebida"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="item_recdesp_multa_recebida_id"
                  value={configuracao.item_recdesp_multa_recebida_id}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Item </option>
                  {itens.list.filter(param => param.tipo === 'RECEBER').map(item => {
                    return (
                      <option value={item.id}>{item.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Item de Despesa Multa Paga"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="item_recdesp_multa_paga_id"
                  value={configuracao.item_recdesp_multa_paga_id}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Item </option>
                  {itens.list.filter(param => param.tipo === 'PAGAR').map(item => {
                    return (
                      <option value={item.id}>{item.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Item de Despesa Taxa Boleto/Pix"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="item_recdesp_taxa_pix_boleto"
                  value={configuracao.item_recdesp_taxa_pix_boleto}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value=''> Selecionar Item </option>
                  {itens.list.filter(param => param.tipo === 'PAGAR').map(item => {
                    return (
                      <option value={item.id}>{item.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className='titulo'>Logo da Unidade:</h4>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              {configuracao.midia_logo_id &&
                <Grid item md={2} xs={12} sm={4}>
                  <img src={`${window._env_.REACT_APP_API_URL}/static/${logo_unidade.key}`} alt="Imagem" /> 
                </Grid>
              }
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={4}>
                <Button 
                  fullWidth 
                  color="primary" 
                  variant="contained" 
                  size="small" 
                  startIcon={<ImageIcon />}
                  onClick={() => openModalSelecionarLogo()}
                >
                  {configuracao.midia_logo_id ? 'Alterar Logo': 'Selecionar Logo'}
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h5 className='titulo'>Mensagem de Boas Vindas:</h5>
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={9} xs={12} sm={8}></Grid>
              <Grid item md={3} xs={12} sm={4}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={6} xs={12}>
                <div className="cardEditor">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={e => onEditorStateChange(e)}
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Table
                  headCell={state.cabecalhoTabela}
                  rows={variaveisFiltrada.list}
                  acoes={state.acoesTabela}
                  selecionarVar={e => selecionarVar(e)} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Perm. a Venda de Voucher sem Financeiro"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="perm_voucher_sem_fin"
                  value={configuracao.perm_voucher_sem_fin}
                  onChangeCapture={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled={desabilitarCampos}
                >
                  <option value={true}> Sim </option>
                  <option value={false}> Não </option>
                </TextField>
              </Grid>
            </Grid>
          </TabPanel>
          <hr className="mg_top_10" />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={false} sm={4}></Grid>
            {desabilitarCampos &&
              <Grid item md={3} xs={false} sm={4}></Grid>
            }
            <Grid item md={3} xs={false} sm={4}>
              <Button fullWidth onClick={() => voltar()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
            </Grid>
            {!desabilitarCampos &&
              <Grid item md={3} xs={false} sm={4}>
                <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            }
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalSelecionarLogo 
        open={state.openModalSelecionarLogo}
        handleClose={e => handleCloseModalSelecionarLogo()} 
        imagens={props.unidade.pessoa.midias_pessoa}
        configuracao={configuracao}
        selecionarLogo={e => selecionarLogo(e)}
      />
    </React.Fragment>
  )
}

export default Configuracao