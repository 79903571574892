import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './BoletosCliente.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment';
import ConfirmarCPFCNPJ from './../../components/modals/ConfirmarCPFCNPJ'
import ModalExibeBoleto from './../../components/modals/ExibeBoleto'
import ModalViewContaCliente from './../../components/modals/ViewContaCliente'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalAnteciparParcelas from './../../components/modals/AnteciparParcelas'
import ModalQuitacao from './../../components/modals/Quitacao'
import real from '../../services/real'
import Alert from './../../components/templates/Alert'
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import icone_nfse from './logo_nfse.png'
import icone_nfse_disabled from './logo_nfse_disabled.png'
import { saveAs } from 'file-saver';
import decrypt from '../../services/decrypt'
import ModalAjuda from './../../components/modals/Ajuda'
import { styled, keyframes } from "@mui/system";

function renderClassFinanceiro(param) {
  if (param === 'Atrasado') {
    return  '#FFB6C1'
  } else if (param === 'A Pagar') {
    return  '#FFF'
  } else if (param === 'Pago') {
    return '#98FB98'
  }
}

const initialState = {
  cabecalhoTabela: [
    // { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'data_vencimento', numeric: false, disablePadding: true, label: 'Data Vencimento', orderBy: 'data_vencimentoOrder' },
    // { id: 'tipo_conta', numeric: false, disablePadding: true, label: 'Tipo Conta' },
    // { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma Pagto.' },
    // { id: 'valor', numeric: false, disablePadding: true, label: 'Valor' },
    // { id: 'multajuros', numeric: false, disablePadding: true, label: 'Multa / Juros / Desc.' },
    { id: 'valor_total_form', numeric: true, disablePadding: true, label: 'Valor Total' },
    // { id: 'qtd_itens', numeric: true, disablePadding: true, label: 'Qtd. Itens' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' }
  ],
  acoesTabela: ['openConta', 'imprimirBoletoCliente'],
  contas: {
    list: []
  },
  contas_abertas: {
    list: []
  },
  openModalConfirmar: false,
  login: {
    cpf_cnpj: ''
  },
  pessoa: {
    tipo: 'FISICA',
    fisica: {
      nome: ''
    },
    unidade_pertence: {
      descricao: ''
    }
  },
  mostrarBoletos: false,
  loading: false,
  openModalExibeBoleto: false,
  boletoSelecionado: {
    tipo_transaction: 'BOLETO',
    bank_slip: {
      url_slip_pdf: ''
    }
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  filtro: {
    data_inicial: "",
    data_final: "",
    situacao: "A VENCER E VENCIDO",
    data_por: "VENCIMENTO",
  },
  parcela: {
    baixado: "",
    datavencimento: "",
    valorparcela: '0,00',
    documento: "",
    num: "",
    motivo_cancelamento: "",
    parcela_editada: false,
    multajuros: '0,00',
    formas_pgto: [
      {
        id: '',
        formapagto_id: '',
        valor_pagamento: real(0),
        formapagto_descricao: '',
        formapagto_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        index: 0
      }
    ]
  },
  openModalViewContaCliente: false,
  dataSelecionada: {
    parcelas: []
  },
  row_selecionado: null,
  modal: {
    mensagem: '',
  },
  modalOpen: false,
  tabela_mes: [
    {
      numero: '01',
      mes: 'Janeiro'
    },
    {
      numero: '02',
      mes: 'Fevereiro'
    },
    {
      numero: '03',
      mes: 'Março'
    },
    {
      numero: '04',
      mes: 'Abril'
    },
    {
      numero: '05',
      mes: 'Maio'
    },
    {
      numero: '06',
      mes: 'Junho'
    },
    {
      numero: '07',
      mes: 'Julho'
    },
    {
      numero: '08',
      mes: 'Agosto'
    },
    {
      numero: '09',
      mes: 'Setembro'
    },
    {
      numero: '10',
      mes: 'Outubro'
    },
    {
      numero: '11',
      mes: 'Novembro'
    },
    {
      numero: '12',
      mes: 'Dezembro'
    }
  ],
  openModalAjuda: false,
  markdown: '',
  openModalAnteciparParcelas: false,
  parcelas_antecipacao: [],
  openModalQuitacao: false,
  selecionarTodos: false,
  disableFiltro: false,
  possui_antecipacao: false
}

// Definindo a animação de pulsar
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// Aplicando a animação ao ícone
const PulsingIcon = styled(PrintIcon)`
  animation: ${pulseAnimation} 1s infinite;
`;

const BoletosCliente = () => {
  const [state, setState] = useState({ ...initialState });
  const { pessoaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token_cliente')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const confirmar = async () => {
    const { login } = state

    try {
      const dados = {
        cpf_cnpj: login.cpf_cnpj,
        pessoa_id: parseInt(pessoaId)
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente/login`, dados)

      localStorage.setItem('token_cliente', data.token)

      buscarDadosPessoa()

      setState(prevState => ({...prevState,
        mostrarBoletos: true,
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const buscarDadosPessoa = async () => {
    try {
      const { data: pessoa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoaExterno/${parseInt(pessoaId)}`, getToken())

      localStorage.setItem('cliente', JSON.stringify(pessoa))

      let {filtro} = state

      if(pessoa.unidade_id === 48){
        filtro.data_inicial = '2024-09-01'
        filtro.data_final = moment().format('YYYY-MM-DD')
        setState(prevState => ({
          ...prevState,
          disableFiltro: true
        }))
      }else{
        filtro.data_inicial = moment().format('YYYY-MM')+'-01'
        filtro.data_final = moment(filtro.data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
      }
  
      filtrarContas(state.filtro)

      setState(prevState => ({...prevState,
        pessoa,
        filtro
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const filtrarContas = async (filtro) => {
    setState(prevState => ({...prevState, loading: true }))

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/list/${parseInt(pessoaId)}`, filtro, getToken())

      let resp = JSON.parse(decrypt(data))
    
      console.log(resp)

      setState(prevState => ({...prevState,
        contas: {
          list: resp.list.map((value, key) => {
            return {
              index: key,
              descricao: value.descricao,
              data_vencimento: moment(value.data_vencimento).format('DD/MM/YYYY'),
              data_vencimento_sem_form: moment(value.data_vencimento).format('YYYY-MM-DD'),
              valor: 'R$ ' + real(value.valor_total),
              multajuros: 'R$ ' + real(value.multajuros),
              valor_total:value.valor_total + value.multajuros,
              valor_total_form: 'R$ ' + real(value.valor_total + value.multajuros),
              qtd_itens: value.parcelas.length,
              responsavel: value.responsavel,
              tipo_conta: value.tipo_conta === 'RECEITA' ? 'A Pagar' : 'A Receber',
              forma_pgto_slug: value.forma_pgto_slug,
              forma_pagto: value.forma_pagto,
              status: value.status,
              boleto_transaction_id: value.boleto_transaction_id,
              boleto_link: value.boleto_link,
              tipo_transaction: value.tipo_transaction,
              boleto_digitable_line: value.boleto_digitable_line,
              parcelas: value.parcelas,
              nfse_ids: value.nfse_ids,
              parceiro_bancario_id: value.parceiro_bancario_id,
              parcela_antecipacao: value.parcela_antecipacao
            }
          })
        },
        possui_antecipacao: resp.possui_antecipacao,
        openModalConfirmar: false,
        mostrarBoletos: true,
        loading: false
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateField = (event) => {
    const { login } = state
    login[event.target.name] = event.target.value
    setState(prevState => ({...prevState, login }))
  }

  const imprimirBoleto = async (row) => {
    if(moment(row.data_vencimento_sem_form).format('MM/YYYY') !== moment().format('MM/YYYY')){
      let {tabela_mes} = state

      setState(prevState => ({...prevState,
        row_selecionado: row,
        modal: {
          mensagem: `Essa conta é referente ao mês de ${tabela_mes.filter(param => param.numero === moment(row.data_vencimento_sem_form).format('MM'))[0].mes} do ano de ${moment(row.data_vencimento_sem_form).format('YYYY')}, deseja continuar com o pagamento?`,
        },
        modalOpen: true,
      }))

    }else{
      await continuaImprimirBoletoPix(true, row)
    }    
  }

  const continuaImprimirBoletoPix = async (value, row) => {
    if(value){
      handleClose()
      let {contas} = state
  
      if(row.forma_pgto_slug === 'pix'){
  
        try {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'info',
              message: 'Carregando PIX'
            }
          }))
  
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pix/atualiza/${row.parcelas[0].id}`,{}, getToken())
    
          contas.list[row.index].boleto_digitable_line = data.pix_code.emv
          contas.list[row.index].boleto_transaction_id = data.transaction_id
          contas.list[row.index].boleto_link = data.pix_code.qrcode_image_url
          contas.list[row.index].tipo_transaction = 'PIX'
  
          setState(prevState => ({...prevState,
            boletoSelecionado:{
              tipo_transaction: 'PIX',
              parceiro_bancario_id: row.parceiro_bancario_id,
              bank_slip:{
                url_slip_pdf: data.pix_code.qrcode_image_url,
                boleto_digitable_line: data.pix_code.emv
              },
              expiracao_pix: data.expiracao_pix
            },
            contas,
            alerta: {
              open: false,
              severity: 'info',
              message: ''
            },
            openModalExibeBoleto: true
          }))
          
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
            }
          }))
        }
      }

      if(row.forma_pgto_slug === 'boleto'){
        if(row.boleto_transaction_id){
          setState(prevState => ({...prevState,
            boletoSelecionado:{
              tipo_transaction: row.tipo_transaction,
              bank_slip:{
                url_slip_pdf: row.boleto_link,
                boleto_digitable_line: row.boleto_digitable_line
              }
            },
            openModalExibeBoleto: true
          }))
        }else{
          try {
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'info',
                message: 'Carregando Boleto'
              }
            }))
    
            const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/atualiza/${row.parcelas[0].id}`,{}, getToken())
    
            contas.list[row.index].boleto_transaction_id = data.transaction_id
            contas.list[row.index].boleto_digitable_line = data.bank_slip.digitable_line
            contas.list[row.index].boleto_link = data.bank_slip.url_slip_pdf
            contas.list[row.index].tipo_transaction = 'BOLETO'
    
            setState(prevState => ({...prevState,
              boletoSelecionado:{
                tipo_transaction: 'BOLETO',
                bank_slip:{
                  url_slip_pdf: data.bank_slip.url_slip_pdf,
                  boleto_digitable_line: data.bank_slip.digitable_line
                }
              },
              contas,
              alerta: {
                open: false,
                severity: 'info',
                message: ''
              },
              openModalExibeBoleto: true
            }))
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
              }
            }))
          }
        }
      }
    }else{
      handleClose()
    }
  }

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
  
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'success',
        message: 'Copiado!'
      }
    }))
    return
    
  }

  const handleModalExibeBoleto = () => {
    setState(prevState => ({...prevState, openModalExibeBoleto: false }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFiltro = (event) => {
    const { filtro } = state

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))

  }

  const viewRow = async (row) => {
    setState(prevState => ({...prevState,
      dataSelecionada: row,
      openModalViewContaCliente: true
    }))
  }

  const handleModalViewContaCliente = () => {
    setState(prevState => ({...prevState,
      openModalViewContaCliente: false
    }))
  }

  const viewNFSe = async (row) => {
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Carregando NFSe'
      }
    }))

    for (const nfse of row.nfse_ids) {
      try {
    
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/imprimir`, {id: nfse.id}, getToken())
        
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        setState(prevState => ({...prevState,
          loadingImprimir: false,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))

        saveAs(pdfBlob, `NFSe-${nfse.numero}.pdf`)
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingImprimir: false
        }))
      }
    }
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modal: {
        mensagem: ``,
      },
      modalOpen: false
    }))
  }

  const openQuitacao = async () => {

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parcelaConta/listAbertasCliente/${parseInt(pessoaId)}`, getToken())

      let resp = JSON.parse(decrypt(data))
     
      let allStatus = [
        {
          de: 'A VENCER',
          para: 'A Pagar'
        },
        {
          de: 'BAIXADO',
          para: 'Pago'
        },
        {
          de: 'VENCIDO',
          para: 'Atrasado'
        }
      ]

      setState(prevState => ({...prevState,
        contas_abertas: {
          list: resp.map((value, key) => {
            return {
              parcela_id: value.id,
              descricao: value.conta.descricao,
              data_vencimento: moment(value.datavencimento_orig).format('DD/MM/YYYY'),
              valor_total:value.valorparcela + value.multajuros,
              status: allStatus.filter(param => param.de === value.situacao)[0].para,
              forma_pgto_slug: value.valores_pago[0].forma_pagto.slug
            }
          })
        },
        openModalQuitacao: true
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseModalQuitacao = () => {
    setState(prevState => ({...prevState,
      openModalQuitacao: false
    }))
  }

  const handleCloseModalAnteciparParcelas = () => {
    setState(prevState => ({...prevState,
      openModalAnteciparParcelas: false
    }))
  }

  const selecionarParcelaAntecipar = (parcela, key) => {
    let {parcelas_antecipacao} = state

    let index = parcelas_antecipacao.findIndex(param => param.id === parcela.parcela_id)

    if(index === -1){
      if(key === parcelas_antecipacao.length){
        parcelas_antecipacao.push({
          id: parcela.parcela_id,
          valor: parcela.valor_total
        })
      }
    }else{
      if(key + 1 === parcelas_antecipacao.length){
        parcelas_antecipacao.splice(index, 1)
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Não é possível desmarcar essa parcela, apenas a ultima parcela selecionada.'
          }
        }))
      }

    }

    setState((prevState) => ({
      ...prevState,
      parcelas_antecipacao
    }))
  }

  const openSelecionarParcelas = () => {
    setState(prevState => ({...prevState,
      openModalAnteciparParcelas: true
    }))
  }

  const quitarParcelas = async () => {
    try {
      let {contas_abertas} = state

      let dados = {
        parcelas: contas_abertas.list.map(value => {return value.parcela_id})
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Gerando Antecipação...'
        }
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/quitarParcelas`, dados, getToken())
      
      await filtrarContas(state.filtro)
      // await continuaImprimirBoletoPix(true, {index: 0, forma_pgto_slug: 'pix', parcelas: contas_abertas.list.map(value => {return {id: value.parcela_id}})})

      handleCloseModalQuitacao()
      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const selecionarTodos = () => {
    let {contas_abertas} = state

    let parcelas_antecipacao = []

    for (const conta of contas_abertas.list) {
      parcelas_antecipacao.push({
        id: conta.parcela_id,
        valor: conta.valor_total
      })
    }

    setState((prevState) => ({
      ...prevState,
      parcelas_antecipacao,
      selecionarTodos: true
    }))

  }

  const desmarcarTodos = () => {
    setState((prevState) => ({
      ...prevState,
      parcelas_antecipacao: [],
      selecionarTodos: false
    }))

  }

  const confirmarAntecipacao = async () => {
    try {
      let {parcelas_antecipacao} = state

      let dados = {
        parcelas: parcelas_antecipacao.map(value => {return value.id})
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Gerando Antecipação...'
        }
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/quitarParcelas`, dados, getToken())
      
      await filtrarContas(state.filtro)
      // await continuaImprimirBoletoPix(true, {index: 0, forma_pgto_slug: 'pix', parcelas: contas_abertas.list.map(value => {return {id: value.parcela_id}})})

      handleCloseModalQuitacao()
      handleCloseModalAnteciparParcelas()

      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const cancelarAntecipacao = async () => {
    try {
      let {contas} = state

      console.log(contas.list)

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Cancelando Antecipações'
        }
      }))

      for (const value of contas.list) {
        if(value.parcela_antecipacao){
          await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/desfazerAntecipacao/${value.parcela_antecipacao.antecipacao.id}`, {}, getToken())
        }
      }

      await filtrarContas(state.filtro)

      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/boletosCliente/BoletosCliente.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })
  
      let cliente = JSON.parse(localStorage.getItem('cliente'))

      let {filtro} = state

      if(cliente){
        if(cliente.unidade_id === 48){
          filtro.data_inicial = moment().format('YYYY-MM')+'-01'
          filtro.data_final = moment().format('YYYY-MM-DD')

          setState(prevState => ({
            ...prevState,
            disableFiltro: true
          }))
        }else{
          filtro.data_inicial = moment().format('YYYY-MM')+'-01'
          filtro.data_final = moment(filtro.data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
        }
      }else{
        filtro.data_inicial = moment().format('YYYY-MM')+'-01'
        filtro.data_final = moment(filtro.data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
      }  

      setState(prevState => ({...prevState,
        filtro,
        parcelas_antecipacao: []
      }))
  
      const tempoConexao = JSON.parse(localStorage.getItem('tempoConexao'))

      if (!cliente) {
        setState(prevState => ({...prevState,
          openModalConfirmar: true
        }))
      } else if (cliente.id !== parseInt(pessoaId)) {
        setState(prevState => ({...prevState,
          openModalConfirmar: true
        }))
      } else if (tempoConexao) {
        if (new Date(tempoConexao).getTime() < new Date().getTime()) {
  
          setState(prevState => ({...prevState,
            openModalConfirmar: true
          }))
        } else if (!cliente) {
          setState(prevState => ({...prevState,
            openModalConfirmar: true
          }))
        } else {
          let cliente = JSON.parse(localStorage.getItem('cliente'))
  
          setState(prevState => ({...prevState,
            pessoa: cliente,
            mostrarBoletos: true,
          }))
  
          filtrarContas(filtro)
        }
      } else {  
        if (parseInt(pessoaId)) {
          try {
            const { data: pessoa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/${parseInt(pessoaId)}`, getToken())
  
            localStorage.setItem('cliente', JSON.stringify(pessoa))
  
            setState(prevState => ({...prevState,
              pessoa,
              openModalConfirmar: true
            }))
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
          }
        }
      }
  
      let novoTempoConexao = moment(new Date()).add(3, 'minutes')
      localStorage.setItem('tempoConexao', JSON.stringify(novoTempoConexao))
      
      handleCloseModalQuitacao()
      handleCloseModalAnteciparParcelas()
    };
    
    fetchData();
  }, []);

  const { contas, mostrarBoletos, pessoa, filtro, contas_abertas, possui_antecipacao } = state

  console.log(possui_antecipacao)

  return (
    <div className="container-boletos" id="app">
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Contas...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <React.Fragment>
          {mostrarBoletos &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={6} xs={12} sm={6}>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={2} xs={3} sm={3}>
                      {pessoa.unidade_pertence.configuracao &&
                        <React.Fragment>
                          {pessoa.unidade_pertence.configuracao.midia &&
                            <img src={`${window._env_.REACT_APP_API_URL}/static/${pessoa.unidade_pertence.configuracao.midia.key}`} alt="Logo Unidade" />
                          }
                          {!pessoa.unidade_pertence.configuracao.midia &&
                            <img src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                          }
                        </React.Fragment>
                      }
                      {!pessoa.unidade_pertence.configuracao &&
                        <React.Fragment>
                          <img src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid item md={10} xs={9} sm={9}>
                      <p style={{ textAlign: 'left', marginBottom: '0px', marginTop: '17px' }}>Cliente: {pessoa.tipo === 'FISICA' ? pessoa.fisica.nome : pessoa.juridica.razaosocial}</p>
                      <p style={{ textAlign: 'left', marginBottom: '0px' }}>Unidade: {pessoa.unidade_pertence.descricao}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={10} xs={9} sm={9}>
                      <h1 className="titulo" style={{ textAlign: 'right', marginTop: '15px' }}>Minhas Contas</h1>
                    </Grid>
                    <Grid item md={2} xs={3} sm={3}>
                      <img src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ paddingBottom: '10px' }} container spacing={1} direction="row" className="borderBottom mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    type="date" 
                    label="Data Inicial" 
                    variant="outlined" 
                    size="small" 
                    name="data_inicial" 
                    value={filtro.data_inicial} 
                    onChange={(e) => updateFiltro(e)} 
                    InputLabelProps={{ shrink: true }}
                    disabled={state.disableFiltro}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    type="date" 
                    label="Data Final" 
                    variant="outlined" 
                    size="small" 
                    name="data_final" 
                    value={filtro.data_final} 
                    onChange={(e) => updateFiltro(e)} 
                    InputLabelProps={{ shrink: true }} 
                    disabled={state.disableFiltro}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="situacao"
                    value={filtro.situacao}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={state.disableFiltro}
                  >
                    <option value=""></option>
                    <option value="A VENCER">A Pagar</option>
                    <option value="BAIXADO">Pago</option>
                    <option value="VENCIDO">Atrasado</option>
                    <option value="A VENCER E VENCIDO">Atrasado e A Pagar</option>
                  
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => filtrarContas(state.filtro)}>
                    Filtrar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  {!possui_antecipacao &&
                    <Button fullWidth variant="contained" color="success" onClick={() => openQuitacao()}>
                      Antecipar Parcelas
                    </Button>
                  }
                  {possui_antecipacao &&
                    <Button fullWidth variant="contained" color="secondary" onClick={() => cancelarAntecipacao()}>
                      Cancelar Antecipações
                    </Button>
                  }
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                {contas.list.map((value, key) => {
                  return (
                    <Grid key={key} item md={3} xs={12} sm={4}>
                      <div
                        style={{
                          width: '96%',
                          marginLeft: '2%',
                          border: '1px solid #b0b0b0',
                          backgroundColor: renderClassFinanceiro(value.status),
                          minHeight: 140,
                          borderRadius: 5,
                          overflow: 'hidden',
                          position: 'relative'
                        }}
                      >
                        <Grid container spacing={1} direction="row">
                          <Grid item md={12} xs={12} sm={12} style={{textTransform: 'uppercase'}}>
                            <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>{value.descricao}</p></b>
                            <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Vencimento: {value.data_vencimento}</p></b>
                            <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Valor: R$ <span>{real(value.valor_total)} - Situação: {value.status}</span></p></b>
                            {(value.forma_pgto_slug === 'boleto' && !value.parceiro_bancario_id) &&
                              <b><p style={{fontSize: '10px', margin: '5px 10px 5px 10px', color: 'red'}}><i>Boleto emitido por outra instituição financeira</i></p></b>
                            }
                            {(value.forma_pgto_slug === 'pix' && !value.parceiro_bancario_id) &&
                              <b><p style={{fontSize: '10px', margin: '5px 10px 5px 10px', color: 'red'}}><i>Pix emitido por outra instituição financeira</i></p></b>
                            }
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" style={{height: '40px', backgroundColor: 'rgba(169,169,169,0.8)', position: 'absolute', bottom: 0}}>
                          <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                            <Tooltip title='Visualizar Contas' aria-label="add">
                              <IconButton onClick={e => viewRow(value)} color="primary" aria-label="view" style={{padding: '0px'}}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {value.nfse_ids.length > 0 &&
                            <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                              <Tooltip title='Visualizar NFS-e' aria-label="add">
                                <img className='icone_nfse' src={icone_nfse} alt="Icone NFS-e" onClick={e => viewNFSe(value)}/>
                              </Tooltip>
                            </Grid>
                          }
                          {value.nfse_ids.length === 0 &&
                            <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                              <img className='icone_nfse' src={icone_nfse_disabled} alt="Icone NFS-e"/>
                            </Grid>
                          }
                          <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                            <Tooltip title='Visualizar Boleto/Pix' aria-label="add">
                              <IconButton onClick={e => imprimirBoleto(value)} color="success" aria-label="view" style={{padding: '0px'}} disabled={!value.parceiro_bancario_id}>
                                <PulsingIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
              <span className='clicksistemas'>Desenvolvido por Click Sistemas LTDA 2021 ® </span>
            </React.Fragment>
          }
          {/* <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon style={{bottom: 20}} className='botao_ajuda' onClick={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}/>
          </Tooltip> */}
        </React.Fragment>
      }
      <ConfirmarCPFCNPJ
        open={state.openModalConfirmar}
        dados={state.login}
        confirmar={e => confirmar(e)}
        updateData={(e) => updateField(e)}
        openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}
      />

      <ModalExibeBoleto
        open={state.openModalExibeBoleto}
        handleClose={e => handleModalExibeBoleto()}
        boletoSelecionado={state.boletoSelecionado}
        copyLink={e => copyLink(e)}
      />

      <ModalViewContaCliente
        open={state.openModalViewContaCliente}
        dados={state.dataSelecionada}
        handleClose={e => handleModalViewContaCliente()}
        renderClassFinanceiro={(e) => renderClassFinanceiro(e)}
        voltar={e => handleModalViewContaCliente()}
      />

      <Alert
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()}
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />

      <ModalConfirmacao 
        open={state.modalOpen} 
        handleClose={e => handleClose(e)} 
        dados={state.modal} 
        confirmar={e => continuaImprimirBoletoPix(e, state.row_selecionado)}
      />

      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Minhas Contas"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />

      <ModalAnteciparParcelas
        open={state.openModalAnteciparParcelas}
        handleClose={e => handleCloseModalAnteciparParcelas()}
        contas={contas_abertas.list}
        selecionarParcela={(e, key) => selecionarParcelaAntecipar(e, key)}
        parcelas_antecipacao={state.parcelas_antecipacao}
        selecionarTodos={() => selecionarTodos()}
        desmarcarTodos={() => desmarcarTodos()}
        selecionar={state.selecionarTodos}
        confirmarAntecipacao={() => confirmarAntecipacao()}
      />

      <ModalQuitacao 
        open={state.openModalQuitacao}
        handleClose={e => handleCloseModalQuitacao()}
        contas={contas_abertas.list}
        openSelecionarParcelas={() => openSelecionarParcelas()}
        quitarParcelas={() => quitarParcelas()}
      />
    </div>
  )
}

export default BoletosCliente