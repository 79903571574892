import React, { useState, useEffect } from 'react';
import './ContaPagarReceber.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import { saveAs } from 'file-saver';
import FormParcelaConta from '../../components/forms/FormParcelaConta'
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import Alert from './../../components/templates/Alert'
import ModalBaixaFinanceiro from './../../components/modals/BaixaFinanceiro'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'
import moeda from '../../services/moeda'
import ModalConfirmacaoCancelamento from './../../components/modals/ConfirmacaoCancelamento'
import ModalSimple from './../../components/modals/Confirmacao'
import ModalConfirmacaoEstorno from './../../components/modals/ConfirmacaoEstorno'
import ModalConfirmacaoParcelaEstorno from './../../components/modals/EstornarParcelas'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalConfirmacaoBaixa from './../../components/modals/Confirmacao'
import ModalTroco from './../../components/modals/Troco'
import ModalAlteraRecorrencia from './../../components/modals/AlteraRecorrencia'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormContaPRCentroCusto from '../../components/forms/FormContaPRCentroCusto'
import ModalCancelarNFSE from './../../components/modals/CancelarNFSE'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  parcela: {
    baixado: "",
    datavencimento: "",
    datapagamento: "",
    valorparcela: '0,00',
    documento: "",
    num: "",
    motivo_cancelamento: "",
    parcela_editada: false,
    multajuros: '0,00',
    valortotal: '0,00',
    valor_aberto: '0,00',
    valor_aberto_sem_form: 0,
    valor_pago: '0,00',
    valor_pago_sem_form: 0,
    formas_pgto: [
      {
        id: '',
        formapagto_id: '',
        valor_pagamento: real(0),
        formapagto_descricao: '',
        formapagto_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        tipo_chave: '',
        chave_pix: '',
        index: 0
      }
    ],
    conta:{
      descricao: '',
      valortotal: '',
      valortotal_sem_form: 0,
      numparcela: 0
    },
    itens_conta: []
  },
  planoContas: {
    id: "",
    codigo: "",
    descricao: "",
    ativo: "",
  },
  planosConta: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  formasPagamentoRemovidos: {
    list: []
  },
  modalConfirmacaoCancelamentoOpen: false,
  modalConfirmacaoEstornoOpen: false,
  modalConfirmacaoBaixaOpen: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false,
  modalTrocoOpen: false,
  modalCaixaOpen: false,
  modalCaixa: {
    mensagem: '',
    situacao: ''
  },
  permissoesContasPR: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCaixa: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  perfilLogado: '',
  valor_total: 0,
  modalBaixaFinanceiro: false,
  dadosModalBaixa: {
    modal: ''
  },
  nova_parcela: {
    valor_restante: '',
    vencimento_nova_parcela: '',
    forma_pgto_nova_parcela: '',
    conta_fin_nova_parcela: '',
    habilita: '',
    gerar: false
  },
  formas_sem_filtro: {
    list: []
  },
  valor_troco: 0,
  disabledButton: false,
  forma_pgto: {
    id: '',
    formapagto_id: '',
    valor_pagamento: real(0),
    formapagto_descricao: '',
    formapagto_hab_caixa: false,
    tipo_chave: '',
    chave_pix: '',
    index: ''
  },
  updateForma: false,
  movConciliada: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  formasOriginal: [],
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  dados: {},
  loadingCancelamento: false,
  tab: 0,
  centro_custos: [],
  centro_custo:{
    id: '',
    departamento_id: '',
    departamento: '',
    centrocusto_id: '',
    centrocusto: '',
    valor_percentual: 0,
    valor_rateio: 0,
    ativo: true,
    index: ''
  },
  departamentos: {
    list: []
  },
  centros_custo_list: {
    list: []
  },
  planoContaSelecionado: {
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  modalConfirmacaoParcelaEstornoOpen: false,
  parcelas_filhos: [],
  contaTroco: {
    descricao: "",
    tipo: "PAGAR",
    valortotal: "",
    numparcela: 1,
    qtd_parcelas: 1,
    tipo_item: 'ITEMRECDESP',
    item_id: '',
    qtdRecorrencia: 1,
    recorrenciaACada: 1,
    tipoRecorrencia:'M',
    data_documento: '',
    pessoa_id: '',
    unidade_id: '',
    recorrencia: false,
    parcelas: [
      {
        baixado: true,
        datavencimento: "",
        valorparcela: "",
        formapagto_id: "",
        documento: "",
        num: 1,
        contfin_id: "",
        parcela_editada: false,
        index: 1
      }
    ]
  },
  itensDespesa: {
    list: []
  },
  motivosCancelamento: {
    list: []
  },
  dadosCancelar: {
    id:'',
    motivoCancelar: '',
  },
  travarCancelamentoNFSE: false,
  openModalCancelarNFSE: false,
  openModalAlteraRecorrencia: false,
  recorrencia: {
    tipoRecorrencia: '',
    recorrenciaACada: 0,
    qtdRecorrencia: 0
  }
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const UpdateContasPagarReceber = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { parcelaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = (event) => {

    const {parcela} = state

    let name = event.target.name
    let value = event.target.value
    
    if(name === 'acao_cancelamento'){
      if(value === 'a_partir_hoje'){
        if(parcela.conta.recorrencia){
          setState(prevState => ({...prevState, 
            recorrencia: {
              tipoRecorrencia: parcela.conta.recorrencia.intervalo,
              recorrenciaACada: parcela.conta.recorrencia.repetir_a_cada,
              qtdRecorrencia: parcela.qtd_recorrencia_a_partir
            }
          }))
        }else{
          setState(prevState => ({...prevState, 
            recorrencia: {
              tipoRecorrencia: '',
              recorrenciaACada: 0,
              qtdRecorrencia: parcela.qtd_recorrencia_a_partir
            }
          }))
        }
      }else{
        if(parcela.conta.recorrencia){
          setState(prevState => ({...prevState, 
            recorrencia: {
              tipoRecorrencia: parcela.conta.recorrencia.intervalo,
              recorrenciaACada: parcela.conta.recorrencia.repetir_a_cada,
              qtdRecorrencia: parcela.qtd_recorrencia
            }
          }))
        }else{
          setState(prevState => ({...prevState, 
            recorrencia: {
              tipoRecorrencia: '',
              recorrenciaACada: 0,
              qtdRecorrencia: parcela.qtd_recorrencia
            }
          }))
        }
      }
    }

    parcela[name] = value
    setState(prevState => ({...prevState,  parcela }))
  }

  const ajustaCentros = (event) => {
    const {parcela, centro_custos} = state

    let valortotal = parseFloat(parcela.valorparcela.replace('.', '').replace(',', '.')) + (parseFloat(parcela.multajuros.replace('.', '').replace(',', '.') || 0))
    let diferenca = valortotal - parseFloat(parcela.valortotal.replace('.', '').replace(',', '.'))
    
    parcela.valortotal = real(valortotal)
    parcela.conta.valortotal = real(parseFloat(parcela.conta.valortotal.replace('.', '').replace(',', '.')) + diferenca)
    parcela.conta.valortotal_sem_form = parcela.conta.valortotal_sem_form + diferenca
    
    for (let i = 0; i < centro_custos.length; i++) {
      centro_custos[i].valor_rateio = real((parcela.conta.valortotal_sem_form * parseInt(centro_custos[i].valor_percentual)) / 100)
    }
    
    for (let i = 0; i < parcela.formas_pgto.length; i++) {
      parcela.formas_pgto[i].valor_pagamento = real(parseFloat(parcela.formas_pgto[i].valor_pagamento.replace('.', '').replace(',', '.')) + (diferenca / parcela.formas_pgto.length))
    }

    if(!parseFloat(parcela.multajuros.replace('.', '').replace(',', '.'))){
      parcela.multajuros = real(0)
    }

    setState(prevState => ({...prevState,  parcela, centro_custos }))
  }

  const verificaData = (event) => {
    const parcela = { ...state.parcela }

    if(parcela.datavencimento !== parcela.datavencimento_orig){
      //  const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
      // const data_vencimento = new Date(parcela.datavencimento)

      // if (data_vencimento.getTime() < data_hoje.getTime()) {
      //   parcela.datavencimento = parcela.datavencimento_orig
      // }
    }

    setState(prevState => ({...prevState, 
      parcela
    }))
  }

  const verificaDataNovaParcela = (event) => {
    const nova_parcela = { ...state.nova_parcela }
    
      const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
      const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() < data_hoje.getTime()) {
      nova_parcela.vencimento_nova_parcela = moment(new Date()).format('YYYY-MM-DD')
    }

    setState(prevState => ({...prevState, 
      nova_parcela
    }))
  }

  const criaUrl = () => {
    const filtro = JSON.parse(localStorage.getItem('filtro_contaspr'))

    let url = `/conta_pagar_receber?limit=${filtro.limit}&offset=${filtro.offset}&data_por=${filtro.data_por}&data_inicial=${filtro.data_inicial}&data_final=${filtro.data_final}`

    if(filtro.tipo !== ''){
      url += `&tipo=${filtro.tipo}`
    }
    if(filtro.descricao !== ''){
      url += `&descricao=${filtro.descricao}`
    }
    if(filtro.pessoa_id !== ''){
      url += `&pessoa_id=${filtro.pessoa_id}`
    }
    
    if(filtro.formas_pagto.length > 0){
      let texto = ''

      for (let i = 0; i < filtro.formas_pagto.length; i++) {
        if((i+1) === filtro.formas_pagto.length){
          texto += `${filtro.formas_pagto[i].id}`
        }else{
          texto += `${filtro.formas_pagto[i].id},`
        }
      }

      url += `&formas_pagto=${texto}`
    }

    if(filtro.situacoes.length > 0){
      let texto = ''

      for (let i = 0; i < filtro.situacoes.length; i++) {
        if((i+1) === filtro.situacoes.length){
          texto += `${filtro.situacoes[i].id}`
        }else{
          texto += `${filtro.situacoes[i].id},`
        }
      }

      url += `&situacoes=${texto}`
    }

    return url
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

      if (ultima_tela_financeiro.tela === 'contasPR') {
        let url = criaUrl()

        navigate(url);
      } else if (ultima_tela_financeiro.tela === 'cliente') {
        navigate(`/cliente/view/${ultima_tela_financeiro.id}/financeiro`)
      }else if(ultima_tela_financeiro.tela === 'extrato_conciliacao'){
        navigate(`/movimentacao_financeira/importar_extrato`)
      }else{
        let url = criaUrl()

        navigate(url);
      }
    }, timeout)
  }

  const handleClose = () => {
    setState(prevState => ({...prevState, 
      modalConfirmacaoCancelamentoOpen: false,
      modalConfirmacaoEstornoOpen: false,
      modalCaixaOpen: false,
      dadosCancelar: {
        id:'',
        motivoCancelar: '',
      },
      motivosCancelamento: {
        list: []
      }
    }))
  }

  const voltar = () => {
    const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

    if (ultima_tela_financeiro.tela === 'cliente') {
      navigate(`/cliente/view/${ultima_tela_financeiro.id}/financeiro`)
      return
    }

    backPage()
    // navigate('/conta_pagar_receber?limit=10&offset=0');
    return
  }

  const verificaCaixa = () => {
    const { ultimoCaixa, perfilLogado, formasPagamento } = state

    if (
      perfilLogado === 'caixa' &&
      (
        !Object.keys(ultimoCaixa).length ||
        (ultimoCaixa && ultimoCaixa['status_atual.status'] !== 'ABERTO')
      )
    ) {
      if (ultimoCaixa['status_atual.status'] === 'FECHADO') {
        setState(prevState => ({...prevState, 
          modalCaixa: {
            mensagem: 'É necessário abrir um caixa, deseja abrir um caixa?',
            situacao: 'ABRIR'
          },
          modalCaixaOpen: true,
        }))
        return false
      }

      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          warning: 'warning',
          message: 'O seu caixa anterior está em processo de aprovação, não é possível baixar essa parcela!'
        }
      }))

      return false
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.fechado
    ) {
      const dataCaixa = moment(ultimoCaixa.dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        // verificar Data abertura
        setState(prevState => ({...prevState, 
          modalCaixa: {
            mensagem: 'Opps, O caixa não é de hoje, é necessario fechar o anterior e abrir um novo, deseja ser redirecionado para o fechamento?',
            situacao: 'FECHAR'
          },
          modalCaixaOpen: true,
        }))
        return false
      }
    }

    if (perfilLogado === 'caixa') {
      let dinheiro = false
      for (const forma of formasPagamento.list) {
        if (forma.slug === 'dinheiro'
          || forma.slug === 'cartão-de-crédito'
          || forma.slug === 'cartão-de-débito'
          || forma.slug === 'cheque') {
          dinheiro = true
        }
      }

      if (!dinheiro) {
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            warning: 'warning',
            message: 'Essa forma de pagamento não é permitida para o caixa'
          }
        }))
        return false
      }
    }

    return true
  }

  const confirmarCaixa = (resposta) => {
    const { modalCaixa, ultimoCaixa } = state

    if (resposta) {
      // const encode = Buffer.from(props.location.pathname).toString('base64')

      if (modalCaixa.situacao === 'ABRIR') {
        navigate({ pathname: `/caixa/cadastro` })
      }

      if (modalCaixa.situacao === 'FECHAR') {
        navigate({ pathname: `/caixa/view/${ultimoCaixa.id}` })
      }

    } else {
      handleClose()
    }
  }
  
  const validateFormBaixar = () => {
    const { parcela } = state

    
    if (!parcela.contpr_descricao) return false
    if (!parcela.contpr_id) return false
    if (!parcela.plancontas_id) return false
    
    if (!parcela.datavencimento) return false
    if (!parcela.valorparcela) return false
    if (!parcela.num) return false
    
    for (let i = 0; i < parcela.formas_pgto.length; i++) {
      if (!parcela.formas_pgto[i].formapagto_id) return false
      if (!parcela.formas_pgto[i].contfin_id) return false
      if (i > 1) {
        if (parseFloat(parcela.formas_pgto[i].valor_pagamento.replace('.', '').replace(',', '.')) === 0) {
          parcela.formas_pgto.splice(i, 1)
        }
      }
    }

    return true
  }

  const baixar = async () => {

    setState(prevState => ({...prevState, 
      disabledButton: true
    }))

    if (!verificaCaixa()) {
      return false
    }
    
    if (!validateFormBaixar()) {
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        },
        disabledButton: false
      }))

      return
    }

    const {planoContaSelecionado, centro_custos, parcela, perfilLogado, nova_parcela, recorrencia} = state

    if(parcela.formas_pgto.length === 0) {
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: "Informar ao menos uma forma de pagamento."
        },
        disabledButton: false
      }))

      return
    }

    if (!parcela.datapagamento) {
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: "Informar data de pagamento (*)."
        },
        disabledButton: false
      }))

      return
    }

    if(planoContaSelecionado.centro_custos.length > 0){
      if(centro_custos.length === 0){
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o centro de custo.'
          },
          disabledButton: false
        }))
        return
      }
    }

    if(centro_custos.length > 0){
      const valorTotalRateio = somarValores(centro_custos.map(value => {return parseFloat(value.valor_percentual)}))

      if(valorTotalRateio < 100){
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A porcentagem do rateio do centro de custo não pode ser inferior a 100%.'
          },
          disabledButton: false
        }))
        return
      }else if(valorTotalRateio > 100){
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A porcentagem do rateio do centro de custo não pode ser superior a 100%.'
          },
          disabledButton: false
        }))
        return
      }
    }

    try {
      const arrayValores = parcela.formas_pgto.map(forma => { return parseFloat(forma.valor_pagamento.replace('.', '').replace(',', '.')) })
      const valor_total_parcela = (parseFloat(parcela.valorparcela.replace('.', '').replace(',', '.'))) + parseFloat(parcela.multajuros.replace('.', '').replace(',', '.'))
      const valoresParcelas = somarValores(arrayValores)

      if (valoresParcelas === 0) {

        if(parseFloat(parcela.valorparcela.replace('.', '').replace(',', '.')) !== parseFloat(parcela.multajuros.replace('.', '').replace(',', '.') * -1)){ 
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'warning',
              message: "Preencha o campo do valor pago!"
            },
            disabledButton: false
          }))
          return
        }
        
      }

      if (!nova_parcela.gerar) {
        if (parseFloat(valoresParcelas.toFixed(2)) < parseFloat(valor_total_parcela.toFixed(2))) {
          setState(prevState => ({...prevState, 
            modalBaixaFinanceiro: true,
            disabledButton: false,
            dadosModalBaixa: {
              modal: 'falta_valor'
            }
          }))
          return
        }
      }

      if (parseFloat(valoresParcelas.toFixed(2)) > parseFloat(valor_total_parcela.toFixed(2))) {
        const index = parcela.formas_pgto.findIndex(param => param.formapagto_descricao === 'Dinheiro')

        if (index !== -1) {
          const valor_troco = valoresParcelas - valor_total_parcela
          
          if (valor_troco >= parseFloat(parcela.formas_pgto[index].valor_pagamento.replace('.', '').replace(',', '.'))) {
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: `Valor de troco superior a quantia recebida em dinheiro`
              },
              disabledButton: false
            }))
            return
          } else {
            // parcela.formas_pgto[index].valor_pagamento = real(parseFloat(parcela.formas_pgto[index].valor_pagamento.replace('.', '').replace(',', '.')) - valor_troco)

            setState(prevState => ({...prevState, 
              valor_troco
            }))
          }
        }

        if (index === -1) {
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'warning',
              message:  `Valor informado maior que o valor da parcela, não é possivel retornar troco nessa forma de pagamento`
            },
            disabledButton: false
          }))
          return
        }
      }

      if (perfilLogado === 'caixa') {
        for (let i = 0; i < parcela.formas_pgto.length; i++) {
          if (!parcela.formas_pgto[i].formapagto_hab_caixa) {
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: `O caixa não pode receber por ${parcela.formas_pgto[i].formapagto_descricao}`
              },
              disabledButton: false
            }))

            return
          }
        }
      }

      // for (let i = 0; i < parcela.formas_pgto.length; i++) {
      //   if(parseFloat(parcela.formas_pgto[i].valor_pagamento.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) === 0 ){
      //     parcela.formas_pgto.splice(i, 1)
      //   }
      // }

      let valorparcela = parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      valorparcela = parcela.plancontas_tipo === 'DESPESA' ? (valorparcela < 0 ? valorparcela : (valorparcela * -1)) : (valorparcela > 0 ? valorparcela : (valorparcela * -1)) 

      const dados = {
        conta: {
          contpr_id: parcela.contpr_id,
          contpr_descricao: parcela.contpr_descricao,
          plancontas_id: parcela.plancontas_id,
          centros_custo: centro_custos.map(centro => {
            let valor_rateio =  parseFloat(centro.valor_rateio.replaceAll(".", "").replace(",", "."))
            valor_rateio = parcela.plancontas_tipo === 'DESPESA' ? (valor_rateio < 0 ? valor_rateio : (valor_rateio * -1)) :(valor_rateio > 0 ? valor_rateio : (valor_rateio * -1))
            
            if(centro.id !== ''){
              return {
                id: centro.id,
                valor_rateio,
                valor_percentual: parseFloat(centro.valor_percentual),
                centrocusto_id: centro.centrocusto_id
              }
            }else{
              return {
                valor_rateio,
                valor_percentual: parseFloat(centro.valor_percentual),
                centrocusto_id: centro.centrocusto_id
              }
            }
          })
        },
        parcela: {
          baixado: true,
          datavencimento: parcela.datavencimento,
          datapagamento: parcela.datapagamento,
          valorparcela,
          multajuros: parseFloat(parcela.multajuros.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          num: parcela.num,
          documento: parcela.documento,
          situacao: 'BAIXADO',
          formas_pgto: parcela.formas_pgto.map(forma => {
            let valor_pagamento = parseFloat(forma.valor_pagamento.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
            valor_pagamento = parcela.plancontas_tipo === 'DESPESA' ? (valor_pagamento < 0 ? valor_pagamento : (valor_pagamento * -1)) :(valor_pagamento > 0 ? valor_pagamento : (valor_pagamento * -1)) 

            if (forma.id !== "") {
              return {
                id: forma.id,
                formapagto_id: forma.formapagto_id,
                contfin_id: parseInt(forma.contfin_id),
                valor_pagamento
              }
            } else {
              return {
                formapagto_id: forma.formapagto_id,
                contfin_id: parseInt(forma.contfin_id),
                valor_pagamento
              }
            }
          }),
          itens_conta: []
        }
      }

      if (nova_parcela.gerar) {
        dados.nova_parcela = {
          valor_restante: nova_parcela.valor_restante,
          vencimento_nova_parcela: nova_parcela.vencimento_nova_parcela,
          forma_pgto_nova_parcela: parseInt(nova_parcela.forma_pgto_nova_parcela),
          conta_fin_nova_parcela: parseInt(nova_parcela.conta_fin_nova_parcela),
        }
      }

      if (perfilLogado === 'caixa') {
        dados.caixa = state.ultimoCaixa.id
      }

      if (dados.parcela.motivo_cancelamento === "" || !dados.parcela.motivo_cancelamento) {
        delete dados.parcela.motivo_cancelamento
      }

      setState(prevState => ({...prevState, dados}))
  
      const indexBoletoAtual = dados.parcela.formas_pgto.findIndex(param => param.formapagto_id === 4)
      const indexBoletoOriginal = state.formasOriginal.findIndex(param => param.formapagto_id === 4)

      dados.acao_alteracao = parcela.acao_cancelamento

      if(parcela.recorrencia && dados.acao_alteracao !== 'apenas_esta'){
        dados.recorrencia = recorrencia
      }

      if(indexBoletoAtual === -1 && indexBoletoOriginal !== -1){
        setState(prevState => ({...prevState, 
          modalConfirmacao: {
            mensagem: "Para esta parcela foi gerado um boleto, ao remover a forma de pagamento do boleto você também estará cancelando o boleto, deseja continuar?",
          },
          modalConfirmacaoBaixaOpen: true
        }))
      }else{
        confirmarBaixa(true, dados)
      }

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        disabledButton: false
      }))
      return
    }

  }

  const confirmarBaixa = async (value, dados) => {
    if(value){
      const arrayValores = dados.parcela.formas_pgto.map(forma => { return forma.valor_pagamento })
      const valor_total_parcela = (dados.parcela.valorparcela < 0 ? dados.parcela.valorparcela * -1 : dados.parcela.valorparcela) + dados.parcela.multajuros
      const valoresParcelas = somarValores(arrayValores)

      const valor_troco = parseFloat((valoresParcelas < 0 ? valoresParcelas * -1 : valoresParcelas).toFixed(2)) - parseFloat(valor_total_parcela.toFixed(2))
      
      if (valor_troco !== 0 && !state.nova_parcela.gerar) {
        setState(prevState => ({...prevState, 
          valor_troco,
          modalBaixaFinanceiro: true,
          dadosModalBaixa: {
            modal: 'informar_troco'
          }
        }))
      }else{
        await continuarBaixa(true, dados, false)
      }
      
    }else{
      setState(prevState => ({...prevState, 
        modalConfirmacao: {
          mensagem: "",
        },
        modalConfirmacaoBaixaOpen: false
      }))
    }
  }

  const lancarTroco = async (value) => {
    const {contaTroco, itensDespesa, valor_troco, dados, parcela} = state 

    setState(prevState => ({...prevState, 
      modalBaixaFinanceiro: false,
      dadosModalBaixa: {
        modal: ''
      }
    }))

    if(value){

      contaTroco.valortotal = real(valor_troco)
      contaTroco.parcelas[0].valorparcela = real(valor_troco)
      contaTroco.parcelas[0].datavencimento = moment().format('YYYY-MM-DD')
      contaTroco.data_documento = moment().format('YYYY-MM-DD')
      contaTroco.item_id = itensDespesa.list[0].id //Alterar Depois

      setState(prevState => ({...prevState, 
        modalTrocoOpen: true,
        contaTroco,
      }))
    }else{
      const index = parcela.formas_pgto.findIndex(param => param.formapagto_descricao === 'Dinheiro')
      
      if (index !== -1) {
        dados.parcela.formas_pgto[index].valor_pagamento = dados.parcela.formas_pgto[index].valor_pagamento - valor_troco

        setState(prevState => ({...prevState, 
          valor_troco,
          dados
        }))
      }

      await continuarBaixa(true, dados, false)


    }
  }

  const validateFormTroco = () => {
    const { contaTroco } = state
        
    for (let i = 0; i < contaTroco.parcelas.length; i++) {
      if (!contaTroco.parcelas[i].datavencimento) return false
      if (!contaTroco.parcelas[i].valorparcela) return false
      // if (!contaTroco.parcelas[i].formapagto_id) return false
      if (!contaTroco.parcelas[i].contfin_id) return false
    }

    return true
  }

  const continuarBaixa = async (value, dados, troco) => {
    const {contaTroco, valor_troco} = state

    if(value){
      if(troco){
        if(valor_troco > 0){
          if (!validateFormTroco()) {
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Preencha os campos obrigatorios (*).'
              }
            }))
            return
          }
  
          contaTroco.valortotal = parseFloat(contaTroco.valortotal.replaceAll(".", "").replace(",", "."))
          
          for (let i = 0; i < contaTroco.parcelas.length; i++) {
            contaTroco.parcelas[i].valorparcela = parseFloat(contaTroco.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
            contaTroco.parcelas[i].contfin_id = parseInt(contaTroco.parcelas[i].contfin_id)
            contaTroco.parcelas[i].datapagamento = contaTroco.parcelas[i].datavencimento
            contaTroco.parcelas[i].parcela_pai_id = parcelaId

            contaTroco.parcelas[i].formas_pgto = [{
              valor_pagamento: contaTroco.parcelas[i].valorparcela
            }]
          }
  
          dados.conta_troco = contaTroco
        }
      }

      try {

        const { data } = await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/${parcelaId}`, dados, getToken())

        if (data.caminhoRecibo) {

          const dados = {
            path: data.caminhoRecibo
          }

          const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

          const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

          saveAs(pdfBlob, `Recibo-${parcelaId}.pdf`);
        }

        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'success',
            message: 'Parcela baixada com sucesso..'
          },
          disabledButton: false,
          nova_parcela: initialState.nova_parcela
        }))

        backPage()
      } catch (error) {
        console.log(error)

        handleCloseModalTroco()

        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButton: false
        }))
        return
      }

    }else{
      setState(prevState => ({...prevState, 
        modalTrocoOpen: false,
        disabledButton: false,
        contaTroco: {
          descricao: "",
          tipo: "PAGAR",
          valortotal: "",
          numparcela: 1,
          qtd_parcelas: 1,
          tipo_item: 'ITEMRECDESP',
          item_id: '',
          qtdRecorrencia: 1,
          recorrenciaACada: 1,
          tipoRecorrencia:'M',
          data_documento: '',
          recorrencia: false,
          unidade_id: state.contaTroco.unidade_id,
          pessoa_id: state.contaTroco.pessoa_id,
          parcelas: [
            {
              baixado: "",
              datavencimento: "",
              valorparcela: "",
              formapagto_id: "",
              documento: "",
              num: "",
              contfin_id: "",
              parcela_editada: false,
              index: 1
            }
          ]
        },
      }))
    }
  
  }

  const confirmarEstorno = async() => {
    const { parcela } = state

    if(parcela.conta.nfse_id){
      var now = moment(); //todays date

      var end = moment(parcela.conta.nfse.data_emissao); // another date

      var duration = moment.duration(now.diff(end));
      var hours = duration.asHours();

      if(hours < 24){
        try {
  
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/consultarMotivoCancelamento`, getToken())
            
          setState(prevState => ({...prevState, 
            motivosCancelamento: {
              list: data
            }
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
        }
      }else{
        setState(prevState => ({...prevState, 
          travarCancelamentoNFSE: true
        }))
      }

    }

    let movConciliada = false

    for (const i in parcela.formas_pgto) {
      if(parcela.formas_pgto[i].movcontfin_conciliado){
        movConciliada = true
      }
    }

    setState(prevState => ({...prevState, 
      modalConfirmacaoEstornoOpen: true,
      movConciliada
    }))
  }

  const estornar = async (value) => {
    if(value){
      setState(prevState => ({
        ...prevState,
        disabledButton: true
      }))

      const {parcela} = state 

      if(parcela.parcelas_filhos.length > 0){
        setState(prevState => ({...prevState, 
          modalConfirmacaoParcelaEstornoOpen: true,
          parcelas_filhos: parcela.parcelas_filhos
        }))
      }else if(parcela.parcela_pai){
        setState(prevState => ({...prevState, 
          modalConfirmacaoParcelaEstornoOpen: true,
          parcelas_filhos: [{...parcela.parcela_pai}]
        }))
      }else{
        await continuarEstorno(true)
      }

    }else{
      setState(prevState => ({...prevState, 
        modalConfirmacaoEstornoOpen: false,
        disabledButton: false
      }))
    }

  }

  const continuarEstorno = async (value) => {
    if(value){
      try {
        const { parcela, dadosCancelar, travarCancelamentoNFSE } = state
  
        let valorparcela = parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
        valorparcela = parcela.plancontas_tipo === 'DESPESA' ? (valorparcela < 0 ? valorparcela : (valorparcela * -1)) : (valorparcela > 0 ? valorparcela : (valorparcela * -1)) 
  
        const dados = {
          conta: {
            contpr_id: parcela.contpr_id,
            contpr_descricao: parcela.contpr_descricao,
            plancontas_id: parcela.plancontas_id
          },
          parcela: {
            baixado: true,
            datavencimento: parcela.datavencimento,
            valorparcela,
            multajuros: parseFloat(parcela.multajuros.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
            num: parcela.num,
            documento: parcela.documento,
            situacao: 'ESTORNADO',
            motivo_cancelamento: parcela.motivo_cancelamento,
            formas_pgto: parcela.formas_pgto.map(forma => {
              let valor_pagamento = parseFloat(forma.valor_pagamento.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
              valor_pagamento = parcela.plancontas_tipo === 'DESPESA' ? (valor_pagamento < 0 ? valor_pagamento : (valor_pagamento * -1)) :(valor_pagamento > 0 ? valor_pagamento : (valor_pagamento * -1)) 
  
              if (forma.id !== "") {
                return {
                  id: forma.id,
                  formapagto_id: forma.formapagto_id,
                  contfin_id:forma.contfin_id !== '' ?  parseInt(forma.contfin_id) : '',
                  valor_pagamento
                }
              } else {
                return {
                  formapagto_id: forma.formapagto_id,
                  contfin_id:forma.contfin_id !== '' ?  parseInt(forma.contfin_id) : '',
                  valor_pagamento
                }
              }
            })
          },
          acao_alteracao: 'apenas_esta'
        }

        if(parcela.conta.nfse_id && parcela.conta.nfse.status === 'APROVADO' && parcela.conta.numparcela === 1 && !travarCancelamentoNFSE){
          dados.dadosCancelar = {
            id: parcela.conta.nfse_id,
            motivo: parseInt(dadosCancelar.motivo)
          }
        }
  
        for (let i = 0; i < dados.parcela.formas_pgto.length; i++) {
          if(dados.parcela.formas_pgto[i].contfin_id === ''){
            delete dados.parcela.formas_pgto[i].contfin_id
          }
        }
  
        if (dados.parcela.documento === "" || !dados.parcela.documento) {
          delete dados.parcela.documento
        }
  
        await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/${parcelaId}`, dados, getToken())
    
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'success',
            message: 'Estorno realizado com sucesso.'
          },
          disabledButton: false
        }))
  
        backPage()
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButton: false
        }))
        return
      }
    }else{
      handleCloseConfirmacaoParcelaEstorno()
    }
    
  }

  const cancelar = async () => {
    setState(prevState => ({...prevState, 
      modalConfirmacaoCancelamentoOpen: true
    }))
  }

  const confirmar = async (param) => {
    if (param) {
      setState(prevState => ({...prevState, loadingCancelamento: true}))
      let { parcela } = state

      if(parcela.conta.nfse_id){
        try {
          let {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/consultar/${parcela.conta.nfse_id}`, getToken())
          
          if(data.status === "APROVADO"){
            var now = moment(); //todays date

            var end = moment(data.data_emissao); // another date
          
            var duration = moment.duration(now.diff(end));
            var hours = duration.asHours();
          
            if(hours > 24) {
              setState(prevState => ({...prevState, 
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'Para essa conta foi gerado uma NFS-e, e não é possivel cancelar a NFS-e após 24hrs que ela foi gerado, por favor realizar o cancelamento da nota via portal da prefeitura.'
                }
              }))
              return
            }else{
              try {
  
                const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/consultarMotivoCancelamento`, getToken())
                  
                setState(prevState => ({...prevState, 
                  motivosCancelamento: {
                    list: data
                  },
                  dadosCancelar: {
                    id: parcela.conta.nfse_id,
                    motivo: ''
                  },
                  openModalCancelarNFSE: true
                }))
                return
              } catch (error) {
                console.log(error)
                setState(prevState => ({...prevState, 
                  alerta: {
                    open: true,
                    severity: 'error',
                    message: error.response ? error.response.data.message : 'Erro Interno'
                  }
                }))
          
                backPage()
              }
            }
          }
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            },
            loadingCancelamento: false
          }))
          return
        }
      }

      await realizarCancelamento(true)
      
    } else {
      setState(prevState => ({...prevState, 
        modalConfirmacaoCancelamentoOpen: false
      }))
    }
  }

  const realizarCancelamento = async (value) =>{
    if(value){
      let { parcela, dadosCancelar } = state

      setState(prevState => ({...prevState,
        disabledButton: true
      }))
      
      const dados = {
        motivo_cancelamento: parcela.motivo_cancelamento,
        acao_cancelamento: parcela.acao_cancelamento
      }

      if(dadosCancelar.id !== ''){
        dados.motivo_cancelar_nfse = {
          nfse_id: parseInt(dadosCancelar.id),
          motivo: parseInt(dadosCancelar.motivo)
        }
      }

      try {

        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cancelando Parcela...'
          }
        }))

        await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/cancelar/${parcelaId}`, dados, getToken())

        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'success',
            message: 'Atualização realizada com sucesso.'
          },
          loadingCancelamento: false,
          disabledButton: false
        }))

        backPage()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingCancelamento: false,
          disabledButton: false
        }))
        return
      }
    }else{
      setState(prevState => ({...prevState, 
        openModalCancelarNFSE: false,
        loadingCancelamento: false,
        dadosCancelar: {
          id:'',
          motivoCancelar: '',
        },
      }))
    }
  }

  const addFormaPagto = () => {
    setState(prevState => ({...prevState, 
      modalBaixaFinanceiro: false
    }))

    const { parcela, forma_pgto } = state

    if(forma_pgto.formapagto_id === '' || !forma_pgto.formapagto_id){
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: `Por favor informar a Forma de Pagamento!`
        }
      }))
      return false
    }

    if(forma_pgto.contfin_id === '' || !forma_pgto.contfin_id){
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: `Por favor informar a Conta Financeira!`
        }
      }))
      return false
    }

    if(forma_pgto.valor_pagamento === '' || !forma_pgto.valor_pagamento){
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: `Por favor informar o Valor de Pagamento!`
        }
      }))
      return false
    }

    const arrayValores = parcela.formas_pgto.map(forma => { return parseFloat(forma.valor_pagamento) })
    const valoresParcelas = somarValores(arrayValores)
    const itens = parcela.formas_pgto
    const valor_total_parcela = parcela.valorparcela_sem_form + parseFloat(parcela.multajuros.replace('.', '').replace(',', '.'))

    if (forma_pgto.index === '') {

      if(itens.length > 0 ){
        if (parseFloat(itens[itens.length - 1].valor_pagamento.replace('.', '').replace(',', '.')) === 0) {
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar o valor do pagamento na Forma de Pagamento ${itens.length}!`
            }
          }))
          
          return false
        }
  
        if (itens[itens.length - 1].formapagto_id === '') {
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar a Forma de Pagamento do item ${itens.length}!`
            }
          }))
          return false
        }
      }

      if (valoresParcelas >= valor_total_parcela) {
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total é igual ou maior que o valor da parcela!'
          }
        }))
        return
      } else {

        itens.push({
          id: forma_pgto.id !== '' ? forma_pgto.id : '',
          formapagto_id: forma_pgto.formapagto_id,
          valor_pagamento: forma_pgto.valor_pagamento,
          formapagto_descricao: forma_pgto.formapagto_descricao,
          formapagto_hab_caixa: forma_pgto.formapagto_hab_caixa,
          contfin_id: forma_pgto.contfin_id,
          contfin_descricao: forma_pgto.contfin_descricao,
          contfin_tipo_conta: forma_pgto.contfin_tipo_conta,
          index: itens.length
        })

        parcela.formas_pgto = itens

        const forma_pgtoAux = {
          id: '',
          formapagto_id: '',
          valor_pagamento: real(0),
          formapagto_descricao: '',
          formapagto_hab_caixa: false,
          contfin_id: '',
          contfin_descricao: '',
          contfin_tipo_conta: '',
          tipo_chave: '',
          chave_pix: '',
          index: ''
        }

        if (state.perfilLogado === 'caixa') {
          forma_pgtoAux.contfin_id = state.contasFinanceira.list.length ? state.contasFinanceira.list[0].contfin_id : null
          forma_pgtoAux.contfin_descricao = state.contasFinanceira.list.length ? state.contasFinanceira.list[0].contfin_descricao : null
          forma_pgtoAux.contfin_tipo_conta = state.contasFinanceira.list.length ? state.contasFinanceira.list[0].contfin_tipo_conta : null
        }

        setState(prevState => ({...prevState, 
          parcela,
          valor_total: valoresParcelas + parseFloat(forma_pgto.valor_pagamento.replace('.', '').replace(',', '.')),
          forma_pgto: forma_pgtoAux,
          updateForma: false
        }))
      }
    } else {
      itens[forma_pgto.index] = {
        id: forma_pgto.id !== '' ? forma_pgto.id : '',
        formapagto_id: forma_pgto.formapagto_id,
        valor_pagamento: forma_pgto.valor_pagamento,
        formapagto_descricao: forma_pgto.formapagto_descricao,
        formapagto_hab_caixa: forma_pgto.formapagto_hab_caixa,
        contfin_id: forma_pgto.contfin_id,
        contfin_descricao: forma_pgto.contfin_descricao,
        contfin_tipo_conta: forma_pgto.contfin_tipo_conta,
        index: forma_pgto.index
      }

      parcela.formas_pgto = itens

      let forma_pgtoAux = {
        id: '',
        formapagto_id: '',
        valor_pagamento: real(0),
        formapagto_descricao: '',
        formapagto_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        tipo_chave: '',
        chave_pix: '',
        index: ''
      }

      if (state.perfilLogado === 'caixa') {
        forma_pgtoAux.contfin_id = state.contasFinanceira.list.length ? state.contasFinanceira.list[0].contfin_id : null
        forma_pgtoAux.contfin_descricao = state.contasFinanceira.list.length ? state.contasFinanceira.list[0].contfin_descricao : null
        forma_pgtoAux.contfin_tipo_conta = state.contasFinanceira.list.length ? state.contasFinanceira.list[0].contfin_tipo_conta : null
      }

      setState(prevState => ({...prevState, 
        parcela,
        valor_total: valoresParcelas + parseFloat(forma_pgto.valor_pagamento.replace('.', '').replace(',', '.')),
        forma_pgto: forma_pgtoAux,
        updateForma: false
      }))
    }
  }

  const editarFormaPgto = (index) => {
    const { parcela } = state

    setState(prevState => ({...prevState, 
      forma_pgto:{...parcela.formas_pgto[index]},
      update: true
    }))
  }

  const updateFieldFormaPgto = (event, key) => {
    let { forma_pgto, formasPagamento, contasFinanceira, perfilLogado } = state

    if (event.target.name === 'formapagto_id') {
      const itens = formasPagamento.list
      const index = itens.findIndex(param => param.id === parseInt(event.target.value))

      forma_pgto[event.target.name] = parseInt(event.target.value)
      forma_pgto.formapagto_descricao = itens[index].descricao
      forma_pgto.formapagto_hab_caixa = itens[index].hab_caixa
    } else if (event.target.name === 'contfin_id') {
      const itens = contasFinanceira.list
      const index = itens.findIndex(param => param.id === parseInt(event.target.value))
      
      if ( perfilLogado === 'contas-pagar-receber'){

        if(forma_pgto.contfin_tipo_conta !== itens[index].tipo_conta){
          forma_pgto.formapagto_id = ''
          forma_pgto.formapagto_descricao = ''
          forma_pgto.formapagto_hab_caixa = ''
        }
      }
    
      forma_pgto[event.target.name] = parseInt(event.target.value)
      forma_pgto.contfin_descricao = itens[index].descricao
      forma_pgto.contfin_tipo_conta = itens[index].tipo_conta
    
    } else {
      forma_pgto[event.target.name] = event.target.value
    }

    setState(prevState => ({...prevState,  forma_pgto }))
  }

  const openModalNovaParcela = () => {
    setState(prevState => ({...prevState, 
      modalBaixaFinanceiro: false
    }))

    const { nova_parcela, parcela } = state

    const arrayValores = parcela.formas_pgto.map(forma => { return parseFloat(forma.valor_pagamento.replace('.', '').replace(',', '.')) })

    const valoresParcelas = somarValores(arrayValores)

    nova_parcela.valor_restante = parseFloat(parcela.valorparcela.replace('.', '').replace(',', '.')) + parseFloat(parcela.multajuros ? parcela.multajuros.replace('.', '').replace(',', '.') : 0) - valoresParcelas

    setState(prevState => ({...prevState, 
      modalBaixaFinanceiro: true,
      dadosModalBaixa: {
        modal: 'nova_parcela'
      },
      nova_parcela
    }))
    return
  }

  const updateFieldNovaParcela = (event) => {
    const { nova_parcela, formas_sem_filtro } = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'forma_pgto_nova_parcela'){
      let forma_selecionada = formas_sem_filtro.list.filter(param => param.id === parseInt(value))[0]
    
      nova_parcela.habilita = forma_selecionada.habilita
    }

    nova_parcela[name] = value

    setState(prevState => ({...prevState,  nova_parcela }))
  }

  const recusarNovaParcela = () => {
    setState(prevState => ({...prevState, 
      modalBaixaFinanceiro: false,
      nova_parcela: {
        valor_restante: '',
        vencimento_nova_parcela: '',
        forma_pgto_nova_parcela: '',
        conta_fin_nova_parcela: '',
        habilita: ''
      }
    }))
  }

  const confirmarNovaParcela = () => {
    const { nova_parcela } = state

    nova_parcela.gerar = true

    setState(prevState => ({...prevState, nova_parcela}))

    baixar()
  }

  const confirmarTroco = () => {
    setState(prevState => ({...prevState, 
      alerta: {
        open: true,
        severity: 'success',
        message: 'Parabéns! Parcela baixada com sucesso.'
      },
      parcela: initialState.parcela,
      disabledButton: false
    }))

    backPage()
  }

  const removeFormaPagto = (event) => {
    const { parcela } = state

    let itens = parcela.formas_pgto

    if (itens.length >= 1) {
      itens.splice(event, 1)

      for (let i = 0; i < itens.length; i++) {
        itens[i].index = i
      }

      parcela.formas_pgto = itens

      setState(prevState => ({...prevState, 
        parcela
      }))
    }
  }

  const  handleCloseBaixa = () => {
    setState(prevState => ({...prevState, 
      disabledButton: false,
      nova_parcela: initialState.nova_parcela,
      modalBaixaFinanceiro: false
    }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState, 
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleCloseConfirmacao = () => {
    setState(prevState => ({...prevState, 
      modalConfirmacaoOpen: false,
      disabledButton: false,
    }))
  }

  const handleCloseConfirmacaoBaixa = () => {
    setState(prevState => ({...prevState, 
      modalConfirmacaoBaixaOpen: false,
      disabledButton: false
    }))
  }

  const handleCloseConfirmacaoParcelaEstorno = () => {
    setState(prevState => ({...prevState, 
      modalConfirmacaoParcelaEstornoOpen: false
    }))
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState, 
      tab: newValue
    }))
  };

  const removeCentro = (index) => {
    let {centro_custos} = state

    centro_custos.splice(index, 1)

    const aux = []

    for (let i = 0; i < centro_custos.length; i++) {
      aux.push({
        id: centro_custos[i].id,
        departamento_id: centro_custos[i].departamento_id,
        departamento:centro_custos[i].departamento,
        centrocusto_id: centro_custos[i].centrocusto_id,
        centrocusto: centro_custos[i].centrocusto,
        valor_percentual: centro_custos[i].valor_percentual,
        valor_rateio: centro_custos[i].valor_rateio,
        ativo: centro_custos[i].ativo,
        index: i
      })
    }

    centro_custos = aux

    // for (let i = 0; i < centro_custos.length; i++) {
    //   centro_custos[i].valor_rateio = real(valor_total/(centro_custos.length))
    // }

    // for (let i = 0; i < centro_custos.length; i++) {
    //   centro_custos[i].valor_percentual = ((parseFloat(centro_custos[i].valor_rateio.replaceAll(".", "").replace(",", "."))/valor_total)*100).toFixed(2)
    // }

    setState(prevState => ({...prevState,  centro_custos }))
  }

  const updateFieldCentroCusto = (event) => {
    const {centro_custo, departamentos, centros_custo_list} = state
    centro_custo[event.target.name] = event.target.value

    if(event.target.name === 'departamento_id'){
      
      const departamento = departamentos.list.filter(param => param.id === parseInt(event.target.value))[0]
      setState(prevState => ({...prevState, 
        centros_custo_list: {
          list: departamento.centro_custos
        }
      }))

      centro_custo.departamento = departamento.descricao
    }

    if(event.target.name === 'centrocusto_id'){
      const centro = centros_custo_list.list.filter(param => param.id === parseInt(event.target.value))[0]
  
      centro_custo.centrocusto = centro.descricao
    }
    
    setState(prevState => ({...prevState, centro_custo}))
  }

  const validateCentroCusto = () => {
    const centro_custo = state.centro_custo

    if (!centro_custo.centrocusto_id) return false

    return true
  }

  const addCentroCusto = () => {
    if (!validateCentroCusto()) {
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    let {centro_custos, centro_custo, parcela} = state

    const index = centro_custos.length

    centro_custos.push({
      id: '',
      departamento_id: centro_custo.departamento_id,
      departamento:centro_custo.departamento,
      centrocusto_id: centro_custo.centrocusto_id,
      centrocusto: centro_custo.centrocusto,
      valor_percentual: (0).toFixed(2),
      valor_rateio: real(0),
      ativo: true,
      index
    })

    let valor_total = parseFloat(parcela.conta.valortotal.replaceAll(".", "").replace(",", "."))

    for (let i = 0; i < centro_custos.length; i++) {
      let valor_percentual = (100/centro_custos.length).toFixed(2)

      centro_custos[i].valor_percentual = valor_percentual
      centro_custos[i].valor_rateio = real((valor_total * valor_percentual)/100)
    }

    let restante = (100 - somarValores(centro_custos.map(value => {return parseFloat(value.valor_percentual)}))).toFixed(2)
    restante = parseFloat(restante)

    if(restante > 0){
      const valor_percentual = parseFloat(centro_custos[centro_custos.length - 1].valor_percentual) + restante

      centro_custos[centro_custos.length - 1].valor_percentual = valor_percentual.toFixed(2)
      centro_custos[centro_custos.length - 1].valor_rateio = real((valor_total * valor_percentual)/100)
    }

    let restante_valor_rateio = (valor_total - somarValores(centro_custos.map(value => {return parseFloat(value.valor_rateio.replaceAll(".", "").replace(",", "."))})))
    restante_valor_rateio = parseFloat(restante_valor_rateio).toFixed(2)

    if(restante_valor_rateio !== 0){
      const valor_rateio = parseFloat(centro_custos[centro_custos.length - 1].valor_rateio.replaceAll(".", "").replace(",", ".")) + parseFloat(restante_valor_rateio)

      centro_custos[centro_custos.length - 1].valor_rateio = real((valor_rateio))
    }

    setState(prevState => ({...prevState, 
      centro_custos,
      centro_custo:{
        id: '',
        departamento_id: '',
        departamento: '',
        centrocusto_id: '',
        centrocusto: '',
        valor_percentual: 0,
        valor_rateio: 0,
        ativo: true,
        index: ''
      }
    }))
  }

  const ajustaValorCentro = (event, index) => {
    let valor = parseFloat(event.target.value.replaceAll(".", "").replace(",", "."))

    let {parcela, centro_custos} = state
    
    let valor_total = parseFloat(parcela.conta.valortotal.replaceAll(".", "").replace(",", "."))

    if(event.target.name === 'valor_rateio'){
    
      centro_custos[index].valor_rateio = real(valor)
      centro_custos[index].valor_percentual = ((parseFloat(centro_custos[index].valor_rateio.replaceAll(".", "").replace(",", "."))/valor_total)*100).toFixed(2)

      // for (let i = 0; i < centro_custos.length; i++) {
      //   if(centro_custos[i].index !== index){
      //     centro_custos[i].valor_rateio = real(valor_restante/(centro_custos.length - 1))
      //   }
      // }

      // for (let i = 0; i < centro_custos.length; i++) {
      //   centro_custos[i].valor_percentual = ((parseFloat(centro_custos[i].valor_rateio.replaceAll(".", "").replace(",", "."))/valor_total)*100).toFixed(2)
      // }
    }

    if(event.target.name === 'valor_percentual'){
      centro_custos[index].valor_percentual = parseFloat(event.target.value).toFixed(2)
      centro_custos[index].valor_rateio =  real((valor_total * centro_custos[index].valor_percentual)/100)

      // for (let i = 0; i < centro_custos.length; i++) {
      //   if(centro_custos[i].index !== index){
      //     centro_custos[i].valor_percentual = parseFloat((100-parseInt(event.target.value))/(centro_custos.length - 1)).toFixed(2)
      //   }
      // }

      // for (let i = 0; i < centro_custos.length; i++) {
      //   centro_custos[i].valor_rateio =  real((valor_total * centro_custos[i].valor_percentual)/100)
      // }
    }

    setState(prevState => ({...prevState, centro_custos})  )
  }

  const updateFieldCentro = (event, index) => {
    let {centro_custos} = state

    if(event.target.name === 'valor_percentual'){
      let valor = parseFloat(event.target.value).toFixed(2)
    
      if(valor > 100){
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total do rateio não pode ser superior a 100%.'
          }
        }))
      }else{
        centro_custos[index][event.target.name] = event.target.value
      }

    }else{
      centro_custos[index][event.target.name] = event.target.value
    }

    setState(prevState => ({...prevState, centro_custos})) 
  }

  const validateFormSalvar = () => {
    const { parcela } = state

    if (!parcela.contpr_descricao) return false
    if (!parcela.contpr_id) return false
    if (!parcela.plancontas_id) return false
    
    if (!parcela.datavencimento) return false
    if (!parcela.valorparcela) return false
    if (!parcela.num) return false

    for (let i = 0; i < parcela.formas_pgto.length; i++) {
      if (!parcela.formas_pgto[i].formapagto_id) return false
    }

    return true
  }

  const handleCloseModalTroco = () => {
    setState(prevState => ({...prevState, 
      modalTrocoOpen: false
    }))
  }

  const marcaParcelas = (event, index) => {
    
    const {contaTroco, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < contaTroco.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        contaTroco.parcelas[i].formapagto_id = event.target.value
        contaTroco.parcelas[i].habilita = formaPagtoSelecionada.habilita
        contaTroco.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState,  contaTroco }))
  }

  const recalcularParcela = (event, index) => {
    const contaTroco = { ...state.contaTroco }

    const parcelaEditada = contaTroco.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(contaTroco.valortotal.replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        contaTroco.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState, 
          qtdParcelasEditado
        }))
      }

      contaTroco.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = contaTroco.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = contaTroco.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < contaTroco.qtd_parcelas; i++) {
        if (!contaTroco.parcelas[i].parcela_editada) {
          contaTroco.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        contaTroco.parcelas = ajustarArray(contaTroco.qtd_parcelas, contaTroco.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState,  contaTroco }))
    }
  }

  const updateFieldParcela = async (event, index) => {
    const {contaTroco, formasPagamento} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      contaTroco.parcelas[index - 1][name] = value
    }else{
      contaTroco.parcelas[index - 1][name] = value
    }

    if(name === 'formapagto_id'){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

      contaTroco.parcelas[index - 1].habilita = formaPagtoSelecionada.habilita
      contaTroco.parcelas[index - 1].contfin_id = ""
    }
    
    setState(prevState => ({...prevState,  contaTroco }))
  }

  const verificaDataParcela = (event, index) => {
    const {contaTroco} = state
    
    for (let i = index; i < contaTroco.parcelas.length; i++) {
      let count = (i - index ) + 1

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      contaTroco.parcelas[i].datavencimento = novaData
      contaTroco.parcelas[i].datavencimento_orig = novaData
    }

    setState(prevState => ({...prevState,  contaTroco }))
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const mudarParcelas = (event) => {
    const contaTroco = { ...state.contaTroco }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(contaTroco.valortotal.replaceAll(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date(moment(contaTroco.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date(moment(contaTroco.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i))

      array.push({
        baixado: true,
        datavencimento: moment().format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: i + 1,
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    contaTroco.numparcela = qtdParcelasInfo
    contaTroco.qtd_parcelas = qtdParcelasInfo
    contaTroco.parcelas = array

    setState(prevState => ({...prevState,  contaTroco }))
  }

  const updateFieldTroco = async (event) => {
    let {contaTroco} = state

    let value = event.target.value
    let name = event.target.name

    contaTroco[name] = value

    setState(prevState => ({...prevState,  contaTroco }))
  }

  const verificaCaixaInicial = (state) => {
    if (!state.ultimoCaixa || !state.logado) return ''
    const { permissoesContasPR, permissoesCaixa, ultimoCaixa } = state

    if (
      (!permissoesContasPR.alterar && permissoesCaixa.alterar) &&
      (!Object.keys(ultimoCaixa).length || (ultimoCaixa && ultimoCaixa.fechado))
    ) {
      return {
        libera: false,
        msg: 'É necessário abrir um caixa!'
      }
    }

    if (
      (!permissoesContasPR.alterar && permissoesCaixa.alterar) &&
      ultimoCaixa &&
      !ultimoCaixa.fechado
    ) {

      const dataCaixa = moment(ultimoCaixa.dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        return {
          libera: false,
          msg: 'O caixa não é de hoje, é necessário fechar o anterior e abrir um novo!'
        }
      }
    }

    return {
      libera: true,
      msg: ''
    }
  }

  const updateFieldCancelamentoNFSE = (event) => {
    let {dadosCancelar} = state
    dadosCancelar[event.target.name] = event.target.value
    setState(prevState => ({...prevState, dadosCancelar}))
  }

  const handleModalCancelarNFSE = () => {
    setState(prevState => ({...prevState, 
      openModalCancelarNFSE: false,
      dadosCancelar: {
        id:'',
        motivo: ''
      }
    }))
  }

  const handleMoodalAlteraRecorrencia = () => {
    setState(prevState => ({...prevState, 
      openModalAlteraRecorrencia: false,
      disabledButton: false
    }))
  }

  const updateFieldRecorrencia = (event) => {
    let {recorrencia} = state
    recorrencia[event.target.name] = event.target.value
    setState(prevState => ({...prevState, recorrencia}))
  }

  const salvar = async () => {
    setState(prevState => ({...prevState, 
      disabledButton: true
    }))
    
    if (!validateFormSalvar()) {
      setState(prevState => ({...prevState, 
        disabledButton: false,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))

      return
    }

    const {planoContaSelecionado, centro_custos, parcela} = state

    if(parcela.formas_pgto.length === 0) {
      setState(prevState => ({...prevState, 
        disabledButton: false,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Informar ao menos uma forma de pagamento."
        }
      }))

      return
    }

    if(planoContaSelecionado.centro_custos.length > 0){
      if(centro_custos.length === 0){
  
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o centro de custo.'
          },
          disabledButton: false
        }))
        return
      }
    }

    if(centro_custos.length > 0){
      const valorTotalRateio = somarValores(centro_custos.map(value => {return parseFloat(value.valor_percentual)}))

      if(valorTotalRateio < 100){
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A porcentagem do rateio do centro de custo não pode ser inferior a 100%.'
          },
          disabledButton: false
        }))
        return
      }else if(valorTotalRateio > 100){
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A porcentagem do rateio do centro de custo não pode ser superior a 100%.'
          },
          disabledButton: false
        }))
        return
      }
    }

    try {
  
      let valorparcela = parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      valorparcela = parcela.plancontas_tipo === 'DESPESA' ? (valorparcela < 0 ? valorparcela : (valorparcela * -1)) : (valorparcela > 0 ? valorparcela : (valorparcela * -1)) 

      const dados = {
        conta: {
          contpr_id: parcela.contpr_id,
          contpr_descricao: parcela.contpr_descricao,
          plancontas_id: parcela.plancontas_id,
          centros_custo: centro_custos.map(centro => {
            let valor_rateio =  parseFloat(centro.valor_rateio.replaceAll(".", "").replace(",", "."))
            valor_rateio = parcela.plancontas_tipo === 'DESPESA' ? (valor_rateio < 0 ? valor_rateio : (valor_rateio * -1)) :(valor_rateio > 0 ? valor_rateio : (valor_rateio * -1))
            
            if(centro.id !== ''){
              return {
                id: centro.id,
                valor_rateio,
                valor_percentual: parseFloat(centro.valor_percentual),
                centrocusto_id: centro.centrocusto_id
              }
            }else{
              return {
                valor_rateio,
                valor_percentual: parseFloat(centro.valor_percentual),
                centrocusto_id: centro.centrocusto_id
              }
            }
          
          })
        },
        parcela: {
          baixado: parcela.baixado,
          datavencimento: parcela.datavencimento,
          datapagamento: parcela.datapagamento,
          valorparcela,
          num: parcela.num,
          multajuros: parseFloat(parcela.multajuros.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          documento: parcela.documento,
          situacao: parcela.situacao,
          formas_pgto: parcela.formas_pgto.map(forma => {
            let valor_pagamento = parseFloat(forma.valor_pagamento.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
            valor_pagamento = parcela.plancontas_tipo === 'DESPESA' ? (valor_pagamento < 0 ? valor_pagamento : (valor_pagamento * -1)) :(valor_pagamento > 0 ? valor_pagamento : (valor_pagamento * -1)) 

            if (forma.id !== "") {
              return {
                id: forma.id,
                formapagto_id: forma.formapagto_id,
                contfin_id:forma.contfin_id !== '' ?  parseInt(forma.contfin_id) : '',
                valor_pagamento
              }
            } else {
              return {
                formapagto_id: forma.formapagto_id,
                contfin_id:forma.contfin_id !== '' ?  parseInt(forma.contfin_id) : '',
                valor_pagamento
              }
            }
          })
        }
      }

      if(dados.parcela.valorparcela < 0){
        dados.parcela.multajuros = dados.parcela.multajuros ? (dados.parcela.multajuros * -1) : 0
      }else{
        dados.parcela.multajuros = dados.parcela.multajuros || 0
      }

      if(dados.conta.centros_custo.length === 0){
        delete dados.conta.centros_custo
      }

      if(dados.parcela.datapagamento === '' || !dados.parcela.datapagamento){
        delete dados.parcela.datapagamento
      }

      for (let i = 0; i < dados.parcela.formas_pgto.length; i++) {
        if(dados.parcela.formas_pgto[i].contfin_id === ''){
          delete dados.parcela.formas_pgto[i].contfin_id
        }
      }

      if (dados.parcela.documento === "" || !dados.parcela.documento) {
        delete dados.parcela.documento
      }

      setState(prevState => ({...prevState, dados}))

      const formaBoleto = state.formasPagamento.list.filter(param => param.slug === 'boleto')[0]

      if(formaBoleto){
        const indexBoletoAtual = dados.parcela.formas_pgto.findIndex(param => param.formapagto_id === formaBoleto.id)
        const indexBoletoOriginal = state.formasOriginal.findIndex(param => param.formapagto_id === formaBoleto.id)
  
        if(indexBoletoAtual === -1 && indexBoletoOriginal !== -1){
          setState(prevState => ({...prevState, 
            modalConfirmacao: {
              mensagem: "Para esta parcela foi gerado um boleto, ao remover a forma de pagamento do boleto você também estará cancelando o boleto, deseja continuar?",
            },
            modalConfirmacaoOpen: true
          }))

          return false
        }
      }

      if(parcela.conta.conta_pai_id){
        setState(prevState => ({...prevState, 
          openModalAlteraRecorrencia: true
        }))
        return false
      }

      await confirmarSalvamento(true, dados)

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        disabledButton: false
      }))
      return
    }

  }

  const confirmarSalvamento = async (value, dados) => {
    if(value){

      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'info',
          message: 'Atualizando Parcela...'
        }
      }))

      let {parcela, recorrencia} = state

      dados.acao_alteracao = parcela.acao_cancelamento

      if(parcela.recorrencia && dados.acao_alteracao !== 'apenas_esta'){
        dados.recorrencia = recorrencia
      }

      try {
        const {data} = await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/${parcelaId}`, dados, getToken())
      
        if(data.geraBoletoPix){
          if(data.slug === 'boleto'){
            let dadosGera = {
              parcelas : [{id: parcelaId}]
            }
            await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
          }else{
            let dadosGera = {
              parcelas : [{id: parcelaId}]
            }
            await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
          }
        }

        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'success',
            message: 'Atualização realizada com sucesso.'
          },
          disabledButton: false,
          modalConfirmacaoOpen: false,
          openModalAlteraRecorrencia: false
        }))

        backPage()
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButton: false
        }))
      }
      
    }else{
      setState(prevState => ({...prevState, 
        modalConfirmacao: {
          mensagem: "",
        },
        modalConfirmacaoOpen: false,
        openModalAlteraRecorrencia: false,
        disabledButton: false
      }))
      return
    }
  }

  const updateFieldPagamento = (event, index) => {
    let {name, value} = event.target
    let {parcela} = state
    
    parcela.formas_pgto[index][name] = value
    
    setState((prevState) => ({
      ...prevState,
      parcela
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState, 
        parcela: {
          baixado: "",
          datavencimento: "",
          datapagamento: "",
          valorparcela: '0,00',
          documento: "",
          num: "",
          motivo_cancelamento: "",
          parcela_editada: false,
          multajuros: '0,00',
          valortotal: '0,00',
          valor_aberto: '0,00',
          valor_aberto_sem_form: 0,
          valor_pago: '0,00',
          valor_pago_sem_form: 0,
          formas_pgto: [
            {
              id: '',
              formapagto_id: '',
              valor_pagamento: real(0),
              formapagto_descricao: '',
              formapagto_hab_caixa: false,
              contfin_id: '',
              contfin_descricao: '',
              contfin_tipo_conta: '',
              tipo_chave: '',
              chave_pix: '',
              index: 0
            }
          ],
          conta:{
            descricao: '',
            valortotal: '',
            valortotal_sem_form: 0,
            numparcela: 0
          },
          itens_conta: []
        },
        nova_parcela: {
          valor_restante: '',
          vencimento_nova_parcela: '',
          forma_pgto_nova_parcela: '',
          conta_fin_nova_parcela: '',
          habilita: '',
          gerar: false
        },
        forma_pgto: {
          id: '',
          formapagto_id: '',
          valor_pagamento: real(0),
          formapagto_descricao: '',
          formapagto_hab_caixa: false,
          tipo_chave: '',
          chave_pix: '',
          index: ''
        },
        centro_custos:[],
        centro_custo:{
          id: '',
          departamento_id: '',
          departamento: '',
          centrocusto_id: '',
          centrocusto: '',
          valor_percentual: 0,
          valor_rateio: 0,
          ativo: true,
          index: ''
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]
      let perfilLogado = ''

      // if(!permissoesContasPR || !permissoesContasPR.alterar){
      //   setState(prevState => ({...prevState, 
      //     alerta: {
      //       open: true,
      //       severity: 'error',
      //       message: 'Você não tem permissão para acessar essa tela'
      //     }
      //   })
      //   backPage()
      // }

      if (permissoesCaixa) {
        perfilLogado = 'caixa'
        setState(prevState => ({...prevState, 
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        perfilLogado = 'contas-pagar-receber'
        setState(prevState => ({...prevState, 
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

      if(ultimoCaixa && ultimoCaixa['status_atual.status'] === 'ABERTO'){
        perfilLogado = 'caixa'
        setState(prevState => ({...prevState, 
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      setState(prevState => ({...prevState, 
        ultimoCaixa: ultimoCaixa || {}
      }))

      try {
        const caixa = JSON.parse(localStorage.getItem('caixa'))
        setState(prevState => ({...prevState,  logado: { caixa } }))

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/list`, dados,getToken())
        const { data: itensDespesa } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados, getToken())

        const planoContas = data.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(param => {
          return {
            id: param.id,
            codigo: param.codigo,
            descricao: param.descricao,
            ativo: param.ativo,
          }
        })

        setState(prevState => ({...prevState, 
          planosConta: {
            list: planoContas
          },
          itensDespesa: {
            list: itensDespesa
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      try {
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

        setState(prevState => ({...prevState, 
          formas_sem_filtro: {
            list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
          }
        }))

        if (perfilLogado === 'caixa') {
          setState(prevState => ({...prevState, 
            formasPagamento: {
              list: formasPagamento.filter(param => param.ativo === true && param.hab_caixa === true)
            },
            formasPagamentoRemovidos: {
              list: formasPagamento.filter(param => param.ativo === true && param.hab_caixa === true)
            }
          }))
        } else {
          setState(prevState => ({...prevState, 
            formasPagamento: {
              list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
            },
            formasPagamentoRemovidos: {
              list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
            }
          }))
        }


      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (parcelaId) {
      
        const {contaTroco} = state

        try {
          const { data: parcela } = await axios.get(`${window._env_.REACT_APP_API_URL}/parcelaConta/${parcelaId}`, getToken())
          
          const dadosUni = {
            unidadesnegocio: [parcela.conta.pertence.id]
          }

          contaTroco.descricao = `Troco cliente ${parcela.conta.pessoa.tipo === 'FISICA' ? `${parcela.conta.pessoa.fisica.nome} ${parcela.conta.pessoa.fisica.sobrenome ? parcela.conta.pessoa.fisica.sobrenome : '' }` : parcela.conta.pessoa.juridica.razaosocial}`
          contaTroco.unidade_id = parcela.conta.pertence.id
          contaTroco.pessoa_id = parcela.conta.pessoa_conta.id

          const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parcela.conta.pertence.id}`, getToken())
          const { data: departamentosList } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, dadosUni, getToken())
    
          setState(prevState => ({...prevState, 
            contasFinanceira: {
              list: contasFinanceira
            },
            contaTroco
          }))

          const departamentos = []
          
          departamentosList.forEach(departamento => {
            if(departamento.unidade){
              departamentos.push({
                id: departamento.id,
                descricao: departamento.descricao,
                unidade: departamento.unidade.descricao ? departamento.unidade.descricao : (departamento.unidade.pessoa.tipo === 'FISICA' ? departamento.unidade.pessoa.fisica.nome : departamento.unidade.pessoa.juridica.razaosocial),
                centro_custos: departamento.centro_custos,
                ativo: departamento.ativo
              })
            }
          })
    
          setState(prevState => ({...prevState, 
            departamentos: {
              list: departamentos
            }
          }))

          let usuario_baixa = null

          if(parcela.situacao === 'BAIXADO'){
            usuario_baixa = parcela.usuario_baixa ? parcela.usuario_baixa.pessoa.fisica.nome : 'Migração'
          }

          let centro_custos = []

          if(parcela.conta.centros_custo_conta.length > 0){
            centro_custos = parcela.conta.centros_custo_conta.map((value, key) => {
              return {
                id: value.id,
                departamento_id: value.centro_custo.departamento_id,
                departamento: value.centro_custo.departamento.descricao,
                centrocusto_id: value.centrocusto_id,
                centrocusto: value.centro_custo.descricao,
                valor_percentual: value.valor_percentual.toString(),
                valor_rateio: real(value.valor_rateio),
                ativo: value.true,
                index: key
              }
            })
          }

          let produtos = ''

          if(parcela.conta.pedido){
            for (let i = 0; i < parcela.conta.pedido.itens_pedido.length; i++) {
              const item_pedido = parcela.conta.pedido.itens_pedido[i];
    
              if((i + 1) === parcela.conta.pedido.itens_pedido.length){
                produtos += `${item_pedido.produto.descricao}`
              }else{
                produtos += `${item_pedido.produto.descricao}, `
              }
    
            }
          }

          let valor_parcela = parcela.valorparcela < 0 ? (parcela.valorparcela * -1) : parcela.valorparcela
          let valor_desconto = parcela.valorparcela < 0 ? (parcela.multajuros ? (parcela.multajuros * -1) : 0) : (parcela.multajuros ? parcela.multajuros : 0)

          let parcelaAux = {
            contpr_id: parcela.conta.id,
            criado_em: moment(parcela.criado_em).format('DD/MM/YYYY HH:mm'),
            datavencimento: moment(parcela.datavencimento_orig).format('YYYY-MM-DD'),
            datavencimento_orig: moment(parcela.datavencimento_orig).format('YYYY-MM-DD'),
            datapagamento: parcela.datapagamento ? moment(parcela.datapagamento).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(valor_parcela),
            valorparcela_sem_form: valor_parcela,
            valortotalconta: real(parcela.conta.valortotal),
            valortotal: real(valor_parcela + valor_desconto),
            valor_aberto: real(parcela.valor_aberto < 0 ? parcela.valor_aberto * -1 : parcela.valor_aberto),
            valor_aberto_sem_form: parcela.valor_aberto,
            valor_pago: real(parcela.valor_pago < 0 ? parcela.valor_pago * -1 : parcela.valor_pago),
            valor_pago_sem_form: parcela.valor_pago,
            criador: parcela.conta.criador.pessoa.fisica ? parcela.conta.criador.pessoa.fisica.nome : 'Migração',
            usuario_baixa,
            usuario_cancelamento: parcela.usu_cancelamento ? parcela.usuario_cancelamento.pessoa.fisica.nome : null,
            data_cancelamento: parcela.data_cancelamento ?moment(parcela.data_cancelamento).format('DD/MM/YYYY HH:mm') : null,
            contpr_descricao: parcela.conta.descricao,
            contpr_numparcela: parcela.conta.numparcela,
            multajuros: real(valor_desconto),
            num: parcela.num,
            documento: parcela.documento ? parcela.documento : "",
            baixado: parcela.baixado,
            situacao: parcela.situacao,
            motivo_cancelamento: parcela.motivo_cancelamento ? parcela.motivo_cancelamento : "",
            parcelas_filhos: parcela.parcelas_filhos,
            parcela_pai: parcela.parcela_pai,
            formas_pgto: parcela.valores_pago.map((valor_pago, key) => {
              return {
                id: valor_pago.id,
                formapagto_id: valor_pago.forma_pagto.id,
                valor_pagamento: valor_pago.valor_pagamento ? real(valor_pago.valor_pagamento < 0 ? (valor_pago.valor_pagamento * -1) : valor_pago.valor_pagamento) : (parcela.valores_pago.length > 1 ? real(0) : real(parcela.valorparcela < 0 ? (parcela.valorparcela * -1) : (parcela.baixado ? 0 : parcela.valorparcela))),
                formapagto_descricao: valor_pago.forma_pagto.descricao,
                formapagto_slug: valor_pago.forma_pagto.slug,
                formapagto_hab_caixa: valor_pago.forma_pagto.hab_caixa,
                contfin_tipo_conta: valor_pago.mov ? valor_pago.mov.conta_financeira.tipo_conta : ( valor_pago.conta_financeira ? valor_pago.conta_financeira.tipo_conta : ''),
                contfin_descricao: valor_pago.mov ? valor_pago.mov.conta_financeira.descricao :  ( valor_pago.conta_financeira ? valor_pago.conta_financeira.descricao : ''),
                contfin_id: valor_pago.mov ? valor_pago.mov.conta_financeira.id : ( valor_pago.conta_financeira ? valor_pago.conta_financeira.id : ''),
                movcontfin_conciliado: valor_pago.mov ? valor_pago.mov.conciliado : false,
                movcontfin_valor: valor_pago.mov ? valor_pago.mov.valor : false,
                tipo_chave: '',
                chave_pix: '',
                index: key
              }
            }),
            boleto_transaction_id: parcela.boleto_transaction_id,
            responsavel: parcela.conta.pessoa.tipo === 'FISICA' ? `${parcela.conta.pessoa.fisica.nome} ${parcela.conta.pessoa.fisica.sobrenome ? parcela.conta.pessoa.fisica.sobrenome : '' }` : `${parcela.conta.pessoa.juridica.razaosocial}`,
            cliente_fornecedor: parcela.conta.pessoa_conta.tipo === 'FISICA' ? `${parcela.conta.pessoa_conta.fisica.nome} ${parcela.conta.pessoa_conta.fisica.sobrenome ? parcela.conta.pessoa_conta.fisica.sobrenome : ''}` : `${parcela.conta.pessoa_conta.juridica.razaosocial}`,
            conta:{
              descricao: parcela.conta.descricao,
              valortotal: real(parcela.conta.valortotal),
              valortotal_sem_form: parcela.conta.valortotal,
              numparcela: parcela.conta.numparcela,
              conta_pai_id: parcela.conta.conta_pai_id,
              nfse_id: parcela.conta.nfse_id,
              nfse: parcela.conta.nfse,
              recorrencia: parcela.conta.recorrencia
            },
            qtd_parcelas: parcela.qtd_parcelas,
            qtd_recorrencia: parcela.qtd_recorrencia,
            qtd_recorrencia_a_partir: parcela.qtd_recorrencia_a_partir,
            acao_cancelamento: 'apenas_esta',
            plancontas_id: parcela.conta.plano_contas ? parcela.conta.plano_contas.id : '',
            plancontas_tipo: parcela.conta.plano_contas ? parcela.conta.plano_contas.tipo : '',
            itens_conta: parcela.conta.itens_conta.map(item => {
              return {
                id: item.id,
                valor: item.valor,
                item_id: item.item_id,
                descricao: item.item.descricao,
                planocontas_id: item.item.planocontas_id,
                // plano_contas_descricao: item.item.plano_contas.descricao
              }
            }),
            produtos,
            recorrencia: parcela.conta.conta_pai_id ? true : false
          }

          const estado = {
            parcela: parcelaAux,
            centro_custos,
            loading: false,
          }

          const formasOriginal = parcela.valores_pago.map((valor_pago, key) => {
            return {
              id: valor_pago.id,
              formapagto_id: valor_pago.forma_pagto.id,
              valor_pagamento: valor_pago.valor_pagamento ? real(valor_pago.valor_pagamento < 0 ? (valor_pago.valor_pagamento * -1) : valor_pago.valor_pagamento) : (parcela.valores_pago.length > 1 ? real(0) : real(parcela.valorparcela < 0 ? (parcela.valorparcela * -1) : parcela.valorparcela)),
              formapagto_descricao: valor_pago.forma_pagto.descricao,
              formapagto_slug: valor_pago.forma_pagto.slug,
              formapagto_hab_caixa: valor_pago.forma_pagto.hab_caixa,
              contfin_tipo_conta: valor_pago.mov ? valor_pago.mov.conta_financeira.tipo_conta : '',
              contfin_descricao: valor_pago.mov ? valor_pago.mov.conta_financeira.descricao : '',
              contfin_id: valor_pago.mov ? valor_pago.mov.conta_financeira.id : '',
              movcontfin_conciliado: valor_pago.mov ? valor_pago.mov.conciliado : false,
              movcontfin_valor: valor_pago.mov ? valor_pago.mov.valor : false,
              index: key
            }
          })
          
          if (perfilLogado === 'caixa') {
            for (let i = 0; i < estado.parcela.formas_pgto.length; i++) {
              estado.parcela.formas_pgto[i].contfin_tipo_conta = contasFinanceira.length ? contasFinanceira[0].tipo_conta : null
              estado.parcela.formas_pgto[i].contfin_descricao = contasFinanceira.length ? contasFinanceira[0].descricao : null
              estado.parcela.formas_pgto[i].contfin_id = contasFinanceira.length ? contasFinanceira[0].id : null
            }
          }

          if(parcela.valores_pago.length > 0){
            if (!parcela.valores_pago[0].valor_pagamento || parcela.valores_pago[0].valor_pagamento === 0) {
    
              setState(prevState => ({...prevState, 
                updateForma: false
              }))
            }
          }

          if(parcela.conta.recorrencia){
            setState(prevState => ({...prevState, 
              recorrencia: {
                tipoRecorrencia: parcela.conta.recorrencia.intervalo,
                recorrenciaACada: parcela.conta.recorrencia.repetir_a_cada,
                qtdRecorrencia: parcela.conta.recorrencia.num_ocorrencias
              }
            }))
          }

          setState(prevState => ({...prevState, ...estado, formasOriginal}))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
        }
      }
    };
    
    fetchData();
  }, []);

  const { parcela, logado, tab } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
        {state.loading &&
          <div>
            <CircularProgress />
          </div>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={11}>
                <h1 className="titulo">Alteração da Parcela</h1>
              </Grid>
              <Grid item md={1}>
                {state.disabledButton &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            <br />
            {!verificaCaixaInicial(state).libera &&
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <p>{verificaCaixaInicial(state).msg}</p>
                </Grid>
              </Grid>
            }

            {verificaCaixaInicial(state).libera && 
              <React.Fragment>
                <AppBar position="static">
                  <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Dados da Parcela" {...a11yProps(0)} />
                    <Tab label="Centro de Custo" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                  <FormParcelaConta
                    logado={logado}
                    dados={parcela}
                    updateField={e => updateField(e)}
                    planoContas={state.planoContas}
                    planosConta={state.planosConta.list}
                    contasFinanceira={state.contasFinanceira.list}
                    formasPagamento={state.formasPagamento.list}
                    ultimoCaixa={state.ultimoCaixa}
                    perfilLogado={state.perfilLogado}
                    addFormaPagto={e => addFormaPagto(e)}
                    updateFieldFormaPgto={(e, key) => updateFieldFormaPgto(e, key)}
                    valor_total={state.valor_total}
                    removeFormaPagto={(e, key) => removeFormaPagto(e, key)}
                    forma_pgto={state.forma_pgto}
                    editarFormaPgto={e => editarFormaPgto(e)}
                    updateForma={state.updateForma}
                    verificaData={e => verificaData(e)}
                    ajustaCentros={e => ajustaCentros(e)}
                    updateFieldPagamento={(e, index) => updateFieldPagamento(e, index)}
                  />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <FormContaPRCentroCusto
                    centro_custos={state.centro_custos}
                    updateFieldCentro={(e, index) => updateFieldCentro(e, index)}
                    ajustaValorCentro={(e, index) => ajustaValorCentro(e, index)}
                    centro_custo={state.centro_custo}
                    departamentos={state.departamentos.list}
                    centro_custos_list={state.centros_custo_list.list}
                    updateFieldCentroCusto={e => updateFieldCentroCusto(e)}
                    addCentroCusto={e => addCentroCusto()}
                    removeCentro={e => removeCentro(e)}
                    conta={parcela.conta}
                    disabledCampos={(parcela.conta.valortotal_sem_form - parcela.valor_aberto_sem_form) > 0 ? true: false}
                  />
                </TabPanel>       
                <hr />       
                <Grid container direction="row" alignItems="flex-end" className="mg_top_10" spacing={1}>
                  {parcela.situacao !== 'TRANSFERIDO' &&
                    <React.Fragment>
                      {parcela.situacao !== 'BAIXADO' &&
                        <React.Fragment>
                          {parcela.situacao !== 'CANCELADO' &&
                            <React.Fragment>
                              {parcela.situacao !== 'ESTORNADO' &&
                                <React.Fragment>
                                  <Grid item md={3} xs={12} sm={6}>
                                    <Button 
                                      fullWidth
                                      variant="contained" 
                                      className="btn_salvar" 
                                      size="small" 
                                      startIcon={<SaveIcon />} 
                                      onClick={e => salvar(e)} 
                                      disabled={state.disabledButton}
                                      color={'success'}
                                    >
                                      Salvar sem Baixar
                                    </Button>
                                  </Grid>
                                  <Grid item md={2} xs={12} sm={6}>
                                    <Button
                                      fullWidth
                                      color="primary"
                                      variant="contained"
                                      className="btn_salvar"
                                      size="small"
                                      startIcon={<GetAppIcon />}
                                      onClick={e => baixar(e)}
                                      disabled={state.disabledButton}
                                    >
                                      Baixar
                                    </Button>
                                  </Grid>
                                  {state.perfilLogado === 'contas-pagar-receber' &&
                                    <Grid item md={2} xs={12} sm={6}>
                                      <Button 
                                        fullWidth 
                                        color="secondary" 
                                        variant="contained" 
                                        className="btn_salvar" 
                                        size="small" 
                                        startIcon={<CloseIcon />} 
                                        onClick={e => cancelar(e)} 
                                        disabled={state.disabledButton}
                                      >
                                        Cancelar
                                      </Button>
                                    </Grid>
                                  }
                                </React.Fragment>
                              }
                            </React.Fragment>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                  {parcela.situacao === 'BAIXADO' &&
                    <React.Fragment>
                      {!parcela.movcontfin_conciliado &&
                        <Grid item md={2} xs={12} sm={6}>
                          <Button 
                            fullWidth 
                            color="secondary" 
                            variant="contained" 
                            className="btn_salvar" 
                            size="small" 
                            startIcon={<CloseIcon />} 
                            onClick={e => confirmarEstorno(e)} 
                            disabled={state.disabledButton}
                          >
                            Estornar
                          </Button>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  <Grid item md={2} xs={12} sm={6}>
                    <Button fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIosIcon />} onClick={e => voltar(e)} disabled={state.disabledButton}>Voltar</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }


          </div>
        }
        <br />
        <ModalConfirmacaoCancelamento 
          open={state.modalConfirmacaoCancelamentoOpen} 
          dados={parcela} 
          handleClose={e => handleClose(e)} 
          updateData={e => updateField(e)}
          confirmar={e => confirmar(e)} 
          loadingCancelamento = {state.loadingCancelamento}
          disabledButton={state.disabledButton}
        />

        <ModalConfirmacaoEstorno 
          open={state.modalConfirmacaoEstornoOpen} 
          dados={parcela} 
          handleClose={e => handleClose(e)} 
          updateData={e => updateField(e)} 
          confirmar={e => estornar(e)} 
          movConciliada={state.movConciliada}
          parcela={parcela}
          motivosCancelamento={state.motivosCancelamento.list}
          dadosCancelar={state.dadosCancelar}
          updateFieldCancelamentoNFSE={(e) => updateFieldCancelamentoNFSE(e)}
          travarCancelamentoNFSE={state.travarCancelamentoNFSE}
          disabledButton={state.disabledButton}
        />

        <Alert 
          open={state.alerta.open}
          handleClose={e => handleCloseAlerta()} 
          severity={state.alerta.severity}
          message={state.alerta.message} />

        <ModalSimple 
          open={state.modalCaixaOpen} 
          dados={state.modalCaixa} 
          handleClose={e => handleClose(e)} 
          updateData={e => updateField(e)} 
          confirmar={e => confirmarCaixa(e)} />

        <ModalBaixaFinanceiro
          open={state.modalBaixaFinanceiro}
          dados={state.dadosModalBaixa}
          handleClose={e => handleCloseBaixa(e)}
          recusar={e => openModalNovaParcela(e)}
          verificaDataNovaParcela={e => verificaDataNovaParcela(e)}
          updateFieldNovaParcela={e => updateFieldNovaParcela(e)}
          nova_parcela={state.nova_parcela}
          formas_sem_filtro={state.formas_sem_filtro.list}
          contasFinanceira={state.contasFinanceira.list}
          confirmarNovaParcela={e => confirmarNovaParcela(e)}
          recusarNovaParcela={e => recusarNovaParcela(e)}
          valor_troco={state.valor_troco}
          confirmarTroco={e => confirmarTroco(e)} 
          lancarTroco={e => lancarTroco(e)}
        />

        <ModalConfirmacao 
          open={state.modalConfirmacaoOpen}
          dados={state.modalConfirmacao} 
          handleClose={e => handleCloseConfirmacao(e)} 
          confirmar={e => confirmarSalvamento(e, state.dados)} />

        <ModalConfirmacaoBaixa
          open={state.modalConfirmacaoBaixaOpen}
          dados={state.modalConfirmacao} 
          handleClose={e => handleCloseConfirmacaoBaixa(e)} 
          confirmar={e => confirmarBaixa(e, state.dados)} />

        <ModalConfirmacaoParcelaEstorno
          open={state.modalConfirmacaoParcelaEstornoOpen}
          handleClose={e => handleCloseConfirmacaoParcelaEstorno(e)} 
          parcelas_filhos={state.parcelas_filhos} 
          confirmar={e => continuarEstorno(e)}
          tela='estorno'
        />

        <ModalTroco 
          open={state.modalTrocoOpen}
          handleClose={e => handleCloseModalTroco(e)}
          conta={state.contaTroco}
          contasFinanceira={state.contasFinanceira.list}
          formasPagamento={state.formasPagamento.list}
          marcaParcelas={(e, index) => marcaParcelas(e, index)}
          recalcularParcela={(e, value) => recalcularParcela(e, value)}
          updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
          verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
          mudarParcelas={e => mudarParcelas(e)}
          updateField={e => updateFieldTroco(e)}
          confirmar={e => continuarBaixa(e, state.dados, true)}
        />

        <ModalCancelarNFSE 
          handleClose={e => handleModalCancelarNFSE()} 
          open={state.openModalCancelarNFSE}
          dadosCancelar={state.dadosCancelar}
          motivosCancelamento={state.motivosCancelamento.list}
          updateFieldCancelamento={(e) => updateFieldCancelamentoNFSE(e)}
          confirmar={(e) => realizarCancelamento(e)}
        />

        <ModalAlteraRecorrencia 
          handleClose={e => handleMoodalAlteraRecorrencia()} 
          open={state.openModalAlteraRecorrencia}
          dados={parcela}
          confirmar={e => confirmarSalvamento(e, state.dados)}
          updateData={e => updateField(e)}
          recorrencia={state.recorrencia}
          updateFieldRecorrencia={e => updateFieldRecorrencia(e)}
        />

      </Main>
      <Nav />
    </div>
  )
}

export default UpdateContasPagarReceber